import { axiosDelete, axiosPost } from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const LEAD_FLAGS_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/flags`);

export async function postFlag(leadId) {
  const response = await axiosPost(LEAD_FLAGS_ENDPOINT(leadId));
  return response.data;
}

export async function deleteFlags(leadId) {
  const response = await axiosDelete(LEAD_FLAGS_ENDPOINT(leadId));
  return response;
}

export default {
  postFlag,
};
