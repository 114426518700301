import React from "react";
import PropTypes from "prop-types";
import className from "classnames";
import { inject, observer } from "mobx-react";
import { TimerBar } from "./TimerBar/TimerBar";
import { DeleteIcon } from "wm-graphics";
import { FlexCol, FlexRow, Text } from "wm-ui-toolkit";
import { BannerButton } from "./BannerButton/BannerButton";
import { bannerStorePropTypes } from "../../../proptypes/stores";

import "./Banner.scss";

const DELAY_TIME = 50;

@inject("bannerStore")
@observer
class Banner extends React.Component {
  static defaultProps = {
    timer: true,
  };

  static propTypes = {
    actions: PropTypes.arrayOf(PropTypes.object),
    bannerStore: bannerStorePropTypes,
    body: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    index: PropTypes.number,
    onClose: PropTypes.func,
    timer: PropTypes.bool,
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      barPercent: 100,
    };

    this.timeout = null;
  }

  componentWillUnmount() {
    const { onClose } = this.props;

    clearTimeout(this.timeout);

    if (onClose) {
      onClose();
    }
  }

  closeBanner = () => {
    const { bannerStore, id } = this.props;
    bannerStore.deleteBanner(id);
  };

  onKeyDownCloseBanner = (event) => {
    if (event.key === "Enter") {
      this.closeBanner();
    }
  };

  async delay() {
    const promise = new Promise((res) => {
      this.timeout = setTimeout(() => res(), DELAY_TIME);
    });
    await promise;
  }

  countDownPercent = async () => {
    let { barPercent } = this.state;
    let i = 0;
    while (i < 100) {
      barPercent -= 1;
      this.setState({ barPercent });
      await this.delay(); // eslint-disable-line
      i += 1;
    }
    this.closeBanner();
  };

  renderActions = () => {
    const { actions } = this.props;

    return (
      actions.length > 0 && (
        <FlexRow className="content__actions" justifyContent="center">
          {actions.map((action) => (
            <BannerButton
              key={action.text}
              closeBanner={this.closeBanner}
              actionCallback={action.callback}
              text={action.text}
              buttonType={action.buttonType}
            />
          ))}
        </FlexRow>
      )
    );
  };

  renderCloseButton = () => {
    return (
      <div
        className="header__closeButton"
        onClick={this.closeBanner}
        onKeyDown={this.onKeyDownCloseBanner}
        role="button"
        tabIndex={0}
        data-testid="banner-close-button"
      >
        <DeleteIcon height={12} width={12} />
      </div>
    );
  };

  render() {
    const { actions, body, color, timer, title } = this.props;
    const { barPercent } = this.state;

    const height = actions.length !== 0 ? 160 : 132;

    const bannerColorBar = className("Banner__colorBar", color);

    return (
      <div
        className="Banner"
        data-testid="banner"
        style={{
          height,
        }}
      >
        <FlexCol className={bannerColorBar} />
        <FlexCol className="banner__content" flexSpacing={30}>
          <FlexRow
            className="content__header"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text className="MessageFilters__Title" type="H6">
              {title}
            </Text>
            {this.renderCloseButton()}
          </FlexRow>
          <Text type="S" className="content__body">
            {body}
          </Text>
          {this.renderActions()}
          {timer && (
            <TimerBar asyncFunc={this.countDownPercent} value={barPercent} />
          )}
        </FlexCol>
      </div>
    );
  }
}

export { Banner };
export default Banner;
