import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { ConfirmText } from "../../../../controls/ConfirmText/ConfirmText";
import { withRouter } from "../../../../utils/withRouter";
import { adminStoreProptypes } from "../../../../proptypes/stores";
import { withTranslations } from "../../../../utils/withTranslations";

@withTranslations
@inject("adminStore")
@withRouter
@observer
export class EditPriceSheetBreadcrumb extends React.Component {
  static propTypes = {
    adminStore: adminStoreProptypes,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    t: PropTypes.func,
  };

  async componentDidMount() {
    const { adminStore } = this.props;
    const { campaignId, priceSheetsStore } = adminStore;
    const { isLoading } = priceSheetsStore;

    if (isLoading) {
      await priceSheetsStore.retrievePriceSheetsForCampaignAsync(campaignId);
    }
  }

  onSave = (newLabel) => {
    const { adminStore, id } = this.props;
    const { priceSheetsStore } = adminStore;

    const payload = {
      price_sheet: {
        label: newLabel,
      },
    };

    priceSheetsStore.updatePriceSheetAsync(id, payload);
  };

  render() {
    const { adminStore, id, t } = this.props;
    const { priceSheetsStore } = adminStore;

    const priceSheet = priceSheetsStore.findPriceSheet(id);

    return (
      <div>
        {priceSheet && (
          <ConfirmText
            defaultValue={priceSheet.attributes.label}
            onSave={this.onSave}
            placeholder={t("priceSheet.breadcrumbConfirmPlaceholder")}
          />
        )}
      </div>
    );
  }
}

export default EditPriceSheetBreadcrumb;
