import * as axiosService from "../axiosRequests";

import { endpoint } from "../../utils/endpoint";

export const CAMPAIGN_ACTIVITIES_ENDPOINT = (
  campaignId,
  format = "",
  filtersEncoded = ""
) =>
  endpoint(
    `app/v1.0/campaigns/${campaignId}/activities${
      format !== "" ? `.${format}` : ""
    }${filtersEncoded !== "" ? `?${filtersEncoded}` : ""}`
  );

  
export async function getActivities(campaignId, payload, filtersEncoded="") {
  const response = await axiosService.axiosGet(
    CAMPAIGN_ACTIVITIES_ENDPOINT(campaignId, "", filtersEncoded),
    payload
  );

  return response.data;
}

export default {
  getActivities,
};
