import PropTypes from "prop-types";
import { leadBaseAttributesType } from "./leadBase";

export const leadDetailsAttributesPropTypes = {
  ...leadBaseAttributesType,
};

export const leadDetailsPropTypes = PropTypes.shape({
  attributes: PropTypes.shape(leadDetailsAttributesPropTypes),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
});
