import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Text } from "wm-ui-toolkit";
import { withTranslations } from "../../utils/withTranslations";
import * as imagesService from "../../requests/images";
import { S3Upload } from "../S3Upload/S3Upload";
import { sanitizeExifData } from "../../utils/sanitizeImage"

@withTranslations
export class PhotoUpload extends React.Component {
  static propTypes = {
    accept: PropTypes.string,
    id: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    retrieveUploadUrl: PropTypes.func,
    showPreview: PropTypes.bool,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };
  }

  onChange = async (files) => {
    const { id, multiple, onChange, t } = this.props;

    const photoUrls = [];
    let photoCountNotUploaded = 0;

    this.setState({ errorMessage: null });

    await Promise.all(
      files.map(async (file) => {
        const sanitizedFile = await sanitizeExifData(file.file);

        const options = {
          headers: {
            "Content-Type": sanitizedFile.type,
          },
        };

        try {
          if (
            ["jpeg", "jpg", "png", "svg"].some((ext) =>
              sanitizedFile.type.includes(ext)
            )
          ) {
            await axios.put(file.uploadUrl, sanitizedFile, options); 
            photoUrls.push(file.url);
          } else {
            this.setState({ errorMessage: t("common:imageFormatWrong") });
          }
        } catch (err) {
          photoCountNotUploaded += 1;
          const errorMessage = t("common:imageNotUploaded", {
            count: photoCountNotUploaded,
          });
          this.setState({ errorMessage });
        }
      })
    );

    const value = multiple ? photoUrls : photoUrls[0];
    if (onChange) {
      if (id) {
        onChange({
          id,
          value,
        });
      } else {
        onChange(value);
      }
    }
  };

  retrieveUploadUrl = async (file) => {
    const { retrieveUploadUrl } = this.props;

    let fileInfo;
    if (retrieveUploadUrl) {
      fileInfo = retrieveUploadUrl(file);
    } else {
      fileInfo = await imagesService.getImageUploadUrl(file.name);
    }
    return fileInfo;
  };

  render() {
    const { errorMessage } = this.state;
    const { accept, multiple, showPreview } = this.props;

    return (
      <>
        <S3Upload
          accept={accept}
          retrieveUploadUrl={this.retrieveUploadUrl}
          onChange={this.onChange}
          size="small"
          multiple={multiple}
          showPreview={showPreview}
        />
        {errorMessage && <Text>{errorMessage}</Text>}
      </>
    );
  }
}

export default PhotoUpload;
