import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "wm-ui-toolkit";

export class LoadButton extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
  };

  render() {
    const { children, disabled, isLoading, onClick, type } = this.props;

    return isLoading ? (
      <Spinner />
    ) : (
      <Button type={type} onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    );
  }
}
