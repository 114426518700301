import * as axiosService from "../axiosRequests";

import { endpoint } from "../../utils/endpoint";

export const CAMPAIGN_API_TOKEN_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/api_token`);

export async function getCampaignApiToken(campaignId) {
  const response = await axiosService.axiosGet(
    CAMPAIGN_API_TOKEN_ENDPOINT(campaignId)
  );

  return response.data;
}

export async function postCampaignApiToken(campaignId) {
  const response = await axiosService.axiosPost(
    CAMPAIGN_API_TOKEN_ENDPOINT(campaignId)
  );

  return response.data.data;
}

export async function deleteCampaignApiToken(campaignId) {
  const response = await axiosService.axiosDelete(
    CAMPAIGN_API_TOKEN_ENDPOINT(campaignId)
  );

  return response.data.data;
}

export default {
  getCampaignApiToken,
  postCampaignApiToken,
};
