import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";
import { IVariable } from "../../types/IVariable";

export interface IVariablePayload {
  value: string;
  identifier: string;
}

const CAMPAIGN_VARIABLES_ENDPOINT = (campaignId: string): string => endpoint(`app/v1.0/campaigns/${campaignId}/variables`);

const CAMPAIGN_VARIABLE_ENDPOINT = (campaignId: string, variableId: string): string => endpoint(`app/v1.0/campaigns/${campaignId}/variables/${variableId}`);

export const getVariables = async (campaignId: string): Promise<IVariable[]> => {
  const response = await axiosService.axiosGet(
    CAMPAIGN_VARIABLES_ENDPOINT(campaignId),
  );

  return response.data.data;
};

export const putVariable = async (
  campaignId: string,
  variableId: string,
  variable: Partial<IVariablePayload>,
): Promise<IVariable> => {
  const response = await axiosService.axiosPut(
    CAMPAIGN_VARIABLE_ENDPOINT(campaignId, variableId),
    {
      variable,
    },
  );

  return response.data;
};

export const postVariable = async (
  campaignId: string,
  variable: IVariablePayload,
): Promise<IVariable> => {
  const response = await axiosService.axiosPost(
    CAMPAIGN_VARIABLES_ENDPOINT(campaignId),
    { variable },
  );

  return response.data;
};

export const deleteVariable = async (
  campaignId: string,
  variableId: string,
): Promise<IVariable> => {
  const response = await axiosService.axiosDelete(
    CAMPAIGN_VARIABLE_ENDPOINT(campaignId, variableId),
  );

  return response.data;
};

export default {
  getVariables,
  putVariable,
  postVariable,
};
