import React, { ReactNode, Suspense as ReactSuspense } from "react";
import { Spinner } from "wm-ui-toolkit";
import "./Suspense.scss";

export interface ISuspenseProps {
  children: ReactNode;
}

export const Suspense: React.FC<ISuspenseProps> = ({ children }) => (
  <ReactSuspense
    fallback={(
      <div className="Suspense">
        <Spinner />
      </div>
      )}
  >
    {children}
  </ReactSuspense>
);

export default Suspense;
