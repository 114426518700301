import React from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../context/AppProvider";
import { withRouter } from "../utils/withRouter";

@withRouter
export class WorkerRoute extends React.Component {
  static contextType = AppContext;

  render() {
    const { currentUser } = this.context;

    return (
      <div data-testid="Worker">
        {currentUser.type === "Worker" ? (
          <Navigate to="/frontline" />
        ) : (
          <Navigate to="/dashboard" />
        )}
      </div>
    );
  }
}
