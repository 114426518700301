import React, { createContext } from "react";
import PropTypes from "prop-types";
import { Provider, inject, observer } from "mobx-react";
import { AppContext } from "../context/AppProvider";
import { AdminStore } from "../stores/AdminStore";
import { rootStorePropTypes } from "../proptypes/stores";

export const AdminContext = createContext();

@inject("rootStore")
@observer
export class AdminProvider extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    children: PropTypes.node,
    rootStore: rootStorePropTypes,
  };

  constructor(props, context) {
    super(props, context);

    this.adminStore = new AdminStore(props.campaignId, props.rootStore);
  }

  render() {
    const { children } = this.props;
    const { isInitializing } = this.adminStore;

    return (
      <Provider adminStore={this.adminStore}>
        <AdminContext.Provider value={{ adminStore: this.adminStore }}>
          {!isInitializing && children}
        </AdminContext.Provider>
      </Provider>
    );
  }
}

export default AdminProvider;
