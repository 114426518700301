import * as axiosService from "../axiosRequests";

import { IWebhookRequest } from "../../types/IWebhookRequest";
import { endpoint } from "../../utils/endpoint";

export interface IGetWebhookRequestsPayload {
  page?: number;
}

export const WEBHOOK_REQUESTS_ENDPOINT = (campaignId: string): string => endpoint(`app/v1.0/campaigns/${campaignId}/webhook_requests`);

export const getWebhookRequests = async (
  campaignId: string,
  payload: IGetWebhookRequestsPayload,
): Promise<IWebhookRequest[]> => {
  const response = await axiosService.axiosGet(
    WEBHOOK_REQUESTS_ENDPOINT(campaignId),
    payload,
  );

  return response.data;
};

export default {
  getWebhookRequests,
};
