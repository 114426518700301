import React from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";
import { authStorePropTypes, rootStorePropTypes } from "../proptypes/stores";
import { AppContext } from "../context/AppProvider";
import { TranslationProvider } from "../providers/TranslationProvider";
import { AdminProvider } from "../providers/AdminProvider";
import { withRouter } from "../utils/withRouter";
import i18next from "../pages/Admin/translations/i18n";
import { AdminRouteBreadcrumbsWrapper } from "./AdminRouteBreadcrumbsWrapper";
import { inject, observer } from "mobx-react";

@withRouter
@inject("authStore", "rootStore")
@observer
export class AdminRoute extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    authStore: authStorePropTypes,
    campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rootStore: rootStorePropTypes,
  };

  async componentDidMount() {
    const { campaign } = this.context;
    const { authStore, campaignId, rootStore } = this.props;

    const isNotCurrentCampaign =
      campaignId.toString() !== campaign.id.toString();

    if (isNotCurrentCampaign) {
      const payload = { contract: { active: true } };
      await rootStore.contractStore.updateContractForCampaign(
        campaignId,
        payload
      );
      authStore.retrieveCurrentUserContext();
    }
  }

  render() {
    const { campaign, currentUser } = this.context;
    const { campaignId } = this.props;

    const isCurrentCampaign = campaignId.toString() === campaign.id.toString();

    return (
      <>
        {isCurrentCampaign && (
          <TranslationProvider i18n={i18next}>
            <AdminProvider campaignId={campaignId}>
              <AdminRouteBreadcrumbsWrapper />
              <div className="AdminRoute" data-testid="AdminPrivileges">
                {currentUser && currentUser.hasAdminPrivileges ? (
                  <Outlet />
                ) : (
                  <Navigate to="/" />
                )}
              </div>
            </AdminProvider>
          </TranslationProvider>
        )}
      </>
    );
  }
}
