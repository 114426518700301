import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../../context/AppProvider";
import { Button, FlexCol, FlexRow, Modal, Spinner, Text } from "wm-ui-toolkit";
import { AlertTriangleIcon, ErrorTriangleIcon } from "wm-graphics";
import { withTranslations } from "../../../utils/withTranslations";
import "./ConfirmDuplicatesModal.scss";

@withTranslations
class ConfirmDuplicatesModal extends React.Component {
  static contextType = AppContext;

  static defaultProps = {};

  static propTypes = {
    afterSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
    payload: PropTypes.object,
    submitLead: PropTypes.func,
    t: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      isSubmitting: false,
    };
  }

  onSubmit = async () => {
    const { afterSubmit, payload, submitLead } = this.props;

    this.setState({ isSubmitting: true });
    await submitLead(payload);
    this.setState({ isSubmitting: false });
    afterSubmit();
  };

  renderWarning = () => {
    const { campaign } = this.context;
    const { leadLabel } = campaign;
    const { closeModal, t } = this.props;
    const { isSubmitting } = this.state;

    return (
      <FlexCol alignItems="center" gap="L">
        <FlexCol alignItems="center" gap="S">
          <AlertTriangleIcon width={102} height={80} />
          <FlexCol className="ConfirmDuplicatesModal__text" alignItems="center">
            <Text type="H3" weight="Semi">
              {t("common:duplicates.warning.title")}
            </Text>
            <Text>
              {t("common:duplicates.warning.description", {
                leadLabel,
              })}
            </Text>
          </FlexCol>
        </FlexCol>
        <FlexRow gap="XS">
          {isSubmitting ? (
            <Spinner />
          ) : (
            <>
              <Button type="light" onClick={closeModal}>
                {t("common:back")}
              </Button>
              <Button type="primary" onClick={this.onSubmit}>
                {t("common:submit")}
              </Button>
            </>
          )}
        </FlexRow>
      </FlexCol>
    );
  };

  renderError = () => {
    const { campaign } = this.context;
    const { leadLabel } = campaign;
    const { closeModal, t } = this.props;

    return (
      <FlexCol alignItems="center" gap="L">
        <FlexCol alignItems="center" gap="S">
          <ErrorTriangleIcon width={102} height={80} />
          <FlexCol className="ConfirmDuplicatesModal__text" alignItems="center">
            <Text type="H3" weight="Semi">
              {t("common:duplicates.error.title", { leadLabel })}
            </Text>
            <Text>{t("common:duplicates.error.description")}</Text>
          </FlexCol>
        </FlexCol>
        <FlexRow gap="XS">
          <Button type="light" onClick={closeModal}>
            {t("common:back")}
          </Button>
        </FlexRow>
      </FlexCol>
    );
  };

  render() {
    const { closeModal, isModalOpen } = this.props;
    const { campaign } = this.context;
    const { duplicateSettings } = campaign;

    return (
      <Modal
        className="ConfirmDuplicatesModal"
        isModalOpen={isModalOpen}
        onRequestClose={closeModal}
        showCloseButton={false}
      >
        {duplicateSettings === "warn" && this.renderWarning()}
        {duplicateSettings === "error" && this.renderError()}
      </Modal>
    );
  }
}

export { ConfirmDuplicatesModal };

export default ConfirmDuplicatesModal;
