import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const CAMPAIGN_LEAD_FORMS_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/lead_forms`);

const CAMPAIGN_LEAD_FORM_ENDPOINT = (campaignId, leadFormId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/lead_forms/${leadFormId}`);

export async function getLeadForms(campaignId) {
  const response = await axiosService.axiosGet(
    CAMPAIGN_LEAD_FORMS_ENDPOINT(campaignId)
  );

  return response.data;
}

export async function deleteLeadForm(campaignId, leadFormId) {
  const response = await axiosService.axiosDelete(
    CAMPAIGN_LEAD_FORM_ENDPOINT(campaignId, leadFormId)
  );

  return response.data;
}

export async function updateLeadForm(campaignId, leadFormId, payload) {
  const response = await axiosService.axiosPut(
    CAMPAIGN_LEAD_FORM_ENDPOINT(campaignId, leadFormId),
    payload
  );

  return response.data;
}

export async function postLeadForm(campaignId, payload) {
  const response = await axiosService.axiosPost(
    CAMPAIGN_LEAD_FORMS_ENDPOINT(campaignId),
    payload
  );

  return response.data;
}
