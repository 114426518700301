import React from "react";
import PropTypes from "prop-types";
import { Button } from "wm-ui-toolkit";
import { AppContext } from "../../../context/AppProvider";
import { PlusIcon } from "wm-graphics";
import { withTranslations } from "../../../utils/withTranslations";
import "./AddLeadSlideoutButton.scss";

@withTranslations
export class AddLeadSlideoutButton extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    onClick: PropTypes.func,
    t: PropTypes.func,
    visible: PropTypes.bool,
  };

  render() {
    const { onClick, t, visible } = this.props;
    const { campaign } = this.context;
    const { leadLabel } = campaign;

    return (
      visible && (
        <Button
          className="AddLeadSlideoutButton"
          testId="AddLeadSlideoutButton"
          onClick={onClick}
          round
          tooltipMessage={t("common:layouts.addLead", { leadLabel })}
        >
          <PlusIcon width="20" height="20" data-testid="PlusIcon" />
        </Button>
      )
    );
  }
}

export default AddLeadSlideoutButton;
