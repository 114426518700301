import { awsServicesEndpoint } from "../../utils/endpoint";
import { axiosGet, axiosPost } from "../axiosRequests";

const DUPLICATE_SERVICE = "duplicates-service";

const GET_DUPLICATES_ENDPOINT = (leadId) =>
  awsServicesEndpoint(DUPLICATE_SERVICE, `duplicates?leadId=${leadId}`);

const CHECK_DUPLICATES_ENDPOINT = awsServicesEndpoint(DUPLICATE_SERVICE, "checks");

export async function getDuplicates(token, leadId) {
  const response = await axiosGet(
    GET_DUPLICATES_ENDPOINT(leadId),
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
  return response.data;
}

export async function checkForDuplicates(token, payload) {
  const response = await axiosPost(CHECK_DUPLICATES_ENDPOINT, payload, {
    Authorization: `Bearer ${token}`,
  });
  return response.data;
}

export default { getDuplicates };
