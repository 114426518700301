import React, { ReactNode } from "react";
import { TranslationProvider } from "../providers/TranslationProvider";
import i18next from "../pages/TeamResources/translations/i18n";

interface ITeamResourcesRoute {
  children: ReactNode;
}

export const TeamResourcesRoute: React.FC<ITeamResourcesRoute> = ({ children }) => (
  <TranslationProvider i18n={i18next}>{children}</TranslationProvider>
);

export default TeamResourcesRoute;
