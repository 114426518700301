import axios from "axios";
import { endpoint } from "../utils/endpoint";

export const REMINDER_ENDPOINT = (reminderId) =>
  endpoint(`app/v1.0/reminders/${reminderId}.json`);

export async function putReminder(reminderId, payload) {
  const response = await axios.put(
    REMINDER_ENDPOINT(reminderId),
    {
      reminder: {
        ...payload,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
}

export default {
  putReminder,
};
