import * as axiosService from "./axiosRequests";
import { endpoint } from "../utils/endpoint";

const PURCHASABLE_ENDPOINT = (purchasableId) =>
  endpoint(`app/v1.0/purchasables/${purchasableId}`);

export async function deletePurchasable(purchasableId) {
  const response = await axiosService.axiosDelete(
    PURCHASABLE_ENDPOINT(purchasableId)
  );

  return response.data;
}

export default {
  deletePurchasable,
};
