import React, {
  useContext, useEffect, useState, useRef,
} from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Button, Flex, Text } from "@wingmate/toolkit";
import { Spinner } from "wm-ui-toolkit";
import { useTranslation } from "react-i18next";
import { TargetIcon } from "@wingmate/graphics";
import { useStore } from "../../../hooks/useStore";
import { Section } from "../../Section/Section";
import { AppContext } from "../../../context/AppProvider";
import { ScoreCardChart } from "../../../common/ScoreCardChart/ScoreCardChart";

import "./CurrentScoreCard.scss";

export const CurrentScoreCard: React.FC = inject("rootStore")(observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation(["default", "common"]);
  const { currentUser } = useContext(AppContext);
  const { performancesStore, tasksStore } = useStore();
  const { performances } = performancesStore;

  const scoreCardRef = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState<boolean>(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (scoreCardRef.current && !scoreCardRef.current.contains(event?.target as HTMLElement)) {
      setVisible(false);
    }
  };

  const getTasksAndPerformances = async () => {
    await tasksStore.getTasksAsync();
    await performancesStore.getPerformancesAsync(currentUser.id);
  };

  useEffect(() => () => {
    document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleMouseClick = async () => {
    if (!visible) {
      setVisible(true);

      await getTasksAndPerformances();
      setIsLoading(false);

      document.addEventListener("mousedown", handleClickOutside);
    } else {
      setVisible(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const renderDateRange = () => {
    let firstPerformance;
    let dateRange;
    if (performances.length) {
      firstPerformance = performances[0].attributes;
      const fromDate = moment(firstPerformance.startDate).format("MMM.DD");
      const toDate = moment(firstPerformance.endDate).format("MMM.DD, YYYY");
      dateRange = `${fromDate}-${toDate}`;
    }

    return (
      <Text className="dateRange" type="H6">
        {dateRange}
      </Text>
    );
  };

  const renderUserScoreCard = () => (
    <Section className="CurrentScoreCard__scoreCard">
      <div className="CurrentScoreCard__topHeader">
        <Text type="H6">{t("common:layouts.targetTitle")}</Text>
        {renderDateRange()}
      </div>
      <Flex className="scoreCard__chartContainer" align="center" justify="center">
        {
          isLoading ? (
            <Spinner />
          ) : (
            <ScoreCardChart
              performances={performancesStore.currentUserPerformances}
            />
          )
        }
      </Flex>
    </Section>
  );

  return (
    <div className="CurrentScoreCard" ref={scoreCardRef}>
      <Button
        data-testid="CurrentScoreCard"
        onClick={handleMouseClick}
        onKeyDown={handleMouseClick}
      >
        <TargetIcon width="35px" height="35px" />
      </Button>
      {visible && renderUserScoreCard()}
    </div>
  );
}));

export default CurrentScoreCard;
