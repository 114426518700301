import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { inject, observer } from "mobx-react";
import { NotificationBellIcon } from "@wingmate/graphics";
import { Button } from "@wingmate/toolkit";
import { AppContext } from "../../../context/AppProvider";
import { rootStorePropTypes } from "../../../proptypes/stores";
import { withTranslations } from "../../../utils/withTranslations";
import { Flyout } from "../../../controls/Flyout/Flyout";
import { NotificationsCenter } from "./NotificationsCenter/NotificationsCenter";

import "./Notifications.scss";

@withTranslations
@inject("rootStore")
@observer
export class Notifications extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    rootStore: rootStorePropTypes,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      newNotifications: [],
      newNotificationsCount: 0,
      isLoading: true,
      showFlyout: false,
    };

    this.flyoutRef = React.createRef();
  }

  async componentDidMount() {
    const { currentUser } = this.context;
    const { rootStore, t } = this.props;
    const { bannerStore } = rootStore;
    const { unviewedNotificationCount } = currentUser;

    try {
      await this.getApprovalRequests();
    } catch (error) {
      bannerStore.addBanner(
        "red",
        t("common:approval.failed"),
        t("common:approval.failedMessage")
      );
    }

    this.setState({ newNotificationsCount: unviewedNotificationCount });
  }

  getApprovalRequests = async () => {
    const { campaign, currentUser } = this.context;
    const { rootStore } = this.props;
    const { approvalRequestsStore } = rootStore;

    if (currentUser.type === "Manager" && campaign.approvalsEnabled) {
      await approvalRequestsStore.getApprovalRequestsAsync();
    }
  };

  getUserNotifications = async () => {
    const { rootStore } = this.props;
    const { notificationsStore } = rootStore;
    const { currentUser } = this.context;

    await notificationsStore.getUserNotificationsAsync(currentUser.id);

    const { notifications } = notificationsStore;
    let newNotificationsList = [];
    notifications.forEach((notification) => {
      if (notification.attributes.viewed === false) {
        newNotificationsList.push(notification);
      }
    });

    this.setState({
      newNotifications: newNotificationsList,
      newNotificationsCount: newNotificationsList.length,
      isLoading: false,
    });
  };

  onClick = async () => {
    const { showFlyout } = this.state;
    await this.getUserNotifications();
    this.getApprovalRequests();
    this.setShowFlyout(!showFlyout);
  };

  closeFlyout = () => {
    this.setShowFlyout(false);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.flyoutRef && !this.flyoutRef.current.contains(event.target)) {
      this.closeFlyout();
    }
  };

  setShowFlyout = (newShowFlyout) => {
    if (newShowFlyout) {
      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }

    this.setState({ showFlyout: newShowFlyout });
  };

  renderFlyoutButton = () => {
    const { currentUser } = this.context;
    const { newNotificationsCount } = this.state;
    const { rootStore } = this.props;
    const { approvalRequestsStore } = rootStore;
    const { approvalsOpenCount } = approvalRequestsStore;

    const currentUserIsManager = currentUser.type === "Manager";

    const dotClassName = classNames("NotificationsButton__dot", {
      blue: currentUserIsManager && approvalsOpenCount > 0,
      red: currentUserIsManager && newNotificationsCount > 0,
    });

    const showDot = approvalsOpenCount > 0 || newNotificationsCount > 0;
    const showSemiCircle =
      currentUserIsManager &&
      approvalsOpenCount > 0 &&
      newNotificationsCount > 0;

    return (
      <Button onClick={this.onClick}>
        <NotificationBellIcon />
        {showDot && <span className={dotClassName}></span>}
        {showSemiCircle && (
          <span className="NotificationsButton__semicircle"></span>
        )}
      </Button>
    );
  };

  renderFlyout = () => {
    const { isLoading, newNotifications, showFlyout } = this.state;
    const { rootStore } = this.props;
    const { approvalRequestsStore } = rootStore;
    const { allApprovals } = approvalRequestsStore;

    if (showFlyout) {
      return (
        <Flyout
          placementRef={this.flyoutRef.current}
          placement="bottom"
          showArrow={false}
        >
          <NotificationsCenter
            approvalRequests={allApprovals}
            closeNotificationMenu={this.closeFlyout}
            notifications={newNotifications}
            isLoading={isLoading}
          />
        </Flyout>
      );
    }
  };

  render() {
    return (
      <div
        ref={this.flyoutRef}
        className="NotificationsButton"
        data-testid="NotificationsButton"
      >
        {this.renderFlyoutButton()}
        {this.renderFlyout()}
      </div>
    );
  }
}
