import { action } from "mobx";

export class BaseStore {
  constructor() {
    this.abortController = new AbortController();
  }

  @action
  clear() {
    // Override this method in derived classes to clear their state
    throw new Error("clear() method must be overridden in derived classes");
  }

  @action
  cancelPreviousRequests() {
    this.abortController.abort("This request was cancelled");
    this.abortController = new AbortController();
  }
}