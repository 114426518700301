import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { AppContext } from "../context/AppProvider";
import { TranslationProvider } from "../providers/TranslationProvider";
import { withRouter } from "../utils/withRouter";
import i18next from "../pages/WorkerDashboard/translations/i18n";

const WORKER_TYPE = "Worker";

@withRouter
export class WorkerDashboardRoute extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { currentUser } = this.context;
    const { children } = this.props;

    return (
      <TranslationProvider i18n={i18next}>
        {currentUser.type == WORKER_TYPE ? children : <Navigate to="/" />}
      </TranslationProvider>
    );
  }
}

export default WorkerDashboardRoute;
