import * as axiosService from "./axiosRequests";
import { endpoint } from "../utils/endpoint";

const PRICE_SHEET_ENDPOINT = (priceSheetId) =>
  endpoint(`app/v1.0/price_sheets/${priceSheetId}`);

export async function putPriceSheet(priceSheetId, payload) {
  const response = await axiosService.axiosPut(
    PRICE_SHEET_ENDPOINT(priceSheetId),
    payload
  );

  return response.data;
}

export default {
  putPriceSheet,
};
