import { endpoint } from "../../utils/endpoint";

const UPDATE_REMINDER_ENDPOINT = (reminderId) =>
  endpoint(`app/v1.0/reminders/${reminderId}`);

const CREATE_LEAD_REMINDER_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/reminders`);

import * as axiosService from "../axiosRequests";

export async function postReminder(leadId, payload) {
  const response = await axiosService.axiosPost(
    CREATE_LEAD_REMINDER_ENDPOINT(leadId),
    {
      reminder: {
        ...payload,
      },
    }
  );

  return response;
}

export async function putReminder(reminderId, payload) {
  const response = await axiosService.axiosPut(
    UPDATE_REMINDER_ENDPOINT(reminderId),
    {
      reminder: payload,
    }
  );

  return response.data.data;
}

export async function deleteReminder(reminderId) {
  await axiosService.axiosDelete(UPDATE_REMINDER_ENDPOINT(reminderId));
  return true;
}

export default {
  postReminder,
  putReminder,
  deleteReminder,
};
