export const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
export const integrationBaseUrl =
  process.env.REACT_APP_INTEGRATION_SERVICE_BASE_URL;

export const awsServicesBaseUrl = process.env.REACT_APP_AWS_SERVICES_BASE_URL;

export const endpoint = (action) => {
  return `${baseUrl}/${action}`;
};

export const integrationEndpoint = (action) => {
  return `${integrationBaseUrl}/${action}`;
};

export const awsServicesEndpoint = (service, action) => {
  return `${awsServicesBaseUrl}/${service}/v1/${action}`;
};
