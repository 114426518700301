import { axiosGet, axiosPost } from "../axiosRequests";
import { integrationEndpoint } from "../../utils/endpoint";

const GET_APP_DETAILS_ENDPOINT = integrationEndpoint("integrationsBucket");

export async function authenticateAppInstall(
  payload,
  authenticateAppInstallEndpoint
) {
  let endpoint = integrationEndpoint(authenticateAppInstallEndpoint);
  const response = await axiosPost(endpoint, payload);
  return response.data;
}

export async function getAppDetails(appId) {
  const endpoint = `${GET_APP_DETAILS_ENDPOINT}?appId=${appId}`;
  const response = await axiosGet(endpoint);
  return response.data;
}
