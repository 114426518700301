import { action, makeAutoObservable, observable } from "mobx";
import * as leadsChasedMetricsService from "../requests/leadsChasedMetrics";

export class LeadsChasedMetricsStore {
  @observable leadsChasedMetrics = [];

  @observable isLoading = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setLeadsChasedMetrics(leadsChasedMetrics) {
    this.leadsChasedMetrics = leadsChasedMetrics;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  async getLeadsChasedMetricsAsync() {
    this.setIsLoading(true);
    const leadsChasedMetrics =
      await leadsChasedMetricsService.getLeadsChasedMetrics();

    this.setLeadsChasedMetrics(leadsChasedMetrics);
    this.setIsLoading(false);
  }
}

export default LeadsChasedMetricsStore;
