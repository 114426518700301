import React from "react";
import PropTypes from "prop-types";
import { Section } from "../../layouts/Section";
import { FlexCol, Spinner, Text } from "wm-ui-toolkit";
import { withTranslations } from "../../utils/withTranslations";
import { CheckmarkCircleIcon, XCircleIcon } from "wm-graphics";
import * as authenticateService from "../../requests/oauth/authenticate";
import "./OauthProcessing.scss";

@withTranslations
export class OauthProcessing extends React.Component {
  static propTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      message: props.t("processing.loading"),
      isLoading: true,
      status: null,
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const secretCode = urlParams.get("code");
      const state = JSON.parse(urlParams.get("state"));
      const appInstallId = state.appInstallId;
      const appId = appInstallId.split("-")[1];

      const appDetails = await authenticateService.getAppDetails(appId);
      const authenticateAppInstallEndpoint = appDetails.appAuthenticateEndpoint;

      // 1. You have the App ID, call to get the App info
      // 2. Get the authenticateAppInstall route from the App.

      const payload = {
        secretCode,
        ...state,
      };

      await authenticateService.authenticateAppInstall(
        payload,
        authenticateAppInstallEndpoint
      );
      this.setState({
        status: "success",
        title: t("processing.success.title"),
        message: t("processing.success.message"),
      });
    } catch (error) {
      this.setState({
        status: "failed",
        title: t("processing.failed.title"),
        message: t("processing.failed.message"),
      });
    }

    this.setState({ isLoading: false });
  }

  renderIcon = () => {
    const { status } = this.state;

    if (status === "success") {
      return (
        <CheckmarkCircleIcon
          className="OauthProcessing__success"
          height={80}
          width={80}
          data-testid="CheckmarkCircleIcon"
        />
      );
    }

    if (status === "failed") {
      return (
        <XCircleIcon
          className="OauthProcessing__failed"
          height={80}
          width={80}
          data-testid="XCircleIcon"
        />
      );
    }
  };

  render() {
    const { isLoading, message, title } = this.state;

    return (
      <FlexCol>
        <Section className="OauthProcessing">
          <FlexCol gap="L" alignItems="center">
            {isLoading ? <Spinner /> : this.renderIcon()}
            <FlexCol alignItems="center">
              <Text className="OauthProcessing__title" type="H4">
                {title}
              </Text>
              <Text type="H6">{message}</Text>
            </FlexCol>
          </FlexCol>
        </Section>
      </FlexCol>
    );
  }
}
