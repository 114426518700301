import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { MentionFlyout } from "./MentionFlyout/MentionFlyout";
import "./Mention.scss";

export class Mention extends React.Component {
  static propTypes = {
    isContact: PropTypes.bool,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      showFlyout: false,
    };

    this.flyoutRef = React.createRef();
  }

  onMouseEnter = () => {
    const { isContact } = this.props;
    this.setState({ showFlyout: !isContact });
  };

  onMouseLeave = () => {
    this.setState({ showFlyout: false });
  };

  render() {
    const { showFlyout } = this.state;
    const { isContact, user } = this.props;

    const mentionTextClasses = classNames("Mention__text", {
      contact: isContact,
      user: !isContact,
    });

    const mentionText = user.tag || user.email;

    return (
      <>
        <span
          ref={this.flyoutRef}
          className="Mention"
          data-testid="mention"
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <span className={mentionTextClasses}>
            {isContact ? (
              <a href={`mailto:${mentionText}`}>{mentionText}</a>
            ) : (
              mentionText
            )}
          </span>
        </span>
        {!isContact && (
          <MentionFlyout
            showFlyout={showFlyout}
            flyoutRef={this.flyoutRef.current}
            user={user}
          />
        )}
      </>
    );
  }
}

export default Mention;
