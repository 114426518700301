import React from "react";
import PropTypes from "prop-types";
import { Text, TextField } from "wm-ui-toolkit";
import { EditPencilIcon } from "wm-graphics";
import { ConfirmButtons } from "../ConfirmButtons/ConfirmButtons";
import "./ConfirmText.scss";

const ENTER_KEY = "Enter";

export class ConfirmText extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    onSave: PropTypes.func,
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      text: props.defaultValue,
      newText: props.defaultValue,
    };

    this.confirmTextRef = React.createRef();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.confirmTextRef &&
      !this.confirmTextRef.current.contains(event.target)
    ) {
      this.saveNewText(event);
      this.turnEditModeOff();
    }
  };

  setEditMode = (newEditMode) => {
    if (newEditMode) {
      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }

    this.setState({ editMode: newEditMode });
  };

  onChange = (newText) => {
    this.setState({ newText });
  };

  eraseNewText = (event) => {
    event.stopPropagation();
    event.preventDefault();

    this.turnEditModeOff();
  };

  onKeyDownEraseNewText = (event) => {
    if (event.key === ENTER_KEY) {
      this.eraseNewText(event);
    }
  };

  saveNewText = (event) => {
    const { onSave } = this.props;
    const { newText } = this.state;

    event.stopPropagation();
    event.preventDefault();

    this.setState({ text: newText });
    onSave(newText);
    this.turnEditModeOff();
  };

  onKeyDownSaveNewText = (event) => {
    if (event.key === ENTER_KEY) {
      this.saveNewText(event);
    }
  };

  turnEditModeOn = () => {
    const { text } = this.state;

    this.setState({ newText: text });
    this.setEditMode(true);
  };

  onKeyDownTurnEditModeOn = () => {
    if (event.key === ENTER_KEY) {
      this.turnEditModeOn(event);
    }
  };

  turnEditModeOff = () => {
    const { text } = this.state;
    this.setState({ newText: text });
    this.setEditMode(false);
  };

  renderSaveOptions = () => {
    return (
      <ConfirmButtons
        onConfirm={this.saveNewText}
        onCancel={this.eraseNewText}
      />
    );
  };

  renderEditText = () => {
    const { text } = this.state;
    return <TextField defaultValue={text} onChange={this.onChange} />;
  };

  renderText = () => {
    const { placeholder } = this.props;
    const { text } = this.state;

    return <Text>{text ? text : placeholder}</Text>;
  };

  renderEditSymbol = () => {
    return (
      <div className="ConfirmText__editSymbol">
        <EditPencilIcon height={10} width={10} />
      </div>
    );
  };

  render() {
    const { editMode } = this.state;

    return (
      <div
        className="ConfirmText"
        data-testid="ConfirmText"
        ref={this.confirmTextRef}
        onClick={this.turnEditModeOn}
        onKeyDown={this.onKeyDownTurnEditModeOn}
        role="button"
        tabIndex={0}
      >
        {editMode ? this.renderEditText() : this.renderText()}
        {editMode ? this.renderSaveOptions() : this.renderEditSymbol()}
      </div>
    );
  }
}

export default ConfirmText;
