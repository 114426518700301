import { axiosDelete, axiosGet, axiosPost } from "./axiosRequests";
import { endpoint } from "../utils/endpoint";

const ATTACHMENT_ENDPOINT = (id) => endpoint(`app/v1.0/attachments/${id}`);

const EMAIL_ATTACHMENT_ENDPOINT = (id) => endpoint(`app/v1.0/attachments/${id}/email`);

export async function getAttachment(attachmentId, payload = {}) {
  const response = await axiosGet(ATTACHMENT_ENDPOINT(attachmentId), payload);

  return response.data;
}

export async function deleteAttachment(attachmentId) {
  const response = await axiosDelete(ATTACHMENT_ENDPOINT(attachmentId));

  return response.data;
}

export async function emailAttachment(attachmentId, payload = {}) {
  const response = await axiosPost(EMAIL_ATTACHMENT_ENDPOINT(attachmentId), payload);

  return response.data;
}

export default {
  deleteAttachment,
  emailAttachment,
  getAttachment
};
