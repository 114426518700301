import {
  action, makeObservable, observable,
} from "mobx";
import { ICampaign } from "../types/ICampaign";
import { IRootStore } from "../types/IRootStore";
import * as filesService from "../requests/teamResources/teamResources";
import { TeamResourcesRow } from "../pages/TeamResources/TeamResources";

export interface IFile {
  name: string;
  metadata: {
    created_by: string;
    created_on: string;
  };
  type: string;
  downloadUrl: string;
  previewUrl: string;
}

export class TeamResourcesStore {
  rootStore;

  @observable isLoading = false;

  @observable files: IFile[] = [];

  @observable folderName = "";

  @observable insideFolder = false;

  @observable selectedFile: TeamResourcesRow | null = null;

  @observable previewOpen = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.isLoading = false;
  }

  @action
  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @action
  setFiles(files: IFile[]) {
    this.files = files;
  }

  @action
  setFolderName(value: string) {
    this.folderName = value;
  }

  @action
  setInsideFolder(value: boolean) {
    this.insideFolder = value;
  }

  @action
  setSelectedFile(value: TeamResourcesRow | null) {
    this.selectedFile = value;
  }

  @action
  setPreviewOpen(value: boolean) {
    this.previewOpen = value;
  }

  @action
  async getFilesAtPath(
    authToken: string,
    campaignId: ICampaign["id"],
    path: string,
  ) {
    this.setIsLoading(true);

    try {
      const files = await filesService.getFilesAtPath(authToken, campaignId, path);
      this.setFiles(files);
    } catch (error) {
      this.setFiles([]);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async uploadFile(
    authToken: string,
    campaignId: ICampaign["id"],
    file: File,
    path: string,
  ) {
    this.setIsLoading(true);

    try {
      const uploadUrl = await filesService.getUploadUrl(authToken, campaignId, file.name, path);
      await filesService.uploadToS3(uploadUrl, file);
      await this.getFilesAtPath(authToken, campaignId, path);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async createFolder(
    authToken: string,
    campaignId: ICampaign["id"],
    folderName: string,
    path: string,
  ) {
    this.setIsLoading(true);

    try {
      await filesService.createFolder(authToken, campaignId, folderName, path);
      await this.getFilesAtPath(authToken, campaignId, path);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async deleteFolder(
    authToken: string,
    campaignId: ICampaign["id"],
    folderName: string,
    path: string,
  ) {
    this.setIsLoading(true);

    try {
      const result = await filesService.deleteFolder(authToken, campaignId, folderName, path);
      await this.getFilesAtPath(authToken, campaignId, path);
      return {
        status: result.status,
        message: result.message,
      };
    } catch {
      return {
        status: 500,
        message: "",
      };
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async deleteFile(
    authToken: string,
    campaignId: ICampaign["id"],
    fileName: string,
    path: string,
  ) {
    this.setIsLoading(true);

    try {
      await filesService.deleteFile(authToken, campaignId, fileName, path);
      await this.getFilesAtPath(authToken, campaignId, path);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async renameFile(
    authToken: string,
    campaignId: ICampaign["id"],
    oldFileName: string,
    newFileName: string,
    path: string,
  ) {
    this.setIsLoading(true);

    try {
      await filesService.renameFile(authToken, campaignId, oldFileName, newFileName, path);
      await this.getFilesAtPath(authToken, campaignId, path);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async renameFolder(
    authToken: string,
    campaignId: ICampaign["id"],
    oldFolderName: string,
    newFolderName: string,
    path: string,
  ) {
    this.setIsLoading(true);

    try {
      await filesService.renameFolder(authToken, campaignId, oldFolderName, newFolderName, path);
      await this.getFilesAtPath(authToken, campaignId, path);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export default TeamResourcesStore;
