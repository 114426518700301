import PropTypes from "prop-types";

export const userPropTypes = PropTypes.shape({
  attributes: PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    profileImageUrl: PropTypes.string,
    type: PropTypes.string,
  }),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
});

export const campaignPropTypes = PropTypes.shape({
  amount: PropTypes.shape({
    maximum: PropTypes.number,
    minimum: PropTypes.number,
  }),
  customFields: PropTypes.array,
  contactFields: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  leadLabel: PropTypes.string,
  leadStatuses: PropTypes.object,
  statusDescription: PropTypes.object,
});

export const currentUserPropTypes = PropTypes.shape({
  id: PropTypes.number,
  openLeadsInNewTab: PropTypes.bool,
  enableTileDetails: PropTypes.bool,
  scrollbarEnabled: PropTypes.bool,
  type: PropTypes.string,
  amountVisible: PropTypes.bool,
});
