import React from "react";
import PropTypes from "prop-types";
import { TranslationProvider } from "../providers/TranslationProvider";
import i18next from "../pages/ErrorPage/translations/i18n";

export class ErrorPageRoute extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children } = this.props;

    return <TranslationProvider i18n={i18next}>{children}</TranslationProvider>;
  }
}

export default ErrorPageRoute;
