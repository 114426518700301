import { action, makeObservable, observable } from "mobx";
import { AdminStore } from "./AdminStore";
import { ICampaign } from "../types/ICampaign";
import * as userCampaignsService from "../requests/users/campaigns";

export class CampaignsStore {
  @observable adminStore;

  @observable campaigns: ICampaign[] = [];

  @observable isLoading = false;

  constructor(adminStore: AdminStore) {
    makeObservable(this);

    this.adminStore = adminStore;
  }

  @action
  setCampaigns(campaigns: ICampaign[]) {
    this.campaigns = campaigns;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  async retrieveCampaigns(userId: string) {
    this.isLoading = true;

    try {
      const { data } = await userCampaignsService.getCampaigns(userId);

      this.setCampaigns(data);
    } catch (error) {
      this.setCampaigns([]);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export default CampaignsStore;
