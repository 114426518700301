import { action, makeAutoObservable, observable } from "mobx";
import * as notificationsService from "../requests/notifications";

export class NotificationsStore {
  @observable notifications = [];

  @observable count = 0;

  @observable page = {};

  @observable hasMoreNotifications = false;

  @observable isLoading = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setNotifications(notifications) {
    this.notifications = notifications;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setCount(count) {
    this.count = count;
  }

  @action
  setPage(page) {
    this.page = page;
  }

  @action
  setHasMoreNotifications(more) {
    this.hasMoreNotifications = more;
  }

  @action
  async getUserNotificationsAsync(userId) {
    this.setIsLoading(true);

    try {
      const { data, meta } = await notificationsService.getUserNotifications(
        userId
      );

      const { count, page } = meta;
      this.setCount(count);
      this.setPage(page);
      this.setHasMoreNotifications(page.more);

      this.setNotifications(data);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async getNextPageAsync(userId) {
    if (!this.page.more) {
      return;
    }

    this.setIsLoading(true);
    const notificationsPayload = {
      page: this.page.next,
    };

    try {
      const { data, meta } = await notificationsService.getUserNotifications(
        userId,
        notificationsPayload
      );

      const { page } = meta;

      this.setPage(page);
      this.setHasMoreNotifications(page.more);

      const allNotifications = this.notifications.concat(data);
      this.setNotifications(allNotifications);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async cleanUserNotificationsAsync(userId) {
    this.setIsLoading(true);
    await notificationsService.postViewUserNotifications(userId);

    this.setIsLoading(false);
  }
}

export default NotificationsStore;
