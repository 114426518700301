import { axiosGet, axiosPost, axiosDelete } from "../../axiosRequests";
import { segmentEndpoint } from "../segments/segments";
import { ICampaign } from "../../../types/ICampaign";
import { ISegment, ISegmentAccess } from "../../../types/ISegment";

const segmentAccessEndpoint = (
  campaignId: ICampaign["id"],
  segmentId: ISegment["id"],
): string => `${segmentEndpoint(campaignId, segmentId)}/segmentAccess`;

export const getSegmentAccess = async (
  token: string,
  campaignId: ICampaign["id"],
  segmentId: ISegment["id"],
): Promise<ISegmentAccess[]> => {
  const response = await axiosGet(
    segmentAccessEndpoint(campaignId, segmentId),
    {},
    {
      Authorization: `Bearer ${token}`,
    },
  );

  return response.data.accessibleBy;
};

export const updateSegmentAccess = async (
  token: string,
  campaignId: ICampaign["id"],
  segmentId: ISegment["id"],
  accessEntries: Omit<ISegmentAccess, "creator">[],
): Promise<ISegmentAccess[]> => {
  const response = await axiosPost(
    segmentAccessEndpoint(campaignId, segmentId),
    { accessEntries },
    {
      Authorization: `Bearer ${token}`,
    },
  );

  return response.data.accessibleBy;
};

export const deleteSegmentAccess = async (
  token: string,
  campaignId: ICampaign["id"],
  segmentId: ISegment["id"],
  accessEntries: Omit<ISegmentAccess, "creator">[],
) => {
  const response = await axiosDelete(
    segmentAccessEndpoint(campaignId, segmentId),
    { accessEntries },
    {
      Authorization: `Bearer ${token}`,
    },
  );

  return response.data;
};
