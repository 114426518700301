import React from "react";
import PropTypes from "prop-types";
import { withTranslations } from "../utils/withTranslations";
import { RouteBreadcrumbs } from "../controls/RouteBreadcrumbs/RouteBreadcrumbs";
import { EditQuoteTemplateBreadcrumb } from "../pages/Admin/EditQuoteTemplate/EditQuoteTemplateBreadcrumb/EditQuoteTemplateBreadcrumb";
import { EditPriceSheetBreadcrumb } from "../pages/Admin/EditPriceSheet/EditPriceSheetBreadcrumb/EditPriceSheetBreadcrumb";
import { UserDetailsBreadcrumbs } from "../pages/Admin/UserListing/UserDetails/UserDetailsBreadcrumbs/UserDetailsBreadcrumbs";
import { EnterprisePayoutBreadcrumbs } from "../pages/Admin/EnterprisePayouts/EnterprisePayout/EnterprisePayoutBreadcrumb";
const renderEditPriceSheetBreadcrumbs = () => <EditPriceSheetBreadcrumb />;

const renderEditQuoteTemplateBreadcrumbs = () => (
  <EditQuoteTemplateBreadcrumb />
);

const renderUserDetailsBreadcrumbs = () => <UserDetailsBreadcrumbs />;

const renderEnterprisePayoutBreadcrumbs = () => <EnterprisePayoutBreadcrumbs />;

@withTranslations
export class AdminRouteBreadcrumbs extends React.Component {
  static propTypes = {
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { t } = props;

    this.adminRoutes = [
      {
        path: "admin/campaigns/:campaignId",
        breadcrumb: t("breadCrumb.adminConsole"),
        children: [
          { path: "price_sheets", breadcrumb: t("breadCrumb.priceSheets") },
          {
            path: "price_sheets/:id/edit",
            breadcrumb: renderEditPriceSheetBreadcrumbs,
          },
          { path: "approval_conditions", breadcrumb: t("breadCrumb.approvalConditions") },
          { path: "quote_settings", breadcrumb: t("breadCrumb.quoteSettings") },
          {
            path: "quote_templates",
            breadcrumb: t("breadCrumb.quoteTemplates"),
          },
          {
            path: "quote_templates/:quoteTemplateId",
            breadcrumb: renderEditQuoteTemplateBreadcrumbs,
          },
          { path: "edit", breadcrumb: t("breadCrumb.campaignSettings") },
          { path: "users", breadcrumb: t("breadCrumb.userListing") },
          { path: "mail/new", breadcrumb: t("breadCrumb.teamBroadcast") },
          { path: "users/:userId", breadcrumb: renderUserDetailsBreadcrumbs },
          { path: "users/:userId/edit", breadcrumb: t("breadCrumb.editUser") },
          { path: "score_card", breadcrumb: t("breadCrumb.scoreCard") },
          { path: "lead_forms", breadcrumb: t("breadCrumb.leadForms") },
          {
            path: "lead_forms/:lead_forms",
            breadcrumb: t("breadCrumb.editForm"),
          },
          { path: "lead_pages", breadcrumb: t("breadCrumb.programDetails") },
          {
            path: "enterprise_payouts",
            breadcrumb: t("breadCrumb.enterprise"),
          },
          {
            path: "enterprise_payouts/:id",
            breadcrumb: renderEnterprisePayoutBreadcrumbs,
          },
          {
            path: "enterprise_payouts/new",
            breadcrumb: t("breadCrumb.generate"),
          },
          {
            path: "integrations",
            breadcrumb: t("breadCrumb.integrateWingmate"),
          },
          {
            path: "territories",
            breadcrumb: t("breadCrumb.territories"),
          },
          {
            path: "webhook_requests",
            breadcrumb: t("breadCrumb.webhookRequests"),
          },
          {
            path: "zapier",
            breadcrumb: t("breadCrumb.zapier"),
          },
          { path: "pm_notes", breadcrumb: t("breadCrumb.pmPlaybook") },
        ],
      },
    ];
  }

  render() {
    return (
      <RouteBreadcrumbs
        routes={this.adminRoutes}
        options={{ disableDefaults: true }}
      />
    );
  }
}

export default AdminRouteBreadcrumbs;
