import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const CREATE_LEAD_PURCHASE_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/purchases`);

const LEAD_PURCHASE_ENDPOINT = (leadId, purchaseId) =>
  endpoint(`app/v1.0/leads/${leadId}/purchases/${purchaseId}`);

export async function postPurchase(leadId, purchase) {
  const payload = {
    purchase,
  };
  const response = await axiosService.axiosPost(
    CREATE_LEAD_PURCHASE_ENDPOINT(leadId),
    payload
  );
  return response.data;
}

export async function putPurchase(leadId, purchaseId, purchase) {
  const payload = {
    purchase,
  };

  const response = await axiosService.axiosPut(
    LEAD_PURCHASE_ENDPOINT(leadId, purchaseId),
    payload
  );

  return response.data;
}

export default {
  postPurchase,
  putPurchase,
};
