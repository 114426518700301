import * as axiosService from "../axiosRequests";

import { endpoint } from "../../utils/endpoint";

const CAMPAIGN_LEAD_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/leads`);

export async function getLeads(campaignId, payload = {}) {
  const response = await axiosService.axiosGet(
    CAMPAIGN_LEAD_ENDPOINT(campaignId),
    payload
  );

  return response.data;
}

export async function postLead(campaignId, lead) {
  const payload = {
    lead,
  };

  const response = await axiosService.axiosPost(
    CAMPAIGN_LEAD_ENDPOINT(campaignId),
    payload
  );

  return response.data;
}

export default {
  postLead,
};
