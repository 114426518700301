import { action, computed, makeObservable, observable } from "mobx";
import * as contractsService from "../requests/contracts";

export class ContractStore {
  rootStore;

  @observable contracts;

  @observable isLoading = true;

  @observable isInitialized = false;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.contracts = [];
  }

  @computed get userCampaigns() {
    return this.contracts.map((contract) => contract.attributes.campaign);
  }

  @action
  setIsInitialized(initialized) {
    this.isInitialized = initialized;
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setContracts(contracts) {
    this.contracts = contracts;
  }

  @action
  setContract(contract) {
    this.contracts[this.findContractIndex(contract.attributes.id)] = contract;
  }

  getActiveContract() {
    const contract = this.contracts.find(
      (contract) => contract.attributes.active === true
    );

    return contract;
  }

  findContractIndex(contractId) {
    const contractIndex = this.contracts.findIndex(
      (contract) => contract.id.toString() === contractId.toString()
    );

    return contractIndex;
  }

  @action
  async getUserContractsAsync() {
    this.setIsInitialized(false);
    try {
      const response = await contractsService.getContracts();

      this.setContracts(response);
      this.setIsInitialized(true);
    } catch (err) {
      this.setIsInitialized(true);
      throw err;
    } finally {
      this.setIsInitialized(true);
    }
  }

  @action
  async updateContract(contractId, payload) {
    this.setIsLoading(true);
    try {
      await contractsService.updateContract(contractId, payload);

      this.setIsLoading(false);
    } catch (err) {
      this.setIsLoading(false);
    }
  }

  @action
  async updateContractForCampaign(campaignId, payload) {
    this.setIsLoading(true);
    try {
      if (this.contracts.length === 0) {
        await this.getUserContractsAsync();
      }
      const contract = this.contracts.find(
        (contract) =>
          contract.attributes.campaignId.toString() === campaignId.toString()
      );
      await contractsService.updateContract(contract.id, payload);
      await this.getUserContractsAsync();
      this.setIsLoading(false);
    } catch (err) {
      this.setIsLoading(false);
    }
  }
}
