import React from "react";
import {
  Flex, Image, Spinner, Text,
} from "@wingmate/toolkit";
import { useTranslation } from "react-i18next";
import classname from "classnames";
import EmptyResults from "../../../../assets/navigation/empty-folder-search.png";
import { GlobalSearchResult } from "./GlobalSearchResult/GlobalSearchResult";
import { GlobalSearchCampaignToggle } from "./GlobalSearchCampaignToggle/GlobalSearchCampaignToggle";
import { ILeadAttributes } from "../../../../types/ILead";
import { IContract } from "../../../../types/IContract";

import "./GlobalSearchDropdown.scss";

export interface IGlobalSearchDropdownProps {
  campaignLeadCount?: number;
  className?: string;
  closeDropdown: () => void;
  contracts: IContract[];
  forwardRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  leads: ILeadAttributes[];
  loadMoreLeads: () => void;
  onCampaignChange: (campaignId: string) => void;
  searchText: string;
}

export const GlobalSearchDropdown: React.FC<IGlobalSearchDropdownProps> = ({
  campaignLeadCount,
  className,
  closeDropdown,
  contracts,
  forwardRef,
  isLoading,
  leads,
  loadMoreLeads,
  onCampaignChange,
  searchText,
}) => {
  const { t } = useTranslation(["default", "common"]);

  const renderNoResult = () => (
    <Flex className="Content-noResult" vertical gap={8} justify="center" align="center">
      {isLoading ? (
        <>
          <Spinner size={50} />
          <Text type="P">{t("common:navigation.globalSearch.loading")}</Text>
        </>
      ) : (
        <>
          <Image src={EmptyResults} alt="" width={108} height={108} />
          <Text type="P">{t("common:navigation.globalSearch.noResults")}</Text>
        </>
      )}
    </Flex>
  );

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const scrolledToBottomOfLeads = e.currentTarget.scrollHeight - e.currentTarget.scrollTop
      <= e.currentTarget.clientHeight + 1;

    if (scrolledToBottomOfLeads) {
      loadMoreLeads();
    }
  };

  const renderLeadResults = () => (
    <div className="Content--grid" data-testid="Content--grid" onScroll={handleScroll}>
      {leads.map((lead) => (
        <GlobalSearchResult
          key={lead.id}
          closeDropdown={closeDropdown}
          lead={lead}
          searchText={searchText}
        />
      ))}
    </div>
  );

  const globalSearchDropdownClassName = classname("GlobalSearchDropdown", className);

  return (
    <Flex
      className={globalSearchDropdownClassName}
      data-testid="GlobalSearchDropdown"
      justify="center"
    >
      <div ref={forwardRef} className="GlobalSearchDropdown__Container">
        <Flex className="GlobalSearchDropdown__Content" vertical gap={12}>
          <GlobalSearchCampaignToggle
            contracts={contracts}
            isLoading={isLoading}
            leadCount={campaignLeadCount}
            onChange={onCampaignChange}
          />
          {leads.length ? renderLeadResults() : renderNoResult()}
        </Flex>
      </div>
    </Flex>
  );
};

export default GlobalSearchDropdown;
