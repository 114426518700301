import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import ErrorBoundary from "../../providers/ErrorBoundary";
import { ConditionalWrapper } from "../../utils/ConditionalWrapper";

export class NavLayout extends React.Component {
  render() {
    return (
      <ConditionalWrapper
        condition={process.env.NODE_ENV === "production"}
        wrapper={(children) => <ErrorBoundary>{children}</ErrorBoundary>}
      >
        <div data-testid="navLayout">
          <ScrollRestoration />
          <div>
            <Outlet />
          </div>
        </div>
      </ConditionalWrapper>
    );
  }
}

export default NavLayout;
