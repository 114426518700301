import React, { useEffect, useState } from "react";
import {
  Flex, Spinner, ToggleItem, ToggleList,
} from "@wingmate/toolkit";
import { useTranslation } from "react-i18next";
import { IContract } from "../../../../../types/IContract";

import "./GlobalSearchCampaignToggle.scss";

export interface IGlobalSearchCampaignToggleProps {
  contracts: IContract[];
  isLoading: boolean;
  leadCount?: number;
  onChange: (campaignId: string) => void;
}

export const GlobalSearchCampaignToggle: React.FC<IGlobalSearchCampaignToggleProps> = ({
  contracts,
  isLoading,
  leadCount,
  onChange,
}) => {
  const { t } = useTranslation(["default", "common"]);

  const [selectedCampaignId, setSelectedCampaignId] = useState<null | string>(null);

  const selectedCampaignLabel = (campaignName: string) => {
    if (isLoading) {
      if (leadCount === undefined) {
        return (
          <Flex align="center" gap={8} component="span">
            {campaignName}
            <Spinner size={16} />
          </Flex>
        );
      }

      return (
        <Flex align="center" gap={8} component="span">
          {t("common:navigation.globalSearch.campaignResults", {
            campaignName,
            count: leadCount,
          })}
          <Spinner size={16} />
        </Flex>
      );
    }

    return leadCount === undefined ? campaignName : t("common:navigation.globalSearch.campaignResults", {
      campaignName,
      count: leadCount,
    });
  };

  const sortedCampaignItems: ToggleItem[] = contracts
    .filter((contract) => !contract.attributes.disabled)
    .sort((a) => (a.attributes.active ? -1 : 1))
    .map((contract) => {
      const { campaign } = contract.attributes;
      return {
        id: campaign.id,
        label: selectedCampaignId === campaign.id
          ? selectedCampaignLabel(campaign.attributes.name)
          : campaign.attributes.name,
      };
    });

  useEffect(() => {
    if (sortedCampaignItems.length && !selectedCampaignId) {
      setSelectedCampaignId(sortedCampaignItems[0].id);
    }
  }, [sortedCampaignItems, selectedCampaignId]);

  const handleClick = (campaignId: string) => {
    setSelectedCampaignId(campaignId);
    onChange(campaignId);
  };

  return (
    <ToggleList
      className="GlobalSearchCampaignToggle"
      data-testid="GlobalSearchCampaignToggle"
      items={sortedCampaignItems}
      onClick={handleClick}
      size="small"
    />
  );
};

export default GlobalSearchCampaignToggle;
