import {
  useEffect, useRef, ReactNode, ReactPortal,
} from "react";
import { createPortal } from "react-dom";

export const usePrependPortal = (component: ReactNode, container: Element | null): ReactPortal => {
  const portalContainer = useRef(document.createElement("div"));

  useEffect(() => {
    container?.prepend(portalContainer.current);
  }, [container]);

  return createPortal(component, portalContainer.current);
};

export default usePrependPortal;
