import React from "react";
import PropTypes from "prop-types";
import { Provider, inject, observer } from "mobx-react";
import { authStorePropTypes } from "../proptypes/stores";

@inject("authStore")
@observer
export class RootProvider extends React.Component {
  static propTypes = {
    authStore: authStorePropTypes,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.rootStore = props.authStore.rootStore;
  }

  render() {
    const { children } = this.props;
    return <Provider rootStore={this.rootStore}>{children}</Provider>;
  }
}

export default RootProvider;
