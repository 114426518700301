import { action, computed, makeObservable, observable } from "mobx";
import * as likesService from "../requests/leads/likes";

export class LikesStore {
  leadDetailsStore;

  @observable likes = [];

  @observable isLoading = false;

  constructor(leadDetailsStore) {
    this.leadDetailsStore = leadDetailsStore;
    makeObservable(this);
  }

  @computed get currentUserLike() {
    if (!this.isLoading) {
      const { rootStore } = this.leadDetailsStore;
      const { context } = rootStore;

      const { currentUser } = context;
      const currentUserLike = this.likes.find(
        (like) => like.attributes.userId === currentUser.id
      );
      return currentUserLike;
    }
    return null;
  }

  @computed get likedBy() {
    const { rootStore } = this.leadDetailsStore;
    const { usersStore } = rootStore;

    const { users } = usersStore;
    const likedBy = users
      .map((user) => {
        if (
          this.likes.find(
            (like) => like.attributes.userId === user.attributes.id
          )
        ) {
          return user;
        }
        return null;
      })
      .filter((user) => user !== null);

    return likedBy;
  }

  @action
  async onLikeChange() {
    const { currentUserLike, leadDetailsStore } = this;

    this.setIsLoading(true);
    if (currentUserLike) {
      await likesService.deleteLike(
        leadDetailsStore.leadId,
        currentUserLike.id
      );
    } else {
      const { rootStore } = this.leadDetailsStore;
      const { context } = rootStore;

      const { currentUser } = context;

      await likesService.postLike(leadDetailsStore.leadId, currentUser.id);
    }

    await this.retrieveLikesForLeadAsync();
    this.setIsLoading(false);
  }

  @action
  setLikes(likes) {
    this.likes = likes;
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @action
  async retrieveLikesForLeadAsync() {
    this.setIsLoading(true);
    try {
      const response = await likesService.getLikes(
        this.leadDetailsStore.leadId
      );
      this.setLikes(response);
      this.setIsLoading(false);
    } catch (err) {
      this.setIsLoading(false);
    }
  }
}

export default LikesStore;
