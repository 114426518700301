import { action, computed, makeAutoObservable, observable } from "mobx";
import moment from "moment";
import * as companyTransactionsService from "../requests/campaigns/companyTransactions";

export class CompanyTransactionsStore {
  @observable transactions = [];
  @observable transactionsByYear;
  @observable generatedTransaction;
  @observable isLoading = true;
  @observable isSaving = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setTransactions(transactions) {
    this.transactions = transactions;
  }

  @action
  setTransactionsByYear() {
    this.transactionsByYear = this.groupByYear();
  }

  @action
  setGeneratedTransaction(transaction) {
    this.generatedTransaction = transaction;
  }

  @action
  getTransaction(transactionId) {
    const transaction = this.transactions.find(
      (transaction) => transaction.id === transactionId
    );

    if (!transaction) {
      companyTransactionsService.getCompanyTransaction(
        this.rootStore.campaignId,
        transactionId
      );
    }

    return transaction;
  }

  @action
  getGeneratedTransaction() {
    return this.generatedTransaction;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @computed
  groupByYear() {
    const years = this.transactions.map((transaction) => {
      return moment(transaction.attributes.updatedAt).year();
    });

    const uniqueYears = [...new Set(years)].sort((a, b) => b - a);

    const groupedTransactions = uniqueYears.map((year) => {
      const yearHash = {};
      const yearGroup = this.transactions.filter(
        (transaction) =>
          moment(transaction.attributes.updatedAt).year() === year
      );
      yearHash[year] = yearGroup;
      return yearHash;
    });

    return groupedTransactions;
  }

  @action
  async getCompanyTransactionsAsync() {
    this.setIsLoading(true);
    const transactions =
      await companyTransactionsService.getCompanyTransactions(
        this.rootStore.campaignId
      );

    this.setTransactions(transactions);

    this.setTransactionsByYear();
    this.setIsLoading(false);

    return transactions;
  }

  @action
  replaceTransaction(newTransaction) {
    const indexOfTransaction = this.transactions.findIndex(
      (transaction) => transaction.id === newTransaction.id
    );

    if (indexOfTransaction !== -1) {
      this.transactions.splice(indexOfTransaction, 1, newTransaction);
    } else {
      this.setTransactions([newTransaction, ...this.transactions]);
    }
  }

  @action
  async getCompanyTransactionAsync(id) {
    this.setIsLoading(true);
    const transaction = await companyTransactionsService.getCompanyTransaction(
      this.rootStore.campaignId,
      id
    );

    this.replaceTransaction(transaction);
    this.setTransactionsByYear();
    this.setIsLoading(false);
  }

  @action
  async generateCompanyTransaction() {
    this.setIsLoading(true);
    const transaction = await companyTransactionsService.newCompanyTransaction(
      this.rootStore.campaignId
    );

    this.setGeneratedTransaction(transaction.attributes);

    this.setIsLoading(false);
    return transaction.attributes;
  }

  @action
  async createCompanyTransaction(payload) {
    this.setIsLoading(true);
    const transaction = await companyTransactionsService.postCompanyTransaction(
      this.rootStore.campaignId,
      payload
    );
    this.setTransactions([transaction, ...this.transactions]);
    this.setTransactionsByYear();
    this.setIsLoading(false);
    return transaction.attributes;
  }
}

export default CompanyTransactionsStore;
