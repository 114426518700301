import React from "react";
import { observer } from "mobx-react";
import { NavigationMenuIcon } from "@wingmate/graphics";
import { Flex } from "@wingmate/toolkit";
import { useStore } from "../../../../hooks/useStore";

import "./NavigationMenu.scss";

export const NavigationMenu = observer(() => {
  const { navigationStore } = useStore();

  const openSideNavigation = () => {
    navigationStore.setIsExpanded(true);
  };

  return (
    <Flex className="NavigationMenu" align="center" justify="center">
      <NavigationMenuIcon data-testid="NavigationMenuIcon" onClick={openSideNavigation} />
    </Flex>
  );
});

export default NavigationMenu;
