import { useEffect } from "react";


export const HotjarTracker = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const initializeHotjar = function () {
        const hotjar = window;

        hotjar.hj = hotjar.hj || function (...args) {
          (hotjar.hj.q = hotjar.hj.q || []).push(args);
        };
        hotjar._hjSettings = { hjid: process.env.REACT_APP_HOTJAR_ID, hjsv: 6 };

        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://static.hotjar.com/c/hotjar-${hotjar._hjSettings.hjid}.js?sv=${hotjar._hjSettings.hjsv}`;
        head.appendChild(script);
      };

      initializeHotjar();
    }
  }, []);

  return null;
};

export default HotjarTracker;
