import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { AppContext } from "../context/AppProvider";
import { withRouter } from "../utils/withRouter";

@withRouter
export class FirstLoginRoute extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { currentUser } = this.context;
    const { children } = this.props;

    return <>{currentUser && currentUser.signInCount <= 1 ? children : <Navigate to="/" />}</>;
  }
}
