import React from "react";
import PropTypes from "prop-types";
import { Button, Text } from "wm-ui-toolkit";

export class BannerButton extends React.Component {
  static propTypes = {
    actionCallback: PropTypes.func,
    buttonType: PropTypes.string,
    closeBanner: PropTypes.func,
    text: PropTypes.string,
  };

  onClick = () => {
    const { actionCallback, closeBanner } = this.props;
    if (actionCallback) {
      actionCallback();
    }
    closeBanner();
  };

  render() {
    const { buttonType, text } = this.props;
    return (
      <Button onClick={this.onClick} type={buttonType}>
        <Text type="S">{text}</Text>
      </Button>
    );
  }
}

export default BannerButton;
