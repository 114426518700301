import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { authStorePropTypes } from "../proptypes/stores";

@inject("authStore")
@observer
class AuthenticationLoader extends React.Component {
  static propTypes = {
    authStore: authStorePropTypes,
    children: PropTypes.node,
  };

  render() {
    const { authStore, children } = this.props;
    const { isLoading } = authStore;

    return <div>{isLoading ? <div></div> : <div>{children}</div>}</div>;
  }
}

export { AuthenticationLoader };

export default AuthenticationLoader;
