import { axiosPost } from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const LEAD_ACTION_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/actions`);

export async function postLeadAction(leadId, payload) {
  try {
    await axiosPost(LEAD_ACTION_ENDPOINT(leadId), {
      action: payload,
    });
    return true;
  } catch (err) {
    return false;
  }
}

export default { postLeadAction };
