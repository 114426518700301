import React from "react";
import PropTypes from "prop-types";
import "./TimerBar.scss";

export class TimerBar extends React.Component {
  static propTypes = {
    asyncFunc: PropTypes.func,
    value: PropTypes.number,
  };

  componentDidMount() {
    const { asyncFunc } = this.props;
    asyncFunc();
  }

  render() {
    const { value } = this.props;
    return (
      <div
        className="TimerBar"
        style={{ width: `${value}%` }}
        data-testid={"timer-bar"}
      />
    );
  }
}

export default TimerBar;
