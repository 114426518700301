import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../context/AppProvider";
import { withRouter } from "../../utils/withRouter";

@withRouter
export class PageLayer extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
  };

  render() {
    const { children } = this.props;

    return <div id="pageLayer">{children}</div>;
  }
}

export default PageLayer;
