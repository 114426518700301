import { action, makeObservable, observable } from "mobx";
import { RootStore } from "./RootStore";

export class MassUpdateStore {
  @observable rootStore;

  @observable isModalOpen = false;

  constructor(rootStore: RootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
  }

  @action
  setIsModalOpen(isModalOpen: boolean) {
    this.isModalOpen = isModalOpen;
  }
}

export default MassUpdateStore;
