import { action, computed, makeAutoObservable, observable } from "mobx";
import * as webhookService from "../requests/campaigns/webhookRequests";

export class WebhookRequestsStore {
  @observable webhookRequests = [];

  @observable isLoading = false;

  @observable page = null;

  constructor(adminsStore) {
    makeAutoObservable(this);
    this.adminsStore = adminsStore;
  }

  @computed get campaignId() {
    return this.adminsStore.campaignId;
  }

  @action
  setWebhookRequests(webhookRequests) {
    this.webhookRequests = webhookRequests;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setPage(page) {
    this.page = page;
  }

  @action
  resetPage() {
    this.page = null;
  }

  @action
  async getNextPage() {
    this.setIsLoading(true);
    const payload = {
      page: this.page.next,
    };

    const response = await webhookService.getWebhookRequests(
      this.campaignId,
      payload
    );
    const allWebhookRequests = this.webhookRequests.concat(response.data);

    this.setWebhookRequests(allWebhookRequests);
    this.setPage(response.meta.page);
    this.setIsLoading(false);
  }

  @action
  async retrieveWebhookRequestsAsync() {
    this.setIsLoading(true);

    const response = await webhookService.getWebhookRequests(this.campaignId);
    this.setWebhookRequests(response.data);
    this.setPage(response.meta.page);

    this.setIsLoading(false);
  }
}

export default WebhookRequestsStore;
