import { endpoint } from "../../utils/endpoint";
import {
  axiosGet,
} from "../axiosRequests";

const USER_CAMPAIGNS_ENDPOINT = (userId: string) => endpoint(`app/v1.0/users/${userId}/campaigns`);

export const getCampaigns = async (
  userId: string,
) => {
  const response = await axiosGet(USER_CAMPAIGNS_ENDPOINT(userId));

  return response.data;
};

export default {
  getCampaigns,
};
