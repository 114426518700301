import { action, computed, makeAutoObservable, observable } from "mobx";
import * as campaignsService from "../requests/campaigns";

export class TeamFeedStore {
  @observable activities = [];

  @observable isLoading = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @computed get campaignId() {
    return this.rootStore.context.campaign.id;
  }

  @action
  setActivities(activities) {
    this.activities = activities;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  async retrieveTeamFeed() {
    this.setIsLoading(true);

    const response = await campaignsService.getCampaignTeamFeed(
      this.campaignId
    );

    this.setActivities(response.data);

    this.setIsLoading(false);
  }
}

export default TeamFeedStore;
