import moment from "moment";
import { action, computed, makeObservable, observable } from "mobx";
import { isNullOrEmpty } from "../utils/commons";
import { isWithinDateRange } from "../utils/dateTime";
import { EMAIL_REGEX } from "../utils/mentions";
import * as activitiesService from "../requests/leads/activities";
import * as commentsService from "../requests/leads/comments";
import * as remindersService from "../requests/leads/reminders";

const COMMENT = "Comment";
const REMINDER = "Reminder";
const LEAD = "Lead";
export const ALL_FILTER = "All";
export const UPDATES_FILTER = "Updates";
export const REMINDERS_FILTER = "Reminders";
export const ACTIONS_FILTER = "Actions";

export class ActivityStore {
  leadDetailsStore;

  @observable activities = [];

  @observable activeFilter;

  @observable activityFilters = {};

  @observable isLoading = false;

  @observable order = "created_at_desc";

  constructor(leadDetailsStore) {
    makeObservable(this);
    this.leadDetailsStore = leadDetailsStore;
    this.activeFilter = "All";
  }

  @computed get comments() {
    return this.activities.filter(
      (activity) => activity.attributes.itemType === "Comment"
    );
  }

  @computed get emailConversations() {
    return this.activities.filter(
      (activity) => activity.attributes.itemType === "Email::Conversation"
    );
  }

  @computed get metaComments() {
    return this.activities.filter(
      (activity) => activity.attributes.itemType === "Lead"
    );
  }

  @computed get actionActivities() {
    return this.activities.filter(
      (activity) => activity.attributes.itemType === "Action"
    );
  }

  @computed get flags() {
    return this.activities.filter(
      (activity) => activity.attributes.itemType === "Pipeline::Flag"
    );
  }

  @computed get merges() {
    return this.activities.filter(
      (activity) => activity.attributes.itemType === "Merge"
    );
  }

  @computed get tracks() {
    return this.activities.filter(
      (activity) => activity.attributes.itemType === "Pipeline::Track"
    );
  }

  @computed get userTransactions() {
    return this.activities.filter(
      (activity) => activity.attributes.itemType === "UserTransaction"
    );
  }

  @computed get statusUpdates() {
    return this.activities.filter(
      (activity) => activity.attributes.itemType === "StatusUpdate"
    );
  }

  @computed get leadLinks() {
    return this.activities.filter(
      (activity) => activity.attributes.itemType === "LeadLink"
    );
  }

  @computed get updates() {
    return this.sortActivities([...this.comments, ...this.emailConversations, ...this.metaComments]);
  }

  @computed get actions() {
    return this.activities.filter((activity) => {
      const { attributes } = activity;
      const { itemType } = attributes;

      return ![COMMENT, LEAD, REMINDER].includes(itemType);
    });
  }

  @computed get reminders() {
    return this.activities.filter(
      (activity) => activity.attributes.itemType === "Reminder"
    );
  }

  @computed get activitiesSorted() {
    return this.sortActivities([...this.activities]);
  }

  @computed get activitiesShown() {
    switch (this.activeFilter) {
      case ALL_FILTER:
        return this.activitiesSorted;
      case UPDATES_FILTER:
        return this.updates;
      case REMINDERS_FILTER:
        return this.reminders;
      case ACTIONS_FILTER:
        return this.actions;
      default:
        return [];
    }
  }

  @computed get storageKeyPrefix() {
    const { rootStore } = this.leadDetailsStore;
    const { context } = rootStore;

    return `ActivityStore/u${context.currentUser.id}/c${context.campaign.id}`;
  }

  sortActivities(activities) {
    return [...activities].sort((a, b) => {
      const aAttributes = a.attributes.item?.attributes;
      const bAttributes = b.attributes.item?.attributes;

      if (!aAttributes || !bAttributes) return bAttributes ? 1 : -1;

      const aDateValue = a.attributes.itemType === "Email::Conversation" ? aAttributes.updatedAt : aAttributes.createdAt;
      const bDateValue = b.attributes.itemType === "Email::Conversation" ? bAttributes.updatedAt : bAttributes.createdAt;

      return new Date(bDateValue) - new Date(aDateValue);
    });
  }

  checkActivityIsWithinDateRange(activity, filterKeyValueArray) {
    return filterKeyValueArray.some((dateRange) => {
      let startDate, endDate;
      switch (dateRange) {
        case "day":
          startDate = moment().startOf("day");
          endDate = moment().endOf("day");
          break;
        case "week":
          startDate = moment().startOf("week");
          endDate = moment().endOf("week");
          break;
        case "month":
          startDate = moment().startOf("month");
          endDate = moment().endOf("month");
          break;
        case "lastMonth":
          startDate = moment().subtract(1, "month").startOf("month");
          endDate = moment().subtract(1, "month").endOf("month");
          break;
        default:
          break;
      }

      return isWithinDateRange(
        activity.attributes.item.attributes.createdAt,
        startDate,
        endDate
      );
    });
  }

  checkActivityIsWithinCustomDateRange(activity, dateRangeFilters) {
    const dateStartFilter = dateRangeFilters.find(
      (dateKey) => dateKey === "date_start"
    );
    const dateEndFilter = dateRangeFilters.find(
      (dateKey) => dateKey === "date_end"
    );
    let startDate;
    let endDate;

    if (dateStartFilter) {
      startDate = this.activityFilters[dateStartFilter];
    }

    if (dateEndFilter) {
      endDate = this.activityFilters[dateEndFilter];
    }

    return isWithinDateRange(
      activity.attributes.item.attributes.createdAt,
      startDate,
      endDate
    );
  }

  contactIsPresentInText(filterKeyValueArray, text) {
    const { contactsStore } = this.leadDetailsStore;

    const wordsInText = new Set(text.split(EMAIL_REGEX));

    const hasContactInText = filterKeyValueArray.some((contactId) => {
      const contact = contactsStore.getContact(contactId);
      return contact && wordsInText.has(contact.attributes.email);
    });

    return hasContactInText;
  }

  matchesFilter(activity, filterKeyValueArray, field) {
    const { attributes } = activity;
    const { item } = attributes;
    const { attributes: itemAttributes } = item;
    const { reminderType, status, text, type, user } = itemAttributes;

    let matchesFilter = false;

    if (field === "updateTypes") {
      matchesFilter = filterKeyValueArray.includes(type || "");
    } else if (field === "reminderTypes") {
      matchesFilter =
        reminderType && filterKeyValueArray.includes(reminderType.id);
    } else if (field === "reminderStatuses") {
      matchesFilter = filterKeyValueArray.includes(status || "");
    } else if (field === "users") {
      matchesFilter = user && filterKeyValueArray.includes(user.id.toString());
    } else if (field === "dates") {
      const isWithinDateRange = this.checkActivityIsWithinDateRange(
        activity,
        filterKeyValueArray
      );

      matchesFilter = isWithinDateRange;
    } else if (field === "contacts") {
      matchesFilter =
        text && this.contactIsPresentInText(filterKeyValueArray, text);
    }

    return matchesFilter;
  }

  @computed get filterKeys() {
    return Object.keys(this.activityFilters);
  }

  @computed get isActivityFiltersEmpty() {
    return (
      this.filterKeys.filter(
        (filter) =>
          this.activityFilters[filter] &&
          !isNullOrEmpty(this.activityFilters[filter].toString())
      ).length === 0
    );
  }

  @computed get filteredActivities() {
    const filters = this.filterKeys;

    if (!filters.length) return this.activitiesShown;

    return this.activitiesShown.filter((activity) => {
      const dateRangeFilterKeys = filters.filter((filter) =>
        ["date_start", "date_end"].includes(filter)
      );
      const nonDateRangeFilterKeys = filters.filter(
        (filter) => !["date_start", "date_end"].includes(filter)
      );

      const activityIsWithinDateRange =
        this.checkActivityIsWithinCustomDateRange(
          activity,
          dateRangeFilterKeys
        );

      const activityIsWithinNonDateRange = nonDateRangeFilterKeys.every(
        (filter) => {
          const filterKeyValueArray = this.activityFilters[filter];
          return filterKeyValueArray.length
            ? this.matchesFilter(activity, filterKeyValueArray, filter)
            : true;
        }
      );

      return activityIsWithinNonDateRange && activityIsWithinDateRange;
    });
  }

  @computed get updateCount() {
    return this.updates.length;
  }

  @computed get reminderCount() {
    return this.reminders.length;
  }

  @computed get actionCount() {
    return this.actions.length;
  }

  @action
  setActivities(activities) {
    this.activities = activities;
  }

  @action
  setActiveFilter(newFilter) {
    this.activeFilter = newFilter;
  }

  @action
  clearAdditionalActivityFilters() {
    const activityFiltersCopy = JSON.parse(
      JSON.stringify(this.activityFilters)
    );
    delete activityFiltersCopy.updateTypes;
    delete activityFiltersCopy.reminderTypes;
    delete activityFiltersCopy.reminderStatuses;
    this.setActivityFilters(activityFiltersCopy);
  }

  @action
  setActivityFilters(activityFilters) {
    this.activityFilters = activityFilters;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  async createCommentAsync(payload) {
    const { leadId, rootStore } = this.leadDetailsStore;

    const payloadWithLocation = {
      ...payload,
      ...rootStore.location,
    };

    this.setIsLoading(true);
    try {
      await commentsService.postLeadComment(leadId, payloadWithLocation);
      await this.retrieveActivitiesForLeadAsync();
      this.setIsLoading(false);
    } catch (err) {
      this.setIsLoading(false);
      throw err;
    }
  }

  @action
  async updateCommentAsync(commentId, payload) {
    const { leadId } = this.leadDetailsStore;
    this.setIsLoading(true);
    await commentsService.putLeadComment(leadId, commentId, payload);
    await this.retrieveActivitiesForLeadAsync();
    this.setIsLoading(false);
  }

  async deleteCommentAsync(commentId) {
    const { leadId } = this.leadDetailsStore;
    this.setIsLoading(true);
    await commentsService.deleteLeadComment(leadId, commentId);
    await this.retrieveActivitiesForLeadAsync();
    this.setIsLoading(false);
  }

  async deleteReminderAsync(reminderId) {
    this.setIsLoading(true);
    await remindersService.deleteReminder(reminderId);
    await this.retrieveActivitiesForLeadAsync();
    this.setIsLoading(false);
  }

  @action
  async updateReminderAsync(reminderId, payload) {
    this.setIsLoading(true);
    await remindersService.putReminder(reminderId, payload);
    await this.retrieveActivitiesForLeadAsync();
    this.setIsLoading(false);
  }

  @action
  async pushReminderAsync(reminderId, leadId, payload) {
    this.setIsLoading(true);
    await remindersService.putReminder(reminderId, {
      mark_cancelled: true,
    });
    await remindersService.postReminder(leadId, payload);
    await this.retrieveActivitiesForLeadAsync();
    this.setIsLoading(false);
  }

  @action
  async retrieveActivitiesForLeadAsync() {
    const { leadId } = this.leadDetailsStore;
    this.setIsLoading(true);
    const response = await activitiesService.getActivities(leadId, this.order);
    this.setActivities(response);
    this.setIsLoading(false);
  }
}

export default ActivityStore;
