import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withRouter } from "../../../../utils/withRouter";
import { withTranslations } from "../../../../utils/withTranslations";
import { adminStoreProptypes } from "../../../../proptypes/stores";
import { formatDateByMonthDayYear } from "../../../../utils/dateTime";

@withTranslations
@withRouter
@inject("adminStore")
@observer
export class EnterprisePayoutBreadcrumbs extends React.Component {
  static propTypes = {
    adminStore: adminStoreProptypes,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    t: PropTypes.func,
  };

  async componentDidMount() {
    const { adminStore, t } = this.props;
    const { companyTransactionsStore } = adminStore;
    const { transactions } = companyTransactionsStore;
    if (transactions.length === 0) {
      try {
        await companyTransactionsStore.getCompanyTransactionsAsync();
      } catch (err) {
        adminStore.rootStore.bannerStore.addBanner(
          "red",
          t("enterprise.errors.load"),
          t("enterprise.errors.loadMessage")
        );
      }
    }
  }

  render() {
    const { adminStore, id } = this.props;
    const { companyTransactionsStore } = adminStore;
    const { isLoading } = companyTransactionsStore;

    let transaction;
    if (!isLoading) {
      transaction = companyTransactionsStore.getTransaction(id).attributes;
    }

    return transaction ? (
      <span>{formatDateByMonthDayYear(transaction.updatedAt)}</span>
    ) : (
      <></>
    );
  }
}

export default EnterprisePayoutBreadcrumbs;
