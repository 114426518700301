import { action, get, makeObservable, observable } from "mobx";
import * as leadsService from "../requests/leads";

export class MergeLeadsStore {
  rootStore;

  @observable leads = [];

  @observable isLoading = true;

  constructor(leadDetailsStore) {
    makeObservable(this);

    this.leadDetailsStore = leadDetailsStore;
  }

  @action
  setLeads(leads) {
    this.leads = leads;
  }

  @action
  async retrieveLeadsAsync(filters = {}) {
    this.setIsLoading(true);
    try {
      const response = await leadsService.getLeads(filters);

      const { data } = response;

      this.setLeads(data);
      this.setIsLoading(false);
    } catch (err) {
      this.setIsLoading(false);
    }
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  findLead(leadId) {
    const lead = get(this.leadLinks, leadId);

    return lead;
  }

  @action
  async mergeLeads(leadId, payload) {
    this.setIsLoading(true);
    try {
      await leadsService.mergeLeads(leadId, payload);
      this.setIsLoading(false);
    } catch (err) {
      this.setIsLoading(false);
    }
  }
}

export default MergeLeadsStore;
