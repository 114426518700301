import React from "react";
import PropTypes from "prop-types";
import { antdTheme, muiTheme } from "@wingmate/toolkit";
import { ConfigProvider } from "antd";
import { ThemeProvider } from "@mui/material";

const StyleProvider = ({ children }) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <ConfigProvider theme={antdTheme}>{children}</ConfigProvider>
    </ThemeProvider>
  );
};

StyleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StyleProvider;
