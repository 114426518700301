import { action, makeObservable, observable } from "mobx";
import { unique } from "../utils/arrays";
import * as duplicatesService from "../requests/duplicates/duplicates";

export class DuplicatesStore {
  @observable duplicates = new Map();
  @observable isLoading = false;

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  getDuplicatesForLead(leadId) {
    return this.duplicates.get(leadId) || [];
  }

  @action
  modifyDuplicatesInCache(leadId, duplicateIds) {
    const existingDuplicates = this.duplicates.get(leadId) || [];
    const updatedDuplicates = unique([...existingDuplicates, ...duplicateIds]);
    this.duplicates.set(leadId, updatedDuplicates);
  }

  @action
  setDuplicates(duplicates) {
    this.duplicates = new Map(duplicates);
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @action
  async retrieveDuplicatesForLeadAsync(leadId) {
    const { context } = this.rootStore;

    this.setIsLoading(true);

    try {
      const { ids: duplicateIds } = await duplicatesService.getDuplicates(
        context.currentUser.authToken,
        leadId
      );

      this.modifyDuplicatesInCache(leadId, duplicateIds);

      return duplicateIds;
    } finally {
      this.setIsLoading(false);
    }
  }
}

export default DuplicatesStore;
