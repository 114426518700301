import { endpoint } from "../../utils/endpoint";

const GET_ACTIVITIES_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/feed`);

import * as axiosService from "../axiosRequests";

export async function getActivities(leadId, order) {
  const response = await axiosService.axiosGet(
    GET_ACTIVITIES_ENDPOINT(leadId),
    {
      order,
    }
  );

  return response.data.data;
}

export default {
  getActivities,
};
