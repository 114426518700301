import { action, makeObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";

export class BannerStore {
  @observable banners;

  constructor() {
    makeObservable(this);
    this.banners = [];
  }

  @action
  setBanners(banners) {
    this.banners = banners;
  }

  @action
  addBanner(
    color,
    title,
    body,
    actions = [],
    timer = true,
    additionalOptions = {}
  ) {
    const id = uuid();
    if (color === "red") {
      timer = false;
    }
    this.banners.push({
      id,
      color,
      title,
      body,
      actions,
      timer,
      ...additionalOptions,
    });
  }

  @action
  deleteBanner(bannerId) {
    this.banners = this.banners.filter((banner) => banner.id !== bannerId);
  }
}

export default BannerStore;
