import { action, makeAutoObservable, observable } from "mobx";
import * as campaignPriceSheetsService from "../requests/campaigns/priceSheets";
import * as priceSheetsService from "../requests/priceSheets";

export class PriceSheetsStore {
  @observable priceSheets = [];

  @observable isLoading;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.isLoading = true;
  }

  @action
  setPriceSheets(priceSheets) {
    this.priceSheets = priceSheets;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  findPriceSheet(priceSheetId) {
    return this.priceSheets.find(
      (priceSheet) => priceSheet.id.toString() === priceSheetId.toString()
    );
  }

  @action
  updatePriceSheetInCache(priceSheetId, newPriceSheet) {
    const priceSheets = [...this.priceSheets];

    const priceSheetIndex = priceSheets.findIndex(
      (priceSheet) => priceSheet.id === priceSheetId
    );

    if (priceSheetIndex !== -1) {
      priceSheets[priceSheetIndex] = newPriceSheet;

      this.setPriceSheets(priceSheets);
    }
  }

  @action
  async updatePriceSheetAsync(priceSheetId, payload) {
    this.setIsLoading(true);

    const response = await priceSheetsService.putPriceSheet(
      priceSheetId,
      payload
    );

    this.updatePriceSheetInCache(priceSheetId, response.data);

    await this.rootStore.rootStore.authStore.retrieveCurrentUserContext();
    this.setIsLoading(false);
  }

  @action
  async retrievePriceSheetsForCampaignAsync(campaignId) {
    this.setIsLoading(true);
    const response = await campaignPriceSheetsService.getPriceSheets(
      campaignId
    );

    this.setPriceSheets(response.data);
    this.setIsLoading(false);

    return response;
  }

  @action
  async createPriceSheet(campaignId, payload) {
    const priceSheet = await campaignPriceSheetsService.postPriceSheet(
      campaignId,
      payload
    );

    const newPriceSheets = [...this.priceSheets];
    newPriceSheets.push(priceSheet);

    this.setPriceSheets(newPriceSheets);
  }
}
