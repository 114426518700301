import React, { createContext } from "react";
import PropTypes from "prop-types";
import { Provider } from "mobx-react";
import { inject, observer } from "mobx-react";
import { rootStorePropTypes } from "../proptypes/stores";
import { LeadDetailsStore } from "../stores/LeadDetailsStore";

export const LeadDetailsContext = createContext();

@inject("rootStore")
@observer
export class LeadDetailProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    lead: PropTypes.object,
    leadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rootStore: rootStorePropTypes,
  };

  constructor(props, context) {
    super(props, context);

    this.leadDetailsStore = new LeadDetailsStore(
      props.rootStore,
      props.leadId,
      props.lead
    );
  }

  render() {
    const { children } = this.props;

    return (
      <Provider leadDetailsStore={this.leadDetailsStore}>
        <LeadDetailsContext.Provider value={{ leadDetailsStore: this.leadDetailsStore }}>
          {children}
        </LeadDetailsContext.Provider>
      </Provider>
    );
  }
}

export default LeadDetailProvider;
