import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { I18nextProvider } from "react-i18next";
import { rootStorePropTypes } from "../proptypes/stores";
import moment from "moment";
import "moment/min/locales";

@inject("rootStore")
@observer
export class TranslationProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    i18n: PropTypes.object,
    rootStore: rootStorePropTypes,
  };

  componentDidMount() {
    const { i18n, rootStore } = this.props;
    const { context } = rootStore;

    if (context.currentUser) {
      i18n.changeLanguage(context.currentUser.locale);
      moment.locale(context.currentUser.locale);
      document.body.setAttribute("locale", context.currentUser.locale);
    }
  }

  render() {
    const { children, i18n } = this.props;

    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
  }
}

export default TranslationProvider;
