import { action, computed, makeObservable, observable, override } from "mobx";
import { BaseStore } from "./BaseStore";
import { LEAD_TYPE, PURCHASE_TYPE } from "../utils/constants";
import * as approvalRequestsService from "../requests/approvalRequests";
import * as leadApprovalRequestsService from "../requests/leads/approvalRequests";
import * as leadPurchasesService from "../requests/leads/purchases";
import * as leadsService from "../requests/leads";

export class ApprovalRequestsStore extends BaseStore {
  @observable approvalRequests = new Map();

  @observable isLoading = true;

  @observable count = 0;

  @observable page = {};

  @observable hasMoreApprovalRequests = false;

  constructor(rootStore) {
    super();

    makeObservable(this);

    this.rootStore = rootStore;
  }

  @override
  clear() {
    this.approvalRequests.clear();
  }

  filterOpenApprovals(approvalRequests) {
    return approvalRequests.filter(
      (approvalRequest) => approvalRequest.attributes.aasmState === "open"
    );
  }

  filterNonOpenApprovals(approvalRequests) {
    return approvalRequests.filter((approvalRequest) =>
      ["approved", "rejected"].includes(approvalRequest.attributes.aasmState)
    );
  }

  @computed get allApprovals() {
    const allApprovals = [...this.approvalRequests.values()].reduce(
      (approvalsArray, approvals) => {
        return [...approvalsArray, ...approvals];
      },
      []
    );

    allApprovals.sort((a, b) => {
      const aDate = new Date(a.attributes.updatedAt);
      const bDate = new Date(b.attributes.updatedAt);

      return bDate - aDate;
    });

    const openApprovals = this.filterOpenApprovals(allApprovals);

    const nonOpenApprovals = this.filterNonOpenApprovals(allApprovals);

    return [...openApprovals, ...nonOpenApprovals];
  }

  @computed get approvalsOpenCount() {
    return this.allApprovals.filter(
      (approvalRequest) => approvalRequest.attributes.aasmState === "open"
    ).length;
  }

  getApprovalsForLead(leadId) {
    return this.approvalRequests.get(leadId.toString()) || [];
  }

  leadApprovalsOpenCount(leadId) {
    return this.filterOpenApprovals(this.getApprovalsForLead(leadId)).length;
  }

  leadApprovalsRejectedCount(leadId) {
    return this.getApprovalsForLead(leadId).filter(
      (approvalRequest) => approvalRequest.attributes.aasmState === "rejected"
    ).length;
  }

  findLeadApprovalRequestForItem(leadId, itemId) {
    return this.getApprovalsForLead(leadId).find(
      (approvalRequest) =>
        approvalRequest.attributes.item.id.toString() === itemId.toString()
    );
  }

  findLeadApprovalRequestsForItem(leadId, itemId) {
    return this.getApprovalsForLead(leadId).filter(
      (approvalRequest) =>
        approvalRequest.attributes.item.id.toString() === itemId.toString()
    );
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setCount(count) {
    this.count = count;
  }

  @action
  setPage(page) {
    this.page = page;
  }

  @action
  setHasMoreApprovalRequests(more) {
    this.hasMoreApprovalRequests = more;
  }

  @action
  setLeadApprovalRequests(leadId, approvals) {
    this.approvalRequests.set(leadId.toString(), approvals);
  }

  @action
  modifyApprovalRequestInCache(approvalRequest) {
    const { attributes, id } = approvalRequest;
    const { leadId } = attributes;

    const leadApprovals = this.getApprovalsForLead(leadId).slice();

    const approvalIndex = leadApprovals.findIndex(
      (approval) => approval.id == id
    );

    if (approvalIndex !== -1) {
      leadApprovals[approvalIndex] = approvalRequest;
    } else {
      leadApprovals.push(approvalRequest);
    }

    this.setLeadApprovalRequests(leadId, leadApprovals);
  }

  @action
  addApprovalRequests(approvalRequests) {
    approvalRequests.map((approvalRequest) => {
      this.modifyApprovalRequestInCache(approvalRequest);
    });
  }

  @action
  async retrieveApprovalRequestsForLeadAsync(leadId) {
    this.setIsLoading(true);

    try {
      const response = await leadApprovalRequestsService.getApprovalRequests(
        leadId
      );

      this.setLeadApprovalRequests(leadId, response);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async getApprovalRequestsAsync() {
    this.setIsLoading(true);

    try {
      const { data, meta } =
        await approvalRequestsService.getApprovalRequests();

      const { count, page } = meta;
      this.setCount(count);
      this.setPage(page);
      this.setHasMoreApprovalRequests(page.more);

      this.addApprovalRequests(data);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async getNextPageAsync() {
    if (!this.page.more) {
      return;
    }

    this.setIsLoading(true);
    const approvalRequestsPayload = {
      page: this.page.next,
    };

    try {
      const { data, meta } = await approvalRequestsService.getApprovalRequests(
        approvalRequestsPayload
      );

      const { page } = meta;

      this.setPage(page);
      this.setHasMoreApprovalRequests(page.more);

      this.addApprovalRequests(data);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async sendManagerActionAsync(leadId, itemId, itemType, payload) {
    if (itemType === PURCHASE_TYPE) {
      await leadPurchasesService.putPurchase(leadId, itemId, payload);
    } else if (itemType === LEAD_TYPE) {
      await leadsService.putLead(itemId, { lead: payload });
    }

    await this.getApprovalRequestsAsync();
  }
}
