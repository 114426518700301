import { axiosGet } from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const LEAD_APPROVAL_REQUESTS_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/approval_requests`);

export async function getApprovalRequests(leadId) {
  const response = await axiosGet(LEAD_APPROVAL_REQUESTS_ENDPOINT(leadId));
  return response.data.data;
}
