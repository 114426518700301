import React from "react";
import PropTypes from "prop-types";
import { Provider } from "mobx-react";
import { MassSelectStore } from "../stores/MassSelectStore";

export class MassSelectProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  constructor() {
    super();

    this.massSelectStore = new MassSelectStore();
  }

  render() {
    const { children } = this.props;
    return (
      <Provider massSelectStore={this.massSelectStore}>{children}</Provider>
    );
  }
}

export default MassSelectProvider;
