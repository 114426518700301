import * as axiosRequests from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const CREATE_APP_INSTALL_ENDPOINT = (campaignId) => {
  return endpoint(`app/v1.0/campaigns/${campaignId}/app_installs`);
};

export async function postAppInstall(campaignId, payload) {
  const response = await axiosRequests.axiosPost(
    CREATE_APP_INSTALL_ENDPOINT(campaignId),
    payload
  );

  return response.data;
}

export default { postAppInstall };
