import React from "react";
import PropTypes from "prop-types";
import { Provider, inject, observer } from "mobx-react";
import { AuthStore } from "../stores/AuthStore";
import { bannerStorePropTypes } from "../proptypes/stores";
import { AuthenticationLoader } from "../layouts/AuthenticationLoader";

@inject("bannerStore")
@observer
class AuthenticationProvider extends React.Component {
  static propTypes = {
    bannerStore: bannerStorePropTypes,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.authStore = new AuthStore(props.bannerStore);
  }

  render() {
    const { children } = this.props;

    return (
      <Provider authStore={this.authStore}>
        <AuthenticationLoader>{children}</AuthenticationLoader>
      </Provider>
    );
  }
}

export { AuthenticationProvider };

export default AuthenticationProvider;
