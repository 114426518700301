import axios from "axios";
import { endpoint } from "../../utils/endpoint";

const CAMPAIGN_REMINDERS_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/reminders`);

export const CAMPAIGN_REMINDERS_CSV_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/reminders.csv`);

export async function getReminders(campaignId, params = {}) {
  const response = await axios.get(CAMPAIGN_REMINDERS_ENDPOINT(campaignId), {
    params,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return response;
}

export default {
  getReminders,
};
