import { action, computed, makeAutoObservable, observable } from "mobx";
import * as leadFormsService from "../requests/campaigns/leadForms";

export class LeadFormsStore {
  @observable leadForms = [];
  @observable isLoading = false;

  constructor(adminStore) {
    makeAutoObservable(this);
    this.adminStore = adminStore;
    this.campaignId = adminStore.campaignId;
  }

  @computed
  get activeLeadForms() {
    return this.filterActive(this.leadForms);
  }

  @computed
  get inactiveLeadForms() {
    return this.filterInactive(this.leadForms);
  }

  filterActive(leadForms) {
    return leadForms.filter((form) => form.attributes.active === true);
  }

  filterInactive(leadForms) {
    return leadForms.filter((form) => form.attributes.active === false);
  }

  @action
  setLeadForms(leadForms) {
    this.leadForms = leadForms;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @computed get isEmpty() {
    return this.leadForms.length === 0;
  }

  @action
  modifyLeadFormInCache(leadFormId, newLeadForm) {
    const leadForms = [...this.leadForms];
    const leadFormIndex = leadForms.findIndex(
      (leadForm) => leadForm.attributes.id === parseInt(leadFormId)
    );

    if (leadFormIndex !== -1) {
      leadForms[leadFormIndex] = newLeadForm;

      this.setLeadForms(leadForms);
    }
  }

  @action
  findLeadForm(leadFormId) {
    const form = this.leadForms.find(
      (leadForm) => leadForm.attributes.id === parseInt(leadFormId)
    );

    return form;
  }

  @action
  async getLeadFormsAsync() {
    this.setIsLoading(true);
    const response = await leadFormsService.getLeadForms(this.campaignId);

    const leadForms = response.data;
    if (leadForms) {
      leadForms.sort((a, b) => {
        return a.attributes.title
          .toLowerCase()
          .localeCompare(b.attributes.title.toLowerCase());
      });

      const activeLeadForms = this.filterActive(leadForms);

      const inactiveLeadForms = this.filterInactive(leadForms);

      this.setLeadForms([...activeLeadForms, ...inactiveLeadForms]);
    }

    this.setIsLoading(false);

    return leadForms;
  }

  @action
  async deleteLeadFormAsync(leadFormId) {
    this.setIsLoading(true);

    await leadFormsService.deleteLeadForm(this.campaignId, leadFormId);

    const leadForms = this.leadForms.filter(
      (leadForm) => leadForm.id.toString() === leadFormId.toString()
    );

    this.setLeadForms(leadForms);

    this.setIsLoading(false);

    return leadForms;
  }

  @action
  async updateLeadFormAsync(leadFormId, payload) {
    this.setIsLoading(true);
    const leadForm = await leadFormsService.updateLeadForm(
      this.campaignId,
      leadFormId,
      payload
    );

    this.modifyLeadFormInCache(leadFormId, leadForm);

    this.setIsLoading(false);

    return leadForm;
  }

  @action
  async postLeadFormAsync() {
    this.setIsLoading(true);

    const leadFormNumber = this.leadForms.length + 1;

    const payload = { lead_form: { title: `New Lead Form ${leadFormNumber}` } };

    const leadForm = await leadFormsService.postLeadForm(
      this.campaignId,
      payload
    );

    this.setLeadForms([...this.leadForms, leadForm]);

    this.setIsLoading(false);

    return leadForm;
  }
}
