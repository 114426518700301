import React from "react";
import sanitizeHtml from "sanitize-html";
import HtmlReactParser from "html-react-parser";
import { Mention } from "../common/Mentions/Mention/Mention";

export const EMAIL_REGEX = /([+\w0-9._-]+@[\w0-9._-]+\.[\w0-9_-]+)/;
export const MENTION_REGEX = /(%{\d+}%)/;

export function checkIfMention(text) {
  return text.slice(0, 2).concat(text.slice(-2)) === "%{}%";
}

export function checkIfHasMention(text) {
  return MENTION_REGEX.test(text);
}

export function renderMention(user = {}, key, isContact = false) {
  return <Mention key={key} user={user} isContact={isContact} />;
}

export function renderText(text, key) {
  return <span key={key}>{HtmlReactParser(sanitizeHtml(text))}</span>;
}

export function mentionAsHtml(text, userMap = {}) {
  if (text === null) {
    return "";
  }

  const textAsArray = text
    .split(MENTION_REGEX)
    .filter((matchedText) => matchedText);
  const mentionHtmlAsArray = [];

  let renderingCounter = 0;
  textAsArray.forEach((matchedText) => {
    if (checkIfMention(matchedText)) {
      const id = matchedText.slice(2, -2);
      const user = userMap[id];

      if (user) {
        mentionHtmlAsArray.push(renderMention(user, renderingCounter));
      } else {
        mentionHtmlAsArray.push(renderText(matchedText, renderingCounter));
      }
    } else {
      mentionHtmlAsArray.push(renderText(matchedText, renderingCounter));
    }

    renderingCounter += 1;
  });

  return mentionHtmlAsArray;
}

export default mentionAsHtml;
