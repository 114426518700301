import { endpoint } from "../utils/endpoint";
import * as axiosService from "./axiosRequests";

const USER_NOTIFICATIONS_ENDPOINT = (userId) =>
  endpoint(`app/v1.0/users/${userId}/notifications.json`);

const USER_NOTIFICATIONS_VIEW_ENDPOINT = (userId) =>
  endpoint(`app/v1.0/users/${userId}/notifications/view`);

export async function getUserNotifications(userId, payload) {
  try {
    const response = await axiosService.axiosGet(
      USER_NOTIFICATIONS_ENDPOINT(userId),
      payload
    );

    return response.data;
  } catch (err) {
    return [];
  }
}

export async function postViewUserNotifications(userId) {
  try {
    await axiosService.axiosPost(USER_NOTIFICATIONS_VIEW_ENDPOINT(userId));
  } catch (err) {
    return;
  }
}

export default {
  getUserNotifications,
  postViewUserNotifications,
};
