import PropTypes from "prop-types";
import { leadBasePropTypes } from "./leadBase";
import { campaignPropTypes, userPropTypes } from "./user";
import { leadLink } from "./leadLink";
import { leadDetailsPropTypes } from "./leadDetails";

export const adminStoreProptypes = PropTypes.shape({
  campaign: campaignPropTypes,
  isLoading: PropTypes.bool,
  isInitializing: PropTypes.bool,
  isSaving: PropTypes.bool,
  attachments: PropTypes.array,
});

const listLeadsStorePropTypes = PropTypes.shape({
  leads: PropTypes.arrayOf(leadBasePropTypes),
  amount: PropTypes.number,
  count: PropTypes.number,
  setIsLoading: PropTypes.func,
  setLeads: PropTypes.func,
  setCount: PropTypes.func,
  setAmount: PropTypes.func,
  updateLead: PropTypes.func,
  retrieveLeadsAsync: PropTypes.func,
});

const filtersStore = {
  filters: PropTypes.object,
  setFilter: PropTypes.func,
  getFilters: PropTypes.func,
  clearFilters: PropTypes.func,
};

const funnelStore = {
  funnel: PropTypes.shape({
    leads: PropTypes.arrayOf(leadBasePropTypes),
    amount: PropTypes.number,
    count: PropTypes.number,
    page: PropTypes.number,
  }),
  setIsloading: PropTypes.func,
  getFunnelColumnNextPage: PropTypes.func,
  retrieveLeadsAsync: PropTypes.func,
};

const usersStore = {
  users: PropTypes.arrayOf(userPropTypes),
};

const viewStore = {
  view: PropTypes.shape({
    id: PropTypes.string,
    icon: PropTypes.object,
    name: PropTypes.string,
  }),
  setView: PropTypes.func,
  viewOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.object,
      name: PropTypes.string,
    })
  ),
};

export const authStorePropTypes = PropTypes.shape({
  isAuthenticated: PropTypes.bool,
  loginUser: PropTypes.func,
  logoutUser: PropTypes.func,
});

export const contractStorePropTypes = PropTypes.shape({
  contracts: PropTypes.array,
  getUserContractsAsync: PropTypes.func,
  updateContract: PropTypes.func,
});

export const leadLinksStorePropTypes = {
  leadLinks: PropTypes.arrayOf(leadLink),
  addLeadLinkAsync: PropTypes.func,
  activeChildLinks: PropTypes.array,
  activeParentLinks: PropTypes.array,
};

export const bannerStorePropTypes = PropTypes.shape({
  banners: PropTypes.arrayOf(PropTypes.object),
  deleteBanner: PropTypes.func,
  addBanner: PropTypes.func,
});

export const leadDetailsStorePropTypes = PropTypes.shape({
  lead: leadDetailsPropTypes,
  bannerStore: bannerStorePropTypes,
  leadLinksStore: PropTypes.shape(leadLinksStorePropTypes),
  usersStore: PropTypes.shape(usersStore),
});

export const massSelectStorePropTypes = PropTypes.shape({
  selected: PropTypes.arrayOf(PropTypes.any),
  selectMode: PropTypes.bool,
});

export const storePropTypes = PropTypes.shape({
  bannerStore: bannerStorePropTypes,
  listLeadsStore: PropTypes.shape(listLeadsStorePropTypes),
  filtersStore: PropTypes.shape(filtersStore),
  funnelStore: PropTypes.shape(funnelStore),
  usersStore: PropTypes.shape(usersStore),
  viewStore: PropTypes.shape(viewStore),
  leadLinksStore: PropTypes.shape(leadLink),
});

export const rootStorePropTypes = PropTypes.shape({
  authStore: authStorePropTypes,
  listLeadsStore: listLeadsStorePropTypes,
  funnelStore: PropTypes.shape(funnelStore),
  filtersStore: PropTypes.shape(filtersStore),
  viewStore: PropTypes.shape(viewStore),
});

export const leadstorePropTypes = PropTypes.shape({
  leadsStore: listLeadsStorePropTypes,
});

export default storePropTypes;
