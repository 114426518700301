import { endpoint } from "../utils/endpoint";
import * as axiosService from "./axiosRequests";

const LEADS_CHASED_METRIC_ENDPOINT = () =>
  endpoint(`app/v1.0/leads_chased_metrics.json`);

export async function getLeadsChasedMetrics() {
  try {
    const response = await axiosService.axiosGet(
      LEADS_CHASED_METRIC_ENDPOINT()
    );

    return response.data.data;
  } catch (err) {
    return {};
  }
}

export default {
  getLeadsChasedMetrics,
};
