import { action, makeAutoObservable } from "mobx";
import * as territoriesService from "../requests/maps/territories";

export class TerritoriesStore {
  isLoading = false;
  territories = [];
  leadTerritories = [];
  pinTerritories = [];

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  @action
  setTerritories = (territories) => {
    this.territories = territories;
  };

  @action
  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  @action
  setLeadTerritories = (leadTerritories) => {
    this.leadTerritories = leadTerritories;
  };

  @action
  setPinTerritories = (pinTerritories) => {
    this.pinTerritories = pinTerritories;
  };

  @action
  modifyTerritoryInCache(territoryId, newTerritory) {
    const territories = [...this.territories];

    const territoryIndex = territories.findIndex(
      (territory) => territory.territoryId === territoryId
    );

    if (territoryIndex !== -1) {
      territories[territoryIndex] = newTerritory;
    } else {
      territories.push(newTerritory);
    }

    this.setTerritories(territories);
  }

  @action
  async createTerritoryAsync(campaignId, user, territory) {
    const oldTerritoryId = territory.territoryId;
    const { territoryId } = await territoriesService.postTerritory(
      campaignId,
      user,
      territory
    );
    territory.territoryId = territoryId;
    this.modifyTerritoryInCache(territoryId, territory);

    return { oldTerritoryId, territoryId };
  }

  @action
  async retrieveTerritoriesForCampaignAsync(
    campaignId,
    user,
    latitude,
    longitude
  ) {
    this.setIsLoading(true);
    try {
      const { territories } = await territoriesService.getTerritories(
        campaignId,
        user,
        latitude,
        longitude
      );
      this.setTerritories(territories);
    } catch (error) {
      this.setTerritories([]);
    } finally {
      this.setIsLoading(false);
    }
  }

  @action
  async retrievePinsTerritoriesForCampaignAsync(
    campaignId,
    user,
    latitude,
    longitude
  ) {
    try {
      const { territories } = await territoriesService.getTerritories(
        campaignId,
        user,
        latitude,
        longitude
      );
      this.setPinTerritories(territories);
    } catch (error) {
      this.setPinTerritories([]);
    }
  }

  @action
  async retrieveLeadTerritoriesForLeadAsync(campaignId, leadId, user) {
    try {
      const { territories } = await territoriesService.getLeadsTerritories(
        campaignId,
        leadId,
        user
      );
      this.setLeadTerritories(territories);
    } catch (error) {
      this.setLeadTerritories([]);
    }
  }

  @action
  async updateTerritoryAsync(campaignId, user, territory) {
    const { territoryId } = territory;
    await territoriesService.updateTerritory(campaignId, user, territory);
    this.modifyTerritoryInCache(territoryId, territory);
  }

  @action
  async deleteTerritoryAsync(campaignId, user, territoryId) {
    await territoriesService.deleteTerritory(campaignId, user, { territoryId });

    const territories = [...this.territories];

    const newTerritories = territories.filter(
      (territory) => territory.territoryId != territoryId
    );

    this.setTerritories(newTerritories);
  }
}

export default TerritoriesStore;
