import React from "react";
import { TranslationProvider } from "../providers/TranslationProvider";
import { Outlet } from "react-router-dom";
import i18next from "../pages/LeadForms/translations/i18n";

export class LeadFormsRoute extends React.Component {
  render() {
    return (
      <TranslationProvider i18n={i18next}>
        <Outlet />
      </TranslationProvider>
    );
  }
}

export default LeadFormsRoute;
