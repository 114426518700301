import { useTranslation } from "react-i18next";
import { IUserAttributes } from "../types/IUser";

export const getUsername = (user?: Pick<IUserAttributes, "fullName">) => {
  let username;

  if (user) username = user.fullName;

  return username;
};

export const NameOfUser = (user?: Pick<IUserAttributes, "fullName" | "disabled">) => {
  const { t } = useTranslation(["common"]);

  const username = getUsername(user);

  if (user) {
    if (user.disabled) {
      return t("common:user.disabled", { name: username });
    }

    return username;
  }

  return t("common:user.deletedUser");
};
