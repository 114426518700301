import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button, Flex, Image, Text,
} from "@wingmate/toolkit";
import { WmLogoIcon } from "wm-graphics";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks/useStore";
import { AppContext } from "../../../context/AppProvider";

import "./HomeButton.scss";

export const HomeButton: React.FC = observer(() => {
  const { campaign, currentUser } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const rootStore = useStore();

  const { navigationStore, viewStore } = rootStore;

  const onClick = () => {
    const { pathname } = location;

    if (currentUser.type === "Worker") {
      navigate("/frontline");
    } else {
      if (pathname === "/dashboard") {
        viewStore.setView("funnel");
      }

      navigate("/dashboard");
    }
  };

  const renderLogo = () => {
    const { logoUrl } = campaign;

    return (
      <Flex className="HomeButton__logoContainer" align="center" justify="center">
        {
          logoUrl ? (
            <Image
              className="logoContainer__logo"
              src={logoUrl}
              alt="Wingmate"
            />
          ) : (
            <WmLogoIcon className="logoContainer__logo WingmateLogo" />
          )
        }
      </Flex>
    );
  };

  const renderCompanyName = () => {
    const { isExpanded, isPinned } = navigationStore;

    const showCompanyName = isExpanded || isPinned;

    if (!showCompanyName) {
      return null;
    }

    return (
      <Text type="H6" weight="Semi">{campaign.name}</Text>
    );
  };

  return (
    <Button data-testid="HomeButton" onClick={onClick} className="HomeButton" type="text" justify="left">
      <Flex className="HomeButton__logoName" gap={8} justify="flex-start" align="center">
        {renderLogo()}
        {renderCompanyName()}
      </Flex>
    </Button>
  );
});

export default HomeButton;
