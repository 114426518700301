import React from "react";
import PropTypes from "prop-types";
import { TranslationProvider } from "../providers/TranslationProvider";
import { LeadDetailProvider } from "../providers/LeadDetailProvider";
import { withRouter } from "../utils/withRouter";
import i18next from "../pages/Leads/LeadDetails/translations/i18n";

@withRouter
export class LeadDetailsRoute extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    leadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  render() {
    const { children, leadId } = this.props;

    return (
      <TranslationProvider i18n={i18next}>
        <LeadDetailProvider leadId={leadId}>{children}</LeadDetailProvider>
      </TranslationProvider>
    );
  }
}

export default LeadDetailsRoute;
