import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dropdown, Flex, Text,
} from "@wingmate/toolkit";
import { BookIcon } from "@wingmate/graphics";
import { AppContext } from "../../../context/AppProvider";
import { Username } from "../../../common/Users/Username/Username";
import { useStore } from "../../../hooks/useStore";
import { ContractSelector } from "../ContractSelector/ContractSelector";
import { CurrentScoreCard } from "../CurrentScoreCard/CurrentScoreCard";
import { DashboardStats } from "../../../pages/Dashboard/DashboardInfo/LeadStats/DashboardStats";
import { GlobalSearch } from "../GlobalSearch/GlobalSearch";
import { Notifications } from "../Notifications/Notifications";

import "./TopNavigation.scss";

const WORKER_TYPE = "Worker";

export const TopNavigation = observer(() => {
  const { t } = useTranslation(["default", "common"]);
  const { campaign, currentUser } = useContext(AppContext);
  const { name, scEnabled, teamResourcesEnabled } = campaign;
  const {
    authStore,
    contractStore,
    globalSearchStore,
    navigationStore,
  } = useStore();
  const { contracts, isInitialized } = contractStore;
  const { isSearchBarOpen } = globalSearchStore;
  const { isPinned } = navigationStore;

  const [inTeamResources, setInTeamResources] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const getUserContracts = async () => {
      await contractStore.getUserContractsAsync();
    };

    getUserContracts();
  }, [contractStore]);

  useEffect(() => {
    if (location.pathname) {
      setInTeamResources(location.pathname.includes("team-resources"));
    }
  }, [location.pathname]);

  const logout = async () => {
    await authStore.logoutUser();
  };

  const getDropdownItemsForUser = () => {
    const profileItem = {
      key: "profile",
      label: (
        <Link to={`/users/${currentUser.id}/edit`}>
          {t("common:layouts.actions.profile")}
        </Link>
      ),
    };

    const notificationItem = {
      key: "notifications",
      label: (
        <Link to="/notifications">
          {t("common:layouts.actions.notifications")}
        </Link>
      ),
    };

    const segmentsItem = {
      key: "segments",
      label: (
        <Link to="/segments">
          {t("common:layouts.actions.segments")}
        </Link>
      ),
    };

    const leadsChasedMetricItem = {
      key: "leads_chased_metrics",
      label: (
        <Link to="/leads_chased_metrics">
          {t("common:layouts.actions.pmProgress")}
        </Link>
      ),
    };

    const supportItem = {
      key: "support",
      label: (
        <a href="https://support.wingmateapp.com" target="_blank" rel="noopener noreferrer">
          {t("common:navigation.sideNavigation.support")}
        </a>
      ),
    };

    const trashItem = {
      key: "trash",
      label: (
        <Link to="/trash">
          {t("common:layouts.actions.trash")}
        </Link>
      ),
    };

    const logoutItem = {
      key: "logout",
      label: t("common:layouts.actions.logout"),
      onClick: logout,
    };

    if (currentUser.pmAccount) {
      return [
        profileItem,
        leadsChasedMetricItem,
        notificationItem,
        segmentsItem,
        trashItem,
        supportItem,
        logoutItem,
      ];
    }

    if (currentUser.type === "Manager") {
      return [
        profileItem,
        notificationItem,
        segmentsItem,
        trashItem,
        supportItem,
        logoutItem,
      ];
    }

    if (currentUser.type === "Salesperson") {
      return [
        profileItem,
        notificationItem,
        segmentsItem,
        logoutItem,
      ];
    }

    return [
      profileItem,
      notificationItem,
      logoutItem,
    ];
  };

  const showScoreCard = currentUser.type !== WORKER_TYPE && scEnabled;

  const renderUserProfileDropdown = () => (
    <Dropdown
      className="TopNavigation__userDropdown"
      items={getDropdownItemsForUser()}
      showArrow={false}
      buttonProps={{
        className: "RightSection__UserSection",
      }}
    >
      <Flex align="center" gap="middle">
        <Username user={currentUser} hideName showTooltip={false} size="large" />
        <Flex className="UserSection__Info" gap={4} align="flex-start" vertical>
          <Text type="P" weight="Medium">{currentUser.fullName}</Text>
          <Text type="S">
            {`${t("common:memberSince", {
              date: moment(currentUser.createdAt).format("MMM D, YYYY"),
            })} `}
          </Text>
        </Flex>
      </Flex>
    </Dropdown>
  );

  const contractsLoaded = contracts.length > 0 && isInitialized;

  const topNavigationClassNames = classNames(
    "TopNavigation",
    { sidebarOpen: isPinned },
    { searchBarOpen: isSearchBarOpen },
  );

  const navigate = useNavigate();

  const handleTeamResourcesIconClicked = () => {
    navigate(`campaigns/${campaign.id}/team-resources`);
  };

  const teamResourcesButtonClassName = classNames(
    "RightSection__TeamResourcesButton",
    { "RightSection__TeamResourcesButton--active": inTeamResources },
  );

  return (
    <Flex className={topNavigationClassNames} align="center" justify="space-between" gap={16}>
      <Flex className="TopNavigation__LeftSection" align="center" gap="large">
        {contractsLoaded && <ContractSelector contracts={contracts} />}
        <Flex vertical>
          <Text className="LeftSection__CampaignName" type="H6" weight="Medium">{name}</Text>
          <DashboardStats />
        </Flex>
      </Flex>
      <div className="TopNavigation__Separator" />
      <Flex className="TopNavigation__RightSection" justify="flex-end" align="center" gap="middle">
        <Flex className="RightSection__actions" justify="flex-end" align="center" gap="4px">
          <GlobalSearch />
          {teamResourcesEnabled && (
          <Button className={teamResourcesButtonClassName} data-testid="TeamResources__Button" onClick={handleTeamResourcesIconClicked}>
            <BookIcon height={20} width={20} />
          </Button>
          )}
          {showScoreCard && (
            <CurrentScoreCard />
          )}
          <Notifications />
        </Flex>
        <div className="RightSection__Separator" />
        {renderUserProfileDropdown()}
      </Flex>
    </Flex>
  );
});

export default TopNavigation;
