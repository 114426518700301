import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const CREATE_CAMPAIGN_TARGET_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/sc_targets`);

const CAMPAIGN_TARGETS_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/sc_targets.json`);

const CAMPAIGN_TARGET_ENDPOINT = (campaignId, targetId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/sc_targets/${targetId}`);

export async function getTargets(campaignId) {
  try {
    const response = await axiosService.axiosGet(
      CAMPAIGN_TARGETS_ENDPOINT(campaignId)
    );

    return response.data.data;
  } catch (err) {
    return {};
  }
}

export async function postTarget(campaignId, payload) {
  const response = await axiosService.axiosPost(
    CREATE_CAMPAIGN_TARGET_ENDPOINT(campaignId),
    payload
  );

  return response.data;
}

export async function updateTarget(campaignId, targetId, payload) {
  const response = await axiosService.axiosPut(
    CAMPAIGN_TARGET_ENDPOINT(campaignId, targetId),
    payload
  );

  return response.data;
}

export async function deleteTarget(campaignId, targetId) {
  const response = await axiosService.axiosDelete(
    CAMPAIGN_TARGET_ENDPOINT(campaignId, targetId)
  );

  return response.data;
}

export default {
  getTargets,
};
