import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const CAMPAIGN_APPROVAL_CONDITIONS_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/approval_conditions`);

const CAMPAIGN_APPROVAL_CONDITION_ENDPOINT = (campaignId, approvalConditionId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/approval_conditions/${approvalConditionId}`);

export async function getApprovalConditions(campaignId, payload = {}) {
  const response = await axiosService.axiosGet(CAMPAIGN_APPROVAL_CONDITIONS_ENDPOINT(campaignId), payload)

  return response.data;
}

export async function createApprovalCondition(campaignId, payload = {}) {
  const response = await axiosService.axiosPost(CAMPAIGN_APPROVAL_CONDITIONS_ENDPOINT(campaignId), payload)

  return response.data;
}

export async function updateApprovalCondition(campaignId, approvalConditionId, payload = {}) {
  const response = await axiosService.axiosPut(CAMPAIGN_APPROVAL_CONDITION_ENDPOINT(campaignId, approvalConditionId), payload)

  return response.data;
}

export async function deleteApprovalCondition(campaignId, approvalConditionId) {
  const response = await axiosService.axiosDelete(CAMPAIGN_APPROVAL_CONDITION_ENDPOINT(campaignId, approvalConditionId))

  return response.data;
}
