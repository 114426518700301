import { action, makeAutoObservable, observable, runInAction } from "mobx";
import * as arrayUtils from "../utils/arrays";
import * as campaignRemindersService from "../requests/campaigns/reminders";
import * as leadReminderService from "../requests/leads/reminders";
import * as userRemindersService from "../requests/users/reminders";
import * as remindersService from "../requests/reminders";

export class RemindersStore {
  rootStore;

  @observable reminders = observable.array();

  @observable isLoading = false;

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  isEmpty() {
    return this.reminders.length === 0;
  }

  @action
  setIsLoading(isLoading) {
    runInAction(() => {
      this.isLoading = isLoading;
    });
  }

  @action
  setReminders(reminders) {
    this.reminders = reminders;
  }

  @action
  setCachedReminders(newReminders) {
    const allReminders = [...newReminders, ...this.reminders];

    const allRemindersUnique = arrayUtils.uniqueOnId(allReminders, "id");

    this.setReminders(allRemindersUnique);
  }

  @action
  modifyReminderInCache(reminderId, newReminder) {
    const reminders = [...this.reminders];

    const reminderIndex = reminders.findIndex(
      (reminder) => parseInt(reminder.id, 10) === parseInt(reminderId, 10)
    );

    if (reminderIndex !== -1) {
      reminders[reminderIndex] = newReminder;

      this.setReminders(reminders);
    }
  }

  @action
  addReminderToCache(newReminder) {
    this.setCachedReminders([newReminder]);
  }

  @action
  async updateReminder(reminderId, payload) {
    const response = await remindersService.putReminder(reminderId, payload);

    const reminder = response.data;

    this.modifyReminderInCache(reminderId, reminder);

    return reminder;
  }

  @action
  async createReminder(leadId, newReminderPayload) {
    const response = await leadReminderService.postReminder(
      leadId,
      newReminderPayload
    );

    const newReminder = response.data;

    this.addReminderToCache(newReminder);

    return newReminder;
  }

  @action
  async retrieveRemindersForCampaignAsync(campaignId, params = {}) {
    const response = await campaignRemindersService.getReminders(
      campaignId,
      params
    );

    const reminders = response.data.data;

    this.setCachedReminders(reminders);

    return response;
  }

  @action
  async retrieveRemindersForUsersAsync(userId, params = {}) {
    const response = await userRemindersService.getReminders(userId, params);

    const reminders = response.data.data;

    this.setCachedReminders(reminders);

    return response;
  }
}

export default RemindersStore;
