import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { ConfirmText } from "../../../../controls/ConfirmText/ConfirmText";
import { withRouter } from "../../../../utils/withRouter";
import { adminStoreProptypes } from "../../../../proptypes/stores";
import { withTranslations } from "../../../../utils/withTranslations";

@withTranslations
@inject("adminStore")
@withRouter
@observer
export class EditQuoteTemplateBreadcrumb extends React.Component {
  static propTypes = {
    adminStore: adminStoreProptypes,
    quoteTemplateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    t: PropTypes.func,
  };

  async componentDidMount() {
    const { adminStore } = this.props;
    const { quoteTemplatesStore } = adminStore;
    const { quoteTemplates } = quoteTemplatesStore;

    if (quoteTemplates.length === 0) {
      await quoteTemplatesStore.getQuoteTemplatesAsync();
    }
  }

  onSave = async (newQuoteTemplateName) => {
    const { adminStore, quoteTemplateId, t } = this.props;
    const { bannerStore, quoteTemplatesStore } = adminStore;

    try {
      await quoteTemplatesStore.updateQuoteTemplateAsync(quoteTemplateId, {
        name: newQuoteTemplateName,
      });
    } catch (error) {
      quoteTemplatesStore.setIsLoading(false);
      bannerStore.addBanner(
        "red",
        t("quotes.template.update.failedHeader"),
        t("quotes.template.update.failedBody")
      );
    }
  };

  render() {
    const { adminStore, quoteTemplateId } = this.props;
    const { quoteTemplatesStore } = adminStore;

    const quoteTemplate =
      quoteTemplatesStore.findQuoteTemplate(quoteTemplateId);

    return quoteTemplate ? (
      <ConfirmText
        defaultValue={quoteTemplate.attributes.name}
        onSave={this.onSave}
      />
    ) : (
      <></>
    );
  }
}

export default EditQuoteTemplateBreadcrumb;
