import axios from "axios";
import { endpoint } from "../../utils/endpoint";

import * as axiosService from "../axiosRequests";

const CREATE_COMMENT_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/comments`);

const UPDATE_COMMENT_ENDPOINT = (leadId, commentId) =>
  endpoint(`app/v1.0/leads/${leadId}/comments/${commentId}`);

export async function postLeadComment(leadId, payload) {
  await axios.post(
    CREATE_COMMENT_ENDPOINT(leadId),
    {
      comment: payload,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
}

export async function putLeadComment(leadId, commentId, payload) {
  const response = await axiosService.axiosPut(
    UPDATE_COMMENT_ENDPOINT(leadId, commentId),
    {
      comment: payload,
    }
  );
  return response.data.data;
}

export async function deleteLeadComment(leadId, commentId) {
  await axiosService.axiosDelete(UPDATE_COMMENT_ENDPOINT(leadId, commentId));
  return true;
}

export default { postLeadComment, putLeadComment, deleteLeadComment };
