import { axiosGet } from "../axiosRequests";
import { integrationEndpoint } from "../../utils/endpoint";

const APP_ENDPOINT = (id) =>
  integrationEndpoint(`integrationsBucket?appId=${id}`);

export async function getApp(appId) {
  const response = await axiosGet(APP_ENDPOINT(appId));

  return response.data;
}
