import { action, makeAutoObservable } from "mobx";
import { IApprovalCondition } from "../types/IApproval";
import { Page } from "../types/Meta";
import { AdminStore } from "./AdminStore";
import * as approvalConditionsService from "../requests/campaigns/approvalConditions";

export class ApprovalConditionsStore {
  adminStore;

  approvalConditions: IApprovalCondition[] = [];

  count = 0;

  isLoading = false;

  page: Page = { current: 1, more: false, next: null };

  constructor(adminStore: AdminStore) {
    makeAutoObservable(this);

    this.adminStore = adminStore;
  }

  @action
  setApprovalConditions(approvalConditions: IApprovalCondition[]) {
    this.approvalConditions = approvalConditions;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  setCount(count: number) {
    this.count = count;
  }

  @action
  setPage(page: Page) {
    this.page = page;
  }

  @action
  resetPage() {
    this.page = { current: 1, more: false, next: null };
  }

  @action
    modifyApprovalConditionInCache = (
      approvalConditionId: string | number,
      newApprovalCondition: IApprovalCondition,
    ) => {
      const approvalConditions = [...this.approvalConditions];
      const approvalConditionIndex = approvalConditions.findIndex(
        (approvalCondition) => approvalCondition.id.toString() === approvalConditionId.toString(),
      );

      if (approvalConditionIndex !== -1) {
        approvalConditions[approvalConditionIndex] = newApprovalCondition;
      } else {
        approvalConditions.push(newApprovalCondition);
      }

      this.setApprovalConditions(approvalConditions);
    };

  @action
    retrieveApprovalConditions = async () => {
      this.isLoading = true;
      try {
        const { data, meta } = await approvalConditionsService.getApprovalConditions(
          this.adminStore.campaignId,
        );
        const { count, page } = meta;

        this.setApprovalConditions(data);
        this.setCount(count);
        this.setPage(page);
      } catch (error) {
        this.setApprovalConditions([]);
      } finally {
        this.setIsLoading(false);
      }
    };

  @action
    getMoreApprovalConditions = async () => {
      this.setIsLoading(true);
      const payload = {
        page: this.page?.next,
      };

      const { data, meta } = await approvalConditionsService.getApprovalConditions(
        this.adminStore.campaignId,
        payload,
      );
      const { count, page } = meta;
      const allApprovalConditions = this.approvalConditions.concat(data);

      this.setApprovalConditions(allApprovalConditions);
      this.setCount(count);
      this.setPage(page);
      this.setIsLoading(false);
    };

  @action
    createApprovalCondition = async (payload: Record<string, unknown>) => {
      const newApprovalCondition = await approvalConditionsService.createApprovalCondition(
        this.adminStore.campaignId,
        payload,
      );

      this.modifyApprovalConditionInCache(newApprovalCondition, newApprovalCondition);
    };

  @action
    updateApprovalCondition = async (
      approvalConditionId: string,
      payload: Record<string, unknown>,
    ) => {
      const updatedApprovalCondition = await approvalConditionsService.updateApprovalCondition(
        this.adminStore.campaignId,
        approvalConditionId,
        payload,
      );

      this.modifyApprovalConditionInCache(updatedApprovalCondition.id, updatedApprovalCondition);
    };

  @action
    deleteApprovalCondition = async (approvalConditionId: string) => {
      await approvalConditionsService.deleteApprovalCondition(
        this.adminStore.campaignId,
        approvalConditionId,
      );

      const updatedApprovalConditionsList = this.approvalConditions.filter(
        (condition) => condition.id.toString() !== approvalConditionId,
      );

      this.setApprovalConditions(updatedApprovalConditionsList);
    };
}

export default ApprovalConditionsStore;
