import { action, computed, makeObservable, observable } from "mobx";
import { uniqueOnId } from "../utils/arrays";
import { isNullOrEmpty } from "../utils/commons";
import * as leadLinksService from "../requests/leadLinks/leadLinks";

const PARENT_LINK_TYPE = "parent";

export class LeadLinksStore {
  leadDetailsStore;

  @observable filters = {};

  @observable leads = [];

  @observable leadLinks = [];

  @observable isLoading = true;

  constructor(leadDetailsStore) {
    makeObservable(this);
    this.leadDetailsStore = leadDetailsStore;
  }

  @computed get childLeads() {
    const childLinks = this.filterLinks("parentLead");

    return childLinks.map((link) => link.attributes.childLead).filter((lead) => this.matchesFilter(lead));
  }

  @computed get parentLeads() {
    const parentLinks = this.filterLinks("childLead");

    return parentLinks.map((link) => link.attributes.parentLead).filter((lead) => this.matchesFilter(lead));
  }

  @computed get leadLinkCampaigns() {
    return uniqueOnId(this.leadLinks.reduce((campaigns, leadLink) => {
      const { attributes } = leadLink;
      const { childLead, parentLead } = attributes;

      const currentLeadId = this.leadDetailsStore.leadId;

      campaigns = [
        ...campaigns,
        ...(currentLeadId != childLead.id ? [childLead.campaign] : []),
        ...(currentLeadId != parentLead.id ? [parentLead.campaign] : []),
      ];

      return campaigns;
    }, []), "id");
  }

  getChildLeadsForCampaign(campaignId) {
    return this.childLeads.filter((lead) => lead.campaign.id == campaignId);
  }

  getParentLeadsForCampaign(campaignId) {
    return this.parentLeads.filter((lead) => lead.campaign.id == campaignId);
  }

  matchesAddress(address) {
    const { address: filterAddress } = this.filters;

    if (filterAddress === undefined) return true;

    return filterAddress && address.toLowerCase().includes(filterAddress.toLowerCase());
  }

  matchesIdentifier(identifier) {
    const { identifier: filterIdentifier } = this.filters;

    if (filterIdentifier === undefined) return true;

    return filterIdentifier && identifier.toLowerCase().includes(filterIdentifier.toLowerCase());
  }

  matchesOwners(ownerIds) {
    const { owners: filterOwners } = this.filters;

    if (filterOwners === undefined) return true;

    return filterOwners && filterOwners.some((owner) => ownerIds.includes(owner));
  }

  matchesFilter(linkedLead) {
    const { address, identifier, owners } = linkedLead;

    if (isNullOrEmpty(this.filters)) return true;

    if (!this.matchesAddress(address || "")
        || !this.matchesIdentifier(identifier)
        || !this.matchesOwners(owners.map((owner) => owner.id))) {
      return false;
    }

    return true;
  }

  @action
  setFilters(filters) {
    this.filters = filters;
  }

  @action
  setLeads(leads) {
    this.leads = leads;
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setLeadLinks(leadLinks) {
    this.leadLinks = leadLinks;
  }

  @action
  deleteLeadLink(linkId) {
    const leadLinkIndex = this.leadLinks.findIndex(
      (leadLink) => leadLink.id === linkId
    );

    if (leadLinkIndex !== -1) {
      this.leadLinks.splice(leadLinkIndex, 1);
    }
  }

  getLinkId(outwardLeadId) {
    const matchingLink = this.leadLinks.find((link) => {
      if (link.attributes.childLead.id === outwardLeadId) {
        return link;
      }
      if (link.attributes.parentLead.id === outwardLeadId) {
        return link;
      }
      return null;
    });
    return matchingLink.id;
  }

  filterLinks(linkType) {
    const { leadId } = this.leadDetailsStore;

    return this.leadLinks.filter((leadLink) => {
      if (leadLink.attributes[linkType].id === parseInt(leadId, 10)) {
        return true;
      } else {
        return false;
      }
    });
  }

  @action
  async addLeadLinkAsync(linkType, outwardLeadId) {
    this.setIsLoading(true);

    const { leadId } = this.leadDetailsStore;

    let parentLeadId;
    let childLeadId;

    if (linkType === PARENT_LINK_TYPE) {
      parentLeadId = leadId;
      childLeadId = outwardLeadId;
    } else {
      parentLeadId = outwardLeadId;
      childLeadId = leadId;
    }

    const payload = {
      lead_link: {
        parent_lead_id: parentLeadId,
        child_lead_id: childLeadId,
      },
    };

    try {
      const response = await leadLinksService.postLeadLink(payload);
      this.leadLinks.push(response);
    } finally {
      this.setIsLoading(false);
    }
  }

  async deleteLeadLinkAsync(outwardLeadId) {
    const linkId = this.getLinkId(outwardLeadId);

    await leadLinksService.deleteLeadLink(linkId);
    this.deleteLeadLink(linkId);
  }

  async retrieveLinksForLeadAsync() {
    const { leadId } = this.leadDetailsStore;
    this.setIsLoading(true);
    const response = await leadLinksService.getLeadLinks(leadId);
    this.setLeadLinks(response);
    this.setIsLoading(false);
  }
}

export default LeadLinksStore;
