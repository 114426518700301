import React from "react";
import PropTypes from "prop-types";
import i18n from "../pages/Public/translations/i18n";
import { I18nextProvider } from "react-i18next";

export class PublicRoute extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children } = this.props;

    return (
      <I18nextProvider i18n={i18n}>
        <div id="publicLayer">{children}</div>
      </I18nextProvider>
    );
  }
}

export default PublicRoute;
