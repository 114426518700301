import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { authStorePropTypes } from "../proptypes/stores";

export const AppContext = React.createContext();
export const AppConsumer = AppContext.Consumer;

@inject("authStore")
@observer
class AppProvider extends Component {
  static propTypes = {
    authStore: authStorePropTypes,
    children: PropTypes.node,
  };

  render() {
    const { authStore, children } = this.props;
    const context = {
      ...authStore.context,
      rootStore: authStore.rootStore,
    }

    return (
      <AppContext.Provider value={context}>{children}</AppContext.Provider>
    );
  }
}

export { AppProvider };

export default AppContext;
