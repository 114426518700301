import React from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../../../context/AppProvider";
import { withTranslations } from "../../../../utils/withTranslations";
import { LeadStatsOption } from "./LeadStatsOption"
import { Dropdown, Flex } from "@wingmate/toolkit";
import "./LeadStats.scss";

@withTranslations
class LeadStats extends React.Component {
  static contextType = AppContext;

  static defaultProps = {
    showOnlyTotal: false,
  };

  static propTypes = {
    activePipelineAmount: PropTypes.number,
    activePipelineCount: PropTypes.number,
    activePipelineTotalAmount: PropTypes.number,
    activePipelineTotalCount: PropTypes.number,
    showOnlyTotal: PropTypes.bool,
    t: PropTypes.func,
    totalPipelineAmount: PropTypes.number,
    totalPipelineCount: PropTypes.number,
    totalPipelineTotalAmount: PropTypes.number,
    totalPipelineTotalCount: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      leadStatsOption: "totalPipeline",
    };
  }

  onOptionClick = (event) => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
      leadStatsOption: event.key,
    }));
  }

  getPipelineStats = (leadStatsOption, props) => {
    let stats = {
      totalNumberOfLeads: 0,
      filterNumberOfLeads: 0,
      totalValue: 0,
      filteredValue: 0
    };
  
    if (leadStatsOption === "activePipeline") {
      stats.totalNumberOfLeads = props.activePipelineTotalCount;
      stats.filterNumberOfLeads = props.activePipelineCount;
      stats.totalValue = props.activePipelineTotalAmount;
      stats.filteredValue = props.activePipelineAmount;
    } else {
      stats.totalNumberOfLeads = props.totalPipelineTotalCount;
      stats.filterNumberOfLeads = props.totalPipelineCount;
      stats.totalValue = props.totalPipelineTotalAmount;
      stats.filteredValue = props.totalPipelineAmount;
    }
  
    return stats;
  }
  

  render() {
    const { leadStatsOption } = this.state;
    const { showOnlyTotal, t } = this.props;

    const items = [
      {
        label: t("activePipelineLabel"),
        key: 'activePipeline',
      },
      {
        label: t("totalPipelineLabel"),
        key: 'totalPipeline',
      }
    ];

    const { filterNumberOfLeads, filteredValue, totalNumberOfLeads, totalValue } = this.getPipelineStats(leadStatsOption, this.props);

    return (
      <Flex className="LeadStats" align="center" gap={8}>
        <LeadStatsOption option={leadStatsOption} filtered={!showOnlyTotal} totalNumberOfLeads={totalNumberOfLeads} filterNumberOfLeads={filterNumberOfLeads} totalValue={totalValue} filteredValue={filteredValue}/>
        <Dropdown 
          items={items} 
          onClick={this.onOptionClick}
          buttonProps={{
            className: "LeadStats__DropdownTrigger",
          }}
        />
      </Flex>
    );
  }
}

export { LeadStats };

export default LeadStats;