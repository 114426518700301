import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { FlexCol, FlexRow, Spinner, Tab, Tabs, Text } from "wm-ui-toolkit";
import { AppContext } from "../../../../context/AppProvider";
import { withTranslations } from "../../../../utils/withTranslations";
import { ApprovalItem } from "./ApprovalItem/ApprovalItem";
import { ConditionalWrapper } from "../../../../utils/ConditionalWrapper";

import "./NotificationsCenter.scss";

@withTranslations
@observer
export class NotificationsCenter extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    approvalRequests: PropTypes.array,
    closeNotificationMenu: PropTypes.func,
    isLoading: PropTypes.bool,
    notifications: PropTypes.array,
    t: PropTypes.func,
  };

  renderNotification = (notification) => {
    const { closeNotificationMenu } = this.props;

    return (
      <ConditionalWrapper
        key={notification.id}
        condition={
          notification.attributes.leadId && notification.attributes.title
        }
        wrapper={(children) => (
          <Link
            className="notifications__link"
            key={notification.id}
            to={`/leads/${notification.attributes.leadId}/`}
            onClick={closeNotificationMenu}
          >
            {children}
          </Link>
        )}
      >
        <FlexRow key={notification.id} className="notification">
          <Text>{notification.attributes.text}</Text>
        </FlexRow>
      </ConditionalWrapper>
    );
  };

  renderNotifications = () => {
    const { currentUser } = this.context;
    const { isLoading, notifications, t } = this.props;

    const noUpdates = !isLoading && notifications.length === 0;

    return (
      <>
        <div className="NotificationsCenter__notifications">
          {notifications.map((notification) =>
            this.renderNotification(notification)
          )}
          <FlexRow className="notification">
            {isLoading && <Spinner />}
            {noUpdates && <Text>{t("common:layouts.noUpdates")}</Text>}
          </FlexRow>
        </div>
        {currentUser.type !== "Worker" && (
          <FlexRow
            className="NotificationsCenter__seeAll"
            justifyContent="flex-end"
          >
            <Link to={`/notifications`}>
              <Text type="P" weight="Semi">
                {t("common:layouts.seeAll")}
              </Text>
            </Link>
          </FlexRow>
        )}
      </>
    );
  };

  renderApprovals = () => {
    const { approvalRequests, closeNotificationMenu, t } = this.props;
    const noApprovals = approvalRequests.length === 0;
    return (
      <>
        <div className="NotificationsCenter__notifications">
          {approvalRequests.map((approvalRequest) => (
            <ApprovalItem
              closeNotificationMenu={closeNotificationMenu}
              approvalRequest={approvalRequest}
              key={approvalRequest.id}
            />
          ))}
          {noApprovals && (
            <FlexRow className="notification">
              <Text>{t("common:layouts.noApprovals")}</Text>
            </FlexRow>
          )}
        </div>
      </>
    );
  };

  renderTabs = () => {
    const { t } = this.props;
    return (
      <Tabs>
        <Tab id="updates" label={t("common:updates")}>
          {this.renderNotifications()}
        </Tab>
        <Tab id="approvals" label={t("common:approvals")}>
          {this.renderApprovals()}
        </Tab>
      </Tabs>
    );
  };

  render() {
    const { campaign, currentUser } = this.context;
    const { t } = this.props;

    const showApprovals =
      currentUser.type === "Manager" && campaign.approvalsEnabled;

    return (
      <FlexCol className="NotificationsCenter">
        <Text className="NotificationsCenter__title" type="H6" weight="Semi">
          {t("common:notificationCenter")}
        </Text>
        {showApprovals ? this.renderTabs() : this.renderNotifications()}
      </FlexCol>
    );
  }
}
