import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Flex } from "@wingmate/toolkit";
import { inject, observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { rootStorePropTypes } from "../../../../proptypes/stores";
import { CurrentSegment } from "../CurrentSegment/CurrentSegment";
import { LeadStats } from "./LeadStats";

import "./DashboardStats.scss";

const DashboardStats = inject("rootStore")(observer((props) => {
  const { rootStore } = props;
  const { filtersStore, funnelStore, globalSearchStore, 
    listLeadsStore, mapStore, segmentsStore, viewStore } = rootStore;
  const { isSearchBarOpen } = globalSearchStore;
  const { currentSegment } = segmentsStore;
  const { view } = viewStore;
  const { filterCount } = filtersStore;
  const [showLeadStats, setShowLeadStats] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowLeadStats(location.pathname.includes("/dashboard") && !isSearchBarOpen);
  }, [isSearchBarOpen, location]);

  const getLeadStats = () => {
    if (view.id === "map") {
      return mapStore.leadStats;
    } else if (view.id === "funnel") {
      return funnelStore.leadStats;
    } else {
      return listLeadsStore.leadStats;
    }
  };

  const whenNoFilters = (filterCount === 0) && view.id !== "map";
  return (
    showLeadStats && (
      <Flex className="DashboardStats" align="center" gap={16}>
        {currentSegment && (
          <>
            <CurrentSegment />
            <div className="DashboardStats__Divider" />
          </>
        )}
        <LeadStats {...getLeadStats()} showOnlyTotal={whenNoFilters} />
      </Flex>
    )
  );
}));

DashboardStats.propTypes = {
  rootStore: rootStorePropTypes,
  t: PropTypes.func,
};

export { DashboardStats };

export default DashboardStats;
