import React from "react";
import i18next from "../pages/Dashboard/translations/i18n";
import { Outlet } from "react-router-dom";
import { Flex } from "@wingmate/toolkit";
import { withRouter } from "../utils/withRouter";
import { AppContext } from "../context";
import { TranslationProvider } from "../providers/TranslationProvider";
import { Wrapper } from "@googlemaps/react-wrapper";
import { WrapperLoader } from "../layouts/WrapperLoader/WrapperLoader";
import { PageLayer } from "../layouts/PageLayer/PageLayer";
import { NavigationMenu } from "../layouts/Navigation/TopNavigation/NavigationMenu/NavigationMenu";
import { SideNavigation } from "../layouts/Navigation/SideNavigation/SideNavigation";
import { TopNavigation } from "../layouts/Navigation/TopNavigation/TopNavigation";

@withRouter
export class HeaderRoute extends React.Component {
  static contextType = AppContext;

  renderLoader(status) {
    return status === "LOADING" ? <WrapperLoader /> : null;
  }

  render() {
    return (
      <TranslationProvider i18n={i18next}>
        <Flex>
          <SideNavigation />
          <Flex id="mainLayer" align="stretch" vertical>
            <Flex>
              <NavigationMenu />
              <TopNavigation />
            </Flex>
            <Wrapper
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              libraries={["places", "drawing"]}
              render={(status) => this.renderLoader(status)}
            >
              <PageLayer>
                <Outlet />
              </PageLayer>
            </Wrapper>
          </Flex>
        </Flex>
      </TranslationProvider>
    );
  }
}

export default HeaderRoute;
