import { action, computed, makeAutoObservable, observable } from "mobx";
import * as userPerformanceService from "../requests/users/performances";

export class PerformancesStore {
  @observable performances = [];
  @observable isLoading = true;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @computed get currentUserPerformances() {
    const { currentUser } = this.rootStore.context;

    return this.performances.filter(
      (performance) =>
        performance.attributes.userId === currentUser.id &&
        performance.id === null
    );
  }

  @action
  setPerformances(performances) {
    this.performances = performances;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  performancesForUser(userId) {
    if (userId !== undefined && userId !== null) {
      return this.performances.filter(
        (performance) =>
          performance.attributes.userId.toString() === userId.toString()
      );
    } else {
      return [];
    }
  }

  @action
  async getPerformancesAsync(userId) {
    this.setIsLoading(true);
    const performances = await userPerformanceService.getPerformances(userId);

    this.setPerformances(performances);
    this.setIsLoading(false);

    return performances;
  }
}

export default PerformancesStore;
