import * as axiosService from "../axiosRequests";
import { IWebhookAttributes, IWebhook } from "../../types/IWebhook";

import { endpoint } from "../../utils/endpoint";

export const WEBHOOK_ENDPOINTS_ENDPOINT = (campaignId: string): string => endpoint(`app/v1.0/campaigns/${campaignId}/webhook_endpoints`);

export const WEBHOOK_ENDPOINT_ENDPOINT = (
  campaignId: string,
  webhookEndpointId: string,
): string => endpoint(
  `app/v1.0/campaigns/${campaignId}/webhook_endpoints/${webhookEndpointId}`,
);

export const getWebhookEndpoints = async (campaignId: string): Promise<IWebhook[]> => {
  const response = await axiosService.axiosGet(
    WEBHOOK_ENDPOINTS_ENDPOINT(campaignId),
  );

  return response.data.data;
};

export const postWebhookEndpoint = async (
  campaignId: string,
  payload: Partial<IWebhookAttributes>,
): Promise<IWebhook> => {
  const response = await axiosService.axiosPost(
    WEBHOOK_ENDPOINTS_ENDPOINT(campaignId),
    payload,
  );

  return response.data;
};

export const deleteWebhookEndpoint = async (
  campaignId: string,
  webhookEndpointId: string,
): Promise<IWebhook> => {
  const response = await axiosService.axiosDelete(
    WEBHOOK_ENDPOINT_ENDPOINT(campaignId, webhookEndpointId),
  );

  return response.data.data;
};

export default {
  getWebhookEndpoints,
};
