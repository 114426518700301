import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const CAMPAIGN_QUOTE_TEMPLATES_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/quote_templates`);

const CAMPAIGN_QUOTE_TEMPLATE_ENDPOINT = (campaignId, quoteTemplateId) =>
  endpoint(
    `app/v1.0/campaigns/${campaignId}/quote_templates/${quoteTemplateId}`
  );

export async function getQuoteTemplates(campaignId) {
  const response = await axiosService.axiosGet(
    CAMPAIGN_QUOTE_TEMPLATES_ENDPOINT(campaignId)
  );

  return response.data.data;
}

export async function getDownloadUrl(campaignId, quoteTemplateId) {
  const response = await axiosService.axiosGet(
    `${CAMPAIGN_QUOTE_TEMPLATE_ENDPOINT(campaignId, quoteTemplateId)}/download`
  );

  return response.data.url;
}

export async function deleteQuoteTemplate(campaignId, quoteTemplateId) {
  const response = await axiosService.axiosDelete(
    CAMPAIGN_QUOTE_TEMPLATE_ENDPOINT(campaignId, quoteTemplateId)
  );

  return response.data;
}

export async function updateQuoteTemplate(
  campaignId,
  quoteTemplateId,
  payload,
  headers
) {
  const response = await axiosService.axiosPut(
    CAMPAIGN_QUOTE_TEMPLATE_ENDPOINT(campaignId, quoteTemplateId),
    payload,
    headers
  );

  return response.data;
}

export async function createQuoteTemplate(campaignId, payload, headers) {
  const response = await axiosService.axiosPost(
    CAMPAIGN_QUOTE_TEMPLATES_ENDPOINT(campaignId),
    payload,
    headers
  );

  return response.data;
}

export default {
  getQuoteTemplates,
};
