import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const PERFORMANCES_ENDPOINT = (userId) =>
  endpoint(`app/v1.0/users/${userId}/performances.json`);

export async function getPerformances(userId) {
  try {
    const response = await axiosService.axiosGet(PERFORMANCES_ENDPOINT(userId));

    return response.data.data;
  } catch (err) {
    return [];
  }
}

export default {
  getPerformances,
};
