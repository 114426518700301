import { endpoint } from "../../utils/endpoint";
import * as axiosService from "../axiosRequests";

const CREATE_LEAD_LINK_ENDPOINT = endpoint("app/v1.0/lead_links");
const LEAD_LINK_ENDPOINT = (linkId) =>
  endpoint(`app/v1.0/lead_links/${linkId}`);
const LEAD_LINKS_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/lead_links`);

export async function postLeadLink(payload) {
  const response = await axiosService.axiosPost(
    CREATE_LEAD_LINK_ENDPOINT,
    payload
  );
  return response.data.data;
}

export async function getLeadLinks(leadId) {
  const response = await axiosService.axiosGet(LEAD_LINKS_ENDPOINT(leadId), {});

  return response.data.data;
}

export async function deleteLeadLink(linkId) {
  await axiosService.axiosDelete(LEAD_LINK_ENDPOINT(linkId));
  return true;
}

export default {
  postLeadLink,
  getLeadLinks,
};
