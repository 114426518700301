import React, { ReactNode } from "react";
import classNames from "classnames";
import {
  Flex,
  FontTypes,
  Text,
  Tooltip,
  WeightTypes,
} from "@wingmate/toolkit";
import { AsteriskIcon, InfoTooltipIcon } from "wm-graphics";

import "./FieldLabel.scss";

const DEFAULT_ASTERISK_COLOR = "#FC0101";

export interface IFieldLabelProps {
  className?: string;
  color?: string;
  label: ReactNode;
  svg?: "InfoTooltip" | boolean;
  textType?: FontTypes;
  textWeight?: WeightTypes;
  tooltipMessage?: string;
}

export const FieldLabel = ({
  className,
  color,
  label,
  svg = false,
  textType = "H6",
  textWeight,
  tooltipMessage,
}: IFieldLabelProps) => {
  const renderSvg = (svgType: "InfoTooltip" | boolean) => {
    switch (svgType) {
      case "InfoTooltip":
        return <InfoTooltipIcon data-testid="InfoTooltipIcon" width={12} height={12} />;
      default:
        return (
          <AsteriskIcon
            fill={color || DEFAULT_ASTERISK_COLOR}
            className="FieldLabel__Asterisk"
            data-testid="asterisk"
            width={8}
            height={8}
          />
        );
    }
  };

  const renderSvgTooltip = () => (
    <Tooltip title={tooltipMessage}>
      <sup>{renderSvg(svg)}</sup>
    </Tooltip>
  );

  const fieldLabelClass = classNames("FieldLabel", className);

  return (
    <Flex
      className={fieldLabelClass}
      justify="center"
      align="center"
    >
      <Text type={textType} weight={textWeight}>
        {label}
      </Text>
      {svg && (tooltipMessage ? renderSvgTooltip() : <sup>{renderSvg(svg)}</sup>)}
    </Flex>
  );
};

export default FieldLabel;
