import { action, makeAutoObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";
import * as campaignVariablesService from "../requests/campaigns/variables";

export class CampaignVariablesStore {
  @observable campaignVariables = [];
  @observable isLoading = false;
  @observable refresh = true;

  constructor(adminStore) {
    makeAutoObservable(this);
    this.adminStore = adminStore;
    this.campaignId = adminStore.campaignId;
  }

  @action
  setCampaignVariables(campaignVariables) {
    this.campaignVariables = campaignVariables;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setRefresh(refresh) {
    this.refresh = refresh;
  }

  @action
  addCampaignVariable() {
    const newCampaignVariable = {
      id: uuid(),
      saved: false,
      attributes: {
        value: "",
        identifier: "",
      },
    };

    this.campaignVariables.push(newCampaignVariable);
  }

  @action
  deleteCampaignVariable(campaignVariableId) {
    const campaignVariableToDeleteIndex = this.campaignVariables.findIndex(
      (campaignVariable) => campaignVariable.id === campaignVariableId
    );

    if (campaignVariableToDeleteIndex !== -1) {
      this.campaignVariables.splice(campaignVariableToDeleteIndex, 1);
    }
  }

  @action
  modifyCampaignVariableInCache(campaignVariableId, newCampaignVariable) {
    const campaignVariables = [...this.campaignVariables];

    const campaignVariableIndex = campaignVariables.findIndex(
      (campaignVariable) => campaignVariable.id === campaignVariableId
    );

    if (campaignVariableIndex !== -1) {
      campaignVariables[campaignVariableIndex] = newCampaignVariable;

      this.setCampaignVariables(campaignVariables);
    }
  }

  @action
  async saveCampaignVariable(campaignVariable) {
    if (campaignVariable.saved === false) {
      return await this.createCampaignVariableAsync(campaignVariable);
    } else {
      return await this.updateCampaignVariableAsync(campaignVariable);
    }
  }

  @action
  async getCampaignVariablesAsync() {
    this.setIsLoading(true);
    const campaignVariables = await campaignVariablesService.getVariables(
      this.campaignId
    );

    this.setCampaignVariables(campaignVariables);
    this.setIsLoading(false);

    return campaignVariables;
  }

  @action
  async updateCampaignVariableAsync(campaignVariable) {
    const payload = {
      ...campaignVariable.attributes,
    };

    const newCampaignVariable = await campaignVariablesService.putVariable(
      this.campaignId,
      campaignVariable.id,
      payload
    );

    this.modifyCampaignVariableInCache(
      newCampaignVariable.id,
      newCampaignVariable
    );

    return newCampaignVariable;
  }

  @action
  async createCampaignVariableAsync(campaignVariable) {
    const payload = {
      ...campaignVariable.attributes,
    };

    const newCampaignVariable = await campaignVariablesService.postVariable(
      this.campaignId,
      payload
    );

    this.modifyCampaignVariableInCache(
      campaignVariable.id,
      newCampaignVariable
    );

    return newCampaignVariable;
  }

  @action
  async deleteCampaignVariableAsync(campaignVariable) {
    if (campaignVariable.saved !== false) {
      await campaignVariablesService.deleteVariable(
        this.campaignId,
        campaignVariable.id
      );
    }

    this.deleteCampaignVariable(campaignVariable.id);
  }
}

export default CampaignVariablesStore;
