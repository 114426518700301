import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withRouter } from "../../../../../utils/withRouter";
import { adminStoreProptypes } from "../../../../../proptypes/stores";

@inject("adminStore")
@withRouter
@observer
export class UserDetailsBreadcrumbs extends React.Component {
  static propTypes = {
    adminStore: adminStoreProptypes,
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  render() {
    const { adminStore, userId } = this.props;
    const { usersStore } = adminStore;

    const user = usersStore.findUser(userId);

    return user ? <span>{user.attributes.fullName}</span> : <></>;
  }
}

export default UserDetailsBreadcrumbs;
