import axios from "axios";
import * as axiosRequests from "./axiosRequests";
import { endpoint } from "../utils/endpoint";

export const SIGN_IN_ENDPOINT = endpoint("app/v1.0/users/sign_in");

export const USER_PASSWORD_ENDPOINT = endpoint("app/v1.0/users/password.json");

export const SIGN_IN_OAUTH_ENDPOINT = endpoint("api/v1.0/oauth/initiate_oauth");

export const USER_CHANGE_PASSWORD_ENDPOINT = endpoint(
  "app/v1.0/user/change_password.json"
);

export const USER_SET_PASSWORD_ENDPOINT = endpoint(
  "app/v1.0/user/set_first_password.json"
);

export const SIGN_OUT_ENDPOINT = endpoint("app/v1.0/users/sign_out");

export async function resetPassword(userId) {
  await axios.post(USER_PASSWORD_ENDPOINT, {
    email_or_uuid: userId,
  });
}

export async function changePassword(
  resetPasswordToken,
  newPassword,
  newPasswordConfirmation
) {
  const response = await axios.put(USER_PASSWORD_ENDPOINT, {
    user: {
      reset_password_token: resetPasswordToken,
      password: newPassword,
      password_confirmation: newPasswordConfirmation,
    },
  });

  return response.data;
}

export async function updateUserPassword(
  userId,
  currentPassword,
  newPassword,
  newPasswordConfirmation,
  campaignId
) {
  const response = await axios.put(USER_CHANGE_PASSWORD_ENDPOINT, {
    id: userId,
    campaign_id: campaignId,
    user: {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: newPasswordConfirmation,
    },
  });

  return response.data.data;
}

export async function signInUser(payload) {
  const response = await axios.post(
    SIGN_IN_ENDPOINT,
    {
      user: payload,
    },
    {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  return response.data;
}

export async function signOutUser() {
  const response = await axiosRequests.axiosDelete(SIGN_OUT_ENDPOINT);

  return response.data;
}

export async function signInOauth(payload) {
  const response = await axiosRequests.axiosPost(
    SIGN_IN_OAUTH_ENDPOINT,
    payload
  );

  return response.data;
}

export default {
  changePassword,
  resetPassword,
  signInUser,
  signOutUser,
  updateUserPassword,
};
