import { action, makeObservable, observable } from "mobx";
import { RootStore } from "./RootStore";

interface ILeadPayload {
  id?: number | string;
  address?: string;
  amount?: number;
  name?: string;
  parentLeadId?: number | string;
}

export class LeadSlideoutStore {
  rootStore;

  @observable addLeadSlideoutOpen = false;

  @observable campaignSelectionIsVisible = false;

  @observable defaultLeadSlideoutProps: ILeadPayload = {};

  constructor(rootStore: RootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
  }

  @action
  setAddLeadSlideoutOpen(addLeadSlideoutOpen: boolean) {
    this.addLeadSlideoutOpen = addLeadSlideoutOpen;
  }

  @action
  setCampaignSelectionIsVisible(campaignSelectionIsVisible: boolean) {
    this.campaignSelectionIsVisible = campaignSelectionIsVisible;
  }

  @action
  setDefaultLeadSlideoutProps(defaultLeadSlideoutProps: ILeadPayload) {
    const initialProps = { ...this.defaultLeadSlideoutProps };
    this.defaultLeadSlideoutProps = { ...initialProps, ...defaultLeadSlideoutProps };
  }

  @action
  setupSlideoutToCloneLead(leadPayload: ILeadPayload) {
    this.setDefaultLeadSlideoutProps({
      ...leadPayload,
    });
    this.setAddLeadSlideoutOpen(true);
    this.setCampaignSelectionIsVisible(true);
  }

  @action
  reset() {
    this.addLeadSlideoutOpen = false;
    this.campaignSelectionIsVisible = false;
    this.defaultLeadSlideoutProps = {};
  }
}

export default LeadSlideoutStore;
