import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Button, Text } from "@wingmate/toolkit";
import { Link } from "react-router-dom";
import { ReactComponent as WmLogoImage } from "../../assets/images/wingmate-icon.svg";

import "./ErrorPage.scss";

export interface IErrorPageProps {
  errorCode?: string;
}

export const ErrorPage: React.FC<IErrorPageProps> = ({ errorCode }) => {
  const { t } = useTranslation(["default", "common"]);

  return (
    <Flex className="ErrorPage" align="center" gap={8} vertical>
      <Text type="H3" weight="Bold">
        {t(`${errorCode ?? "general"}.title`)}
      </Text>
      <Text type="H4">{t(`${errorCode ?? "general"}.description`)}</Text>
      <WmLogoImage width="280px" height="200px" />
      <Link type="text" to="/">
        <Button type="filled" size="large">
          {t("home")}
        </Button>
      </Link>
      <Link to="https://support.wingmateapp.com/" target="_blank">
        <Text type="P">{t("support")}</Text>
      </Link>
    </Flex>
  );
};

export default ErrorPage;
