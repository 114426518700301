import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Breadcrumb, Text } from "@wingmate/toolkit";
import { Link } from "react-router-dom";
import "./RouteBreadcrumbs.scss";

interface IBreacrumbProps {
  path: string;
  breadcrumb: string;
}

interface IBreadcrumbRouteProps extends IBreacrumbProps {
  children?: IBreacrumbProps[];
}

interface IBreadcrumbOptionsProps {
  disableDefaults?: boolean;
}

interface IRouteBreadcrumbsProps {
  routes?: IBreadcrumbRouteProps[];
  options?: IBreadcrumbOptionsProps;
}

export const RouteBreadcrumbs: React.FC<IRouteBreadcrumbsProps> = ({
  options, routes,
}) => {
  const breadcrumbs = useBreadcrumbs(routes, options);

  const breadcrumbItems = breadcrumbs.map(({ breadcrumb, match }, index) => ({
    title: index === breadcrumbs.length - 1 ? (
      <Text type="H6" key={match.pathname}>{breadcrumb}</Text>
    ) : (
      <Text type="H6" key={match.pathname}>
        <Link to={match.pathname}>{breadcrumb}</Link>
      </Text>
    ),
  }));

  return (
    <Breadcrumb
      className="RouteBreadcrumbs"
      items={breadcrumbItems}
    />
  );
};

export default RouteBreadcrumbs;
