export function unique(array) {
  return [...new Set(array)];
}

export function haveSameValues(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  let sortedArr1 = [...arr1].sort();
  let sortedArr2 = [...arr2].sort();

  return sortedArr1.reduce((isEqual, currentElement, index) => {
    return isEqual && currentElement === sortedArr2[index];
  }, true);
}

export function uniqueOnId(array, idField) {
  let arrayCopy = array.concat();

  for (let i = 0; i < arrayCopy.length; ++i) {
    for (let j = i + 1; j < arrayCopy.length; ++j) {
      if (arrayCopy[i][idField] === arrayCopy[j][idField])
        arrayCopy.splice(j--, 1);
    }
  }

  return arrayCopy;
}

export function sortObjects(array, idField) {
  let arrayCopy = array.concat();

  arrayCopy = arrayCopy.sort((obj1, obj2) => {
    if (obj1[idField] > obj2[idField]) {
      return 1;
    } else {
      return -1;
    }
  });

  return arrayCopy;
}

export function sortObjectsByAttributesId(array, idField) {
  let arrayCopy = array.concat();

  arrayCopy = arrayCopy.sort((obj1, obj2) => {
    if (
      obj1.attributes[idField].toLowerCase() >
      obj2.attributes[idField].toLowerCase()
    ) {
      return 1;
    } else {
      return -1;
    }
  });

  return arrayCopy;
}

export const getAddedAndDeletedItems = (
  oldArray,
  newArray,
) => {
  const addedItems = [...newArray];
  const deletedItems = [];

  oldArray.forEach((oldItem) => {
    const index = addedItems.findIndex((item) => item === oldItem);

    if (index !== -1) {
      addedItems.splice(index, 1);
    } else {
      deletedItems.push(oldItem);
    }
  });

  return {
    addedItems,
    deletedItems,
  }
};
