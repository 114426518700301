import React from "react";
import { ProductFruits } from "react-product-fruits";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Navigate, Outlet } from "react-router-dom";
import { authStorePropTypes } from "../proptypes/stores";
import { withRouter } from "../utils/withRouter";

@withRouter
@inject("authStore")
@observer
export class AuthenticatedRoute extends React.Component {
  static propTypes = {
    authStore: authStorePropTypes,
    location: PropTypes.object,
  };

  render() {
    const { authStore, location } = this.props;
    const { context, isAuthenticated } = authStore;
    const { campaign, currentUser } = context;

    const productFruitsWorkspaceCode =
      process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE;
    const isProductionMode = process.env.NODE_ENV === "production";
    const showFirstTimeUse =
      productFruitsWorkspaceCode && currentUser && isProductionMode;

    const userNameArray = currentUser ? currentUser.fullName.split(" ") : [];

    return (
      <div data-testid="AuthenticatedRoute">
        {isAuthenticated ? (
          <>
            <Outlet />
            {showFirstTimeUse && (
              <ProductFruits
                workspaceCode={productFruitsWorkspaceCode}
                language="en"
                user={{
                  username: currentUser.uuid,
                  email: currentUser.email,
                  firstname: userNameArray.shift(),
                  lastname: userNameArray.join(" "),
                  signUpAt: currentUser.createdAt,
                  role: currentUser.type,
                  props: {
                    account: campaign.leadLabel,
                  },
                }}
              />
            )}
          </>
        ) : (
          <Navigate to="/sign_in" state={location} />
        )}
      </div>
    );
  }
}

export default AuthenticatedRoute;
