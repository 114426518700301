import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const CAMPAIGN_PRICE_SHEETS_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/price_sheets`);

export async function getPriceSheets(campaignId) {
  const response = await axiosService.axiosGet(
    CAMPAIGN_PRICE_SHEETS_ENDPOINT(campaignId)
  );

  return response.data;
}

export async function postPriceSheet(campaignId, payload) {
  const response = await axiosService.axiosPost(
    CAMPAIGN_PRICE_SHEETS_ENDPOINT(campaignId),
    {
      price_sheet: payload,
    }
  );

  return response.data;
}

export default {
  getPriceSheets,
};
