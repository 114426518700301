import axios from "axios";

import { endpoint } from "../utils/endpoint";

const CAMPAIGN_ENDPOINT = (id) => endpoint(`app/v1.0/campaigns/${id}`);

export const CAMPAIGN_TEAM_FEED_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/team_feed`);

export const CAMPAIGN_LOGO_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/logo_upload`);

export async function getCampaign(campaignId) {
  const response = await axios.get(CAMPAIGN_ENDPOINT(campaignId), {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return response.data.data;
}

export async function getLogoUploadUrl(campaignId, filename) {
  const params = {
    filename,
  };

  const response = await axios.get(CAMPAIGN_LOGO_ENDPOINT(campaignId), {
    params,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return response.data;
}

export async function updateCampaign(campaignId, payload) {
  const response = await axios.put(
    CAMPAIGN_ENDPOINT(campaignId),
    {
      campaign: payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  return response.data;
}

export async function getCampaignTeamFeed(campaignId) {
  const response = await axios.get(CAMPAIGN_TEAM_FEED_ENDPOINT(campaignId));
  return response.data;
}

export default {
  getCampaign,
  getLogoUploadUrl,
  getCampaignTeamFeed,
  updateCampaign,
};
