import piexif from "piexifjs";

export const dataURLtoFile = (dataUrl: string, filename: string): File => {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1] || "image/jpeg";
  const bstr = atob(arr[1]);
  const u8arr = new Uint8Array(bstr.length);

  for (let i = 0; i < bstr.length; i += 1) {
    u8arr[i] = bstr.charCodeAt(i);
  }

  return new File([u8arr], filename, { type: mime });
};

export const sanitizeExifData = async (file: File): Promise<File> => (
  new Promise((resolve, reject) => {
    const fileType = file.type;

    if (fileType === "image/jpeg" || fileType === "image/jpg") {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryStr = (event.target?.result as string) || "";

        const strippedData = piexif.remove(binaryStr);

        const sanitizedFile = dataURLtoFile(strippedData, file.name);
        resolve(sanitizedFile);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    } else {
      resolve(file);
    }
  }));
