import React from "react";
import PropTypes from "prop-types";
import { FlexCol, Text } from "wm-ui-toolkit";
import { XCircleIcon } from "wm-graphics";
import { withTranslations } from "../../../utils/withTranslations";
import "./Error.scss";

@withTranslations
export class Error extends React.Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    t: PropTypes.func,
  };

  render() {
    const { errorMessage, t } = this.props;

    return (
      <FlexCol
        className="Oauth__Error"
        alignItems="center"
        gap="L"
        justifyContent="space-between"
      >
        <XCircleIcon height={100} width={100} data-testid="XCircleIcon" />
        <Text type="T">{t("oops")}</Text>
        <Text className="Error__message" type="H3">
          {errorMessage}
        </Text>
      </FlexCol>
    );
  }
}
