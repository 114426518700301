export function getErrorMessage(error, defaultErrorMessage) {
  let errorMessage = null;

  if (error?.response?.data?.errors) {
    errorMessage = Object.values(error.response.data.errors).join(" ");
  }

  return errorMessage || defaultErrorMessage;
}

export function getSerializedErrorMessage(error, defaultErrorMessage) {
  let errorMessage = null;

  const errors = error?.response?.data?.errors;

  if (errors && Array.isArray(errors) && errors.length > 0) {
    errorMessage = errors.reduce((message, error) => message += ` ${error?.attributes?.message || ""}`, "");
    errorMessage = errorMessage.trim();
  }

  return errorMessage || defaultErrorMessage;
}

export function parseErrorMessage(err, defaultErrorMessage) {
  const response = err.response || {};
  const data = response.data || {};

  if (data.message) {
    return data.message;
  } else {
    return defaultErrorMessage;
  }
}
