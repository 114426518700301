import { action, makeAutoObservable, observable } from "mobx";
import * as targetsService from "../requests/campaigns/targets";

export class TargetsStore {
  @observable targets = [];
  @observable isLoading = true;
  @observable isSaving = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setTargets(targets) {
    this.targets = targets;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setIsSaving(isSaving) {
    this.isSaving = isSaving;
  }

  findTarget(targetId) {
    const target = this.targets.find((target) => target.id === targetId);
    return target;
  }

  @action
  modifyTargetInCache(targetId, newTarget) {
    const targets = [...this.targets];

    const targetIndex = targets.findIndex((target) => target.id === targetId);

    if (targetIndex !== -1) {
      targets[targetIndex] = newTarget;

      this.setTargets(targets);
    }
  }

  @action
  async createTargetAsync(payload) {
    this.setIsLoading(true);
    const target = await targetsService.postTarget(
      this.rootStore.campaignId,
      payload
    );

    const targets = [...this.targets, target];

    this.setTargets(targets);
    this.setIsLoading(false);
  }

  @action
  async getTargetsAsync() {
    this.setIsLoading(true);
    const targets = await targetsService.getTargets(this.rootStore.campaignId);

    this.setTargets(targets);
    this.setIsLoading(false);

    return targets;
  }

  @action
  async updateTargetAsync(targetId, payload) {
    this.setIsLoading(true);
    const target = await targetsService.updateTarget(
      this.rootStore.campaignId,
      targetId,
      payload
    );

    this.modifyTargetInCache(targetId, target);

    this.setIsLoading(false);

    return target;
  }

  @action
  async deleteTargetAsync(targetId) {
    this.setIsLoading(true);
    const target = await targetsService.deleteTarget(
      this.rootStore.campaignId,
      targetId
    );

    await this.getTargetsAsync();

    this.setIsLoading(false);

    return target;
  }
}

export default TargetsStore;
