import React from "react";
import classNames from "classnames";
import { TargetDarkIcon, TargetReachedIcon } from "wm-graphics";
import { Text } from "wm-ui-toolkit";
import PropTypes from "prop-types";

import "./ScoreCardColumn.scss";

export class ScoreCardColumn extends React.Component {
  static propTypes = {
    performance: PropTypes.object,
    progressHeight: PropTypes.number,
    targetHeight: PropTypes.number,
  };

  renderIcon = () => {
    const { performance } = this.props;
    const { complete } = performance.attributes;

    if (!complete) {
      return <TargetDarkIcon data-testid="TargetDark" />;
    } else {
      return (
        <>
          <div className="Column__TargetLine"></div>
          <TargetReachedIcon data-testid="TargetReached" />
        </>
      );
    }
  };

  columnLabel = () => {
    const { performance } = this.props;
    const { scTask } = performance.attributes;

    return scTask.attributes.name;
  };

  render() {
    const { performance, progressHeight, targetHeight } = this.props;

    const { complete, count, target } = performance.attributes;

    const columnClass = classNames("ScoreCardChart__Column", {
      "Column--complete": complete,
    });

    const isIconVisible = target !== 0;

    return (
      <div
        className={columnClass}
        data-testid="ScoreCardColumn"
        style={{ height: `${targetHeight}px` }}
      >
        <div className="ScoreCardChart__Target">
          {
            isIconVisible && (
              <div className="ScoreCardChart__Icon">{this.renderIcon()}</div>
            )
          }
          <div
            className="ScoreCardChart__Progress"
            style={{ height: `${progressHeight}px` }}
          ></div>
        </div>
        <div className="ScoreCardChart__ColumnCount">{`${count}/${target}`}</div>
        <div className="ScoreCardChart__ColumnTitle">
          <div className="ScoreCardChart__Tick" />
          <div className="ScoreCardChart__TitleText">
            <Text type="S" weight="Light">
              {this.columnLabel()}
            </Text>
          </div>
        </div>
      </div>
    );
  }
}

export default ScoreCardColumn;
