import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  runInAction,
} from "mobx";
import { BaseStore } from "./BaseStore";
import * as funnelService from "../requests/funnel";
import * as arrayUtils from "../utils/arrays";
import * as leadsService from "../requests/leads";
import * as bulkLeadsService from "../requests/leads/bulk";
import * as leadsCustomFieldsService from "../requests/leads/customFields";

export const LEAD_DETAIL_TYPE = "leadDetail";

export class LeadsStore extends BaseStore {
  rootStore;

  @observable leads = observable.array();

  @observable page = { current: 1, more: false, next: null };

  @observable isInitialized = false;

  @observable isLoading = false;

  constructor(rootStore) {
    super();

    this.rootStore = rootStore;

    makeObservable(this);
  }

  @override
  clear() {
    this.leads = observable.array();
    this.isInitialized = false;
    this.isLoading = false;
  }

  isEmpty() {
    return this.leads.length === 0;
  }

  @computed get deletedLeads() {
    return this.leads
      .filter((lead) => lead.attributes.deletedAt !== null)
      .sort((a, b) => {
        const aDate = new Date(a.attributes.deletedAt);
        const bDate = new Date(b.attributes.deletedAt);

        return bDate - aDate;
      });
  }

  getLead(leadId) {
    return this.leads.find((lead) => lead.id === leadId);
  }

  @action
  setLeads(leads) {
    this.leads = leads;
  }

  @action
  setPage(page) {
    this.page = page;
  }

  @action
  setIsInitialized(isInitialized) {
    runInAction(() => {
      this.isInitialized = isInitialized;
    });
  }

  @action
  setIsLoading(isLoading) {
    runInAction(() => {
      this.isLoading = isLoading;
    });
  }

  @action
  setCachedLeads(newLeads) {
    const allLeads = [...newLeads, ...this.leads];

    const allLeadsUnique = arrayUtils.uniqueOnId(allLeads, "id");

    this.setLeads(allLeadsUnique);
  }

  @action
  modifyLeadInCache(leadId, newLead) {
    const { funnelStore, listLeadsStore } = this.rootStore;

    const leads = [...this.leads];

    const leadIndex = leads.findIndex((lead) => lead.id === leadId);

    const oldLead = leads[leadIndex];

    if (leadIndex !== -1) {
      const statusWasChanged =
        oldLead.attributes.status !== newLead.attributes.status;
      if (statusWasChanged) {
        funnelStore.changeStatusOfLead(
          newLead,
          oldLead.attributes.status,
          newLead.attributes.status
        );
      }

      leads[leadIndex] = newLead;

      this.setLeads(leads);

      listLeadsStore.setLead(leads[leadIndex]);
    }
  }

  @action
  modifyFieldOnLead(leadId, field, value) {
    const leads = [...this.leads];

    const leadIndex = leads.findIndex((lead) => lead.id === leadId);

    if (leadIndex !== -1) {
      leads[leadIndex].attributes[field] = value;

      this.setLeads(leads);
    }
  }

  @action
  async getLeads(filters = {}, additionalOptions = {}) {
    this.setIsLoading(true);
    const response = await leadsService.getLeads(filters, additionalOptions);

    const { data: leads, meta } = response;

    this.setCachedLeads(leads);
    this.setPage(meta.page);

    this.setIsLoading(false);
    this.setIsInitialized(true);

    return response;
  }

  @action
  async getMoreLeads(filters = {}) {
    const payload = {
      ...filters,
      page: this.page?.next,
    };
    
    const response = await leadsService.getLeads(payload);
    const { data, meta } = response;

    this.setPage(meta.page);

    return data;
  }

  @action
  async retrieveLeadsAsync(filters = {}) {
    const response = await leadsService.getLeads(filters);

    const leads = response.data;

    return leads;
  }

  @action
  async updateLead(leadId, payload) {
    const response = await leadsService.putLead(leadId, payload);

    const lead = response.data;

    this.modifyLeadInCache(leadId, lead);

    return lead;
  }

  @action
  updateLeadCustomFieldsAsync = async (leadId, customFieldId, newValue) => {
    const response = await leadsCustomFieldsService.putLeadCustomFields(
      leadId,
      customFieldId,
      newValue
    );
    this.modifyLeadInCache(leadId, response.data);
    return response.data;
  };

  getLeadDetailById = async (leadId) => {
    const lead = this.leads.find((lead) => lead.id === leadId);

    if (lead) {
      if (lead.type !== LEAD_DETAIL_TYPE) {
        const leadDetail = await this.retrieveLeadDetailAsync(leadId);
        return leadDetail;
      }
      return lead;
    } else {
      const leadDetail = await this.retrieveLeadDetailAsync(leadId);
      return leadDetail;
    }
  };

  @action
  async retrieveLeadDetailAsync(leadId) {
    const leadDetail = await leadsService.getLeadDetails(leadId);

    runInAction(() => {
      const leadList = [...this.leads];

      const leadIndex = leadList.findIndex((lead) => lead.id === leadId);

      if (leadIndex === -1) {
        leadList.push(leadDetail);
      } else {
        leadList[leadIndex] = leadDetail;
      }

      this.setLeads(leadList);
    });
    this.setIsLoading(false);

    return leadDetail;
  }

  @action
  async massUpdateLeadOwners(newOwnerIds) {
    const { bannerStore, filtersStore, mapStore, viewStore } = this.rootStore;

    if (newOwnerIds === null) {
      return false;
    }

    const inMapView = viewStore.view.id === "map";

    const searchParams = {
      ...filtersStore.filters,
      ...(inMapView && mapStore.mapFilters),
    };

    const payload = {
      owner_ids: newOwnerIds,
    };

    try {
      const response = await bulkLeadsService.putBulkLeads(
        searchParams,
        payload
      );

      bannerStore.addBanner("green", "Reassignment started", response.message);
    } catch (err) {
      console.log(err);
      bannerStore.addBanner(
        "red",
        "Reassignment did not start",
        Object.values(err.response.data.errors).join(" "),
        [],
        false
      );
    }

    return true;
  }

  @action
  async getFunnel(filters = {}, additionalOptions = {}) {
    const response = await funnelService.getFunnel(filters, additionalOptions);

    const { data } = response;
    const { attributes } = data;
    let leads = [];

    Object.keys(attributes).forEach((status) => {
      leads = leads.concat(attributes[status].data);
    });

    this.setCachedLeads(leads);
    return response;
  }
}

export default LeadsStore;
