import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const COMPANY_TRANSACTIONS_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/company_transactions`);

export const COMPANY_TRANSACTION_ENDPOINT = (campaignId, id, format) =>
  endpoint(
    `app/v1.0/campaigns/${campaignId}/company_transactions/${id}.${format}`
  );

const NEW_COMPANY_TRANSACTION_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/company_transactions/new`);

export async function getCompanyTransactions(campaignId) {
  const response = await axiosService.axiosGet(
    COMPANY_TRANSACTIONS_ENDPOINT(campaignId)
  );
  return response.data;
}

export async function getCompanyTransaction(campaignId, id) {
  const response = await axiosService.axiosGet(
    COMPANY_TRANSACTION_ENDPOINT(campaignId, id, "json")
  );

  return response.data;
}

export async function postCompanyTransaction(campaignId, payload) {
  const response = await axiosService.axiosPost(
    COMPANY_TRANSACTIONS_ENDPOINT(campaignId),
    { company_transaction: payload }
  );
  return response.data;
}

export async function newCompanyTransaction(campaignId) {
  const response = await axiosService.axiosGet(
    NEW_COMPANY_TRANSACTION_ENDPOINT(campaignId)
  );
  return response.data;
}

export default {
  getCompanyTransactions,
  postCompanyTransaction,
  newCompanyTransaction,
};
