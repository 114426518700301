import { action, computed, makeAutoObservable, observable } from "mobx";
import { CAMPAIGN_ACTIVITIES_ENDPOINT } from "../requests/campaigns/activities";
import { buildParams } from "../utils/commons";
import * as activitiesService from "../requests/campaigns/activities";

export class CampaignActivityStore {
  @observable activities = [];

  @observable isLoading = false;

  @observable count = 0;

  @observable page = null;

  @observable filters = {};

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @computed get campaignId() {
    return this.rootStore.context.campaign.id;
  }

  @computed get campaignActivitiesExportUrl() {
    return CAMPAIGN_ACTIVITIES_ENDPOINT(
      this.campaignId,
      "csv",
      this.uriEncodedFilters
    );
  }

  @computed get uriEncodedFilters() {
    const urlString = buildParams({ ...this.filters });

    return urlString;
  }

  setBrowserUrl() {
    const newUrl = `${window.location.pathname}?${this.uriEncodedFilters}`;
    window.history.pushState("", null, newUrl);
  }

  @action
  setActivities(activities) {
    this.activities = activities;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setPage(page) {
    this.page = page;
  }

  @action
  resetPage() {
    this.page = null;
  }

  @action
  clearActivities() {
    this.activities = [];
  }

  @action
  setCount(count) {
    this.count = count;
  }

  @action
  setFilters(newFilters) {
    this.filters = {
      ...this.filters,
      ...newFilters,
    };

    this.setBrowserUrl();
    this.resetPage();
    this.clearActivities();
  }

  @action
  removeFilter(id) {
    delete this.filters[id];

    this.setBrowserUrl();
    this.resetPage();
    this.clearActivities();
  }

  @action
  async getNextPage() {
    this.setIsLoading(true);
    const payload = {
      page: this.page.next
    };

    const response = await activitiesService.getActivities(
      this.campaignId,
      payload,
      this.uriEncodedFilters,
    );
    const allActivities = this.activities.concat(response.data);

    this.setActivities(allActivities);
    this.setPage(response.meta.page);
    this.setIsLoading(false);
  }

  @action
  async retrieveActivitiesForCampaignAsync() {
    this.setIsLoading(true);

    const response = await activitiesService.getActivities(
      this.campaignId,
      {},
      this.uriEncodedFilters,
    );

    this.setActivities(response.data);
    this.setPage(response.meta.page);
    this.setCount(response.meta.count);

    this.setIsLoading(false);
  }
}

export default CampaignActivityStore;
