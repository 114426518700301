import { ILeadAttributes } from "../../types/ILead";
import { camelCase } from "../../utils/commons";
import { awsServicesEndpoint } from "../../utils/endpoint";
import { axiosPost } from "../axiosRequests";

export const RESULTS_PAGINATION = 21;

const globalSearchEndpoint = (campaignId: string) => awsServicesEndpoint("search-service", `campaigns/${campaignId}/leads`);

interface ISearchLeadsResponse {
  leads: ILeadAttributes[];
  totalHits: number;
}

export const searchLeads = async (
  token: string,
  campaignId: string,
  searchString: string,
  page: number,
  additionalOptions: object = {},
): Promise<ISearchLeadsResponse> => {
  const payload = {
    searchString,
    size: RESULTS_PAGINATION,
    page,
  };

  const response = await axiosPost(
    globalSearchEndpoint(campaignId),
    payload,
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    additionalOptions,
  );

  const data = camelCase(response.data) as ISearchLeadsResponse;

  return {
    leads: data.leads,
    totalHits: data.totalHits,
  };
};

export default {
  searchLeads,
};
