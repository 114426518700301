import { action, computed, makeObservable, observable, override } from "mobx";
import { BaseStore } from "./BaseStore";
import * as leadsService from "../requests/leads";

const INITIAL_LOAD_RADIUS = 40.96;

const INITIAL_STATUS_COUNT = {
  fresh: 0,
  received_by_sales: 0,
  work_in_progress: 0,
  pending_decision: 0,
  contract_signed: 0,
  completed: 0,
  failed: 0,
  hibernated: 0,
};

export class MapStore extends BaseStore {
  rootStore;

  @observable leads;

  @observable activePipelineAmount;

  @observable activePipelineCount;

  @observable activePipelineTotalAmount;

  @observable activePipelineTotalCount;

  @observable totalPipelineAmount;

  @observable totalPipelineCount;

  @observable totalPipelineTotalAmount;

  @observable totalPipelineTotalCount;

  @observable countByStatus;

  @observable totalCountByStatus;

  @observable isLoading;

  @observable clickedLeads;

  @observable addLeadToggle;

  @observable excludedLeadCount;

  @observable latitude;

  @observable longitude;

  @observable radius;

  @observable mapSelectedAddress;

  @observable selectedAddress;

  @observable selectedLatitude;

  @observable selectedLongitude;

  @observable selectedLead;

  @observable territoriesVisible;

  constructor(rootStore) {
    super();

    makeObservable(this);
    this.rootStore = rootStore;

    this.initialize();
  }

  @override
  clear() {
    this.initialize();
  }

  @computed get leadStats() {
    return {
      activePipelineAmount: this.activePipelineAmount,
      activePipelineCount: this.activePipelineCount,
      activePipelineTotalAmount: this.activePipelineTotalAmount,
      activePipelineTotalCount: this.activePipelineTotalCount,
      totalPipelineAmount: this.totalPipelineAmount,
      totalPipelineCount: this.totalPipelineCount,
      totalPipelineTotalAmount: this.totalPipelineTotalAmount,
      totalPipelineTotalCount: this.totalPipelineTotalCount,
    };
  }

  @computed get mapFilters() {
    return {
      latitude: this.latitude,
      longitude: this.longitude,
      radius: this.radius,
      map: true,
    };
  }

  @action
  initialize() {
    this.leads = [];
    this.activePipelineAmount = 0;
    this.activePipelineCount = 0;
    this.activePipelineTotalAmount = 0;
    this.activePipelineTotalCount = 0;
    this.totalPipelineAmount = 0;
    this.totalPipelineCount = 0;
    this.totalPipelineTotalAmount = 0;
    this.totalPipelineTotalCount = 0;
    this.countByStatus = INITIAL_STATUS_COUNT;
    this.totalCountByStatus = INITIAL_STATUS_COUNT;
    this.clickedLeads = [];
    this.addLeadToggle = false;
    this.excludedLeadCount = 0;
    this.latitude = null;
    this.longitude = null;
    this.radius = INITIAL_LOAD_RADIUS;
    this.mapSelectedAddress = "";
    this.selectedAddress = "";
    this.selectedLatitude = null;
    this.selectedLongitude = null;
    this.selectedLead = null;
    this.territoriesVisible = true;
  }

  @action
  setActivePipelineAmount(activePipelineAmount){
    this.activePipelineAmount = activePipelineAmount;
  }

  @action
  setActivePipelineCount(activePipelineCount){
    this.activePipelineCount = activePipelineCount;
  }

  @action
  setActivePipelineTotalAmount(activePipelineTotalAmount) {
    this.activePipelineTotalAmount = activePipelineTotalAmount;
  }

  @action
  setTerritoriesVisible(territoriesVisible) {
    this.territoriesVisible = territoriesVisible;
  }

  @action
  setMapSelectedAddress(address) {
    this.mapSelectedAddress = address;
  }

  @action
  setActivePipelineTotalCount(activePipelineTotalCount){
    this.activePipelineTotalCount = activePipelineTotalCount;
  }

  @action
  setTotalPipelineAmount(totalPipelineAmount){
    this.totalPipelineAmount = totalPipelineAmount;
  }

  @action
  setTotalPipelineCount(totalPipelineCount){
    this.totalPipelineCount = totalPipelineCount;
  }

  @action
  setTotalPipelineTotalAmount(totalPipelineTotalAmount){
    this.totalPipelineTotalAmount = totalPipelineTotalAmount;
  }

  @action
  setTotalPipelineTotalCount(totalPipelineTotalCount){
    this.totalPipelineTotalCount = totalPipelineTotalCount;
  }

  @action
  setSelectedAddress(address) {
    this.selectedAddress = address;
  }

  @action
  setSelectedLatitude(latitude) {
    this.selectedLatitude = latitude;
  }

  @action
  setSelectedLongitude(longitude) {
    this.selectedLongitude = longitude;
  }

  @action
  setSelectedLead(lead) {
    this.selectedLead = lead;
  }
  
  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setLeads(leads) {
    this.leads = leads;
  }

  @action
  setTotalCountByStatus(totalCountByStatus) {
    this.totalCountByStatus = totalCountByStatus;
  }

  @action
  setCountByStatus(countByStatus) {
    this.countByStatus = countByStatus;
  }

  @action
  setAddLeadToggle(toggle) {
    this.addLeadToggle = toggle;
  }

  @action
  setExcludedLeadCount(count) {
    this.excludedLeadCount = count;
  }

  @action
  setLatitude(latitude) {
    this.latitude = latitude;
  }

  @action
  setLongitude(longitude) {
    this.longitude = longitude;
  }

  @action
  setRadius(radius) {
    this.radius = radius;
  }

  @action
  pushLeads(leads) {
    let newLeadsCount = 0;
    let newLeads = [];
    const currLeads = [...this.leads];
    leads.forEach((lead) => {
      const leadIndex = currLeads.findIndex(
        (currLead) => currLead.id === lead.id
      );

      if (leadIndex === -1) {
        newLeads.push(lead);
        newLeadsCount += 1;
      }
    });
    this.leads = currLeads.concat(newLeads);
    this.count += newLeadsCount;
  }

  @action
  setLead(leadDetails) {
    const leadIndex = this.findLeadIndex(leadDetails.id);
    if (leadIndex !== -1) {
      const lead = this.leads[leadIndex];
      const attributes = {
        ...lead.attributes,
        ...leadDetails.attributes,
      };
      lead.attributes = attributes;
      this.leads[leadIndex] = lead;
    }
  }

  @action
  findLeadIndex(leadId) {
    return this.leads.findIndex((lead) => lead.id === leadId);
  }

  @action
  async findLead(leadId) {
    if (this.clickedLeads.includes(leadId)) {
      return this.leads.find((lead) => lead.id === leadId);
    }
    await this.getLeadDetails(leadId);
    this.clickedLeads.push(leadId);
    return this.leads.find((lead) => lead.id === leadId);
  }

  @action getLeadDetails = async (leadId) => {
    const lead = await leadsService.getLeadDetails(leadId);
    this.setLead(lead);
  };

  @action
  async retrieveFilteredMapLeads(filters = {}) {
    this.setIsLoading(true);
    const { location } = this.rootStore;
    const { latitude, longitude } = location;
    if (!this.latitude && !this.longitude) {
      this.latitude = latitude;
      this.longitude = longitude;
    }

    const params = {
      ...filters,
      latitude: this.latitude,
      longitude: this.longitude,
      radius: this.radius,
      map: true,
    };

    try {
      this.cancelPreviousRequests();

      const response = await leadsService.getLeads(params, { signal: this.abortController.signal });

      const { data, meta } = response;
      const {
        activePipelineAmount,
        activePipelineCount,
        activePipelineTotalAmount,
        activePipelineTotalCount,
        countByStatus,
        missingAddressLeads,
        totalCountByStatus,
        totalPipelineAmount,
        totalPipelineCount,
        totalPipelineTotalAmount,
        totalPipelineTotalCount,
      } = meta;

      this.setLeads(data);
      this.setCountByStatus(countByStatus);
      this.setTotalCountByStatus(totalCountByStatus);
      this.setExcludedLeadCount(missingAddressLeads);
      this.setActivePipelineAmount(activePipelineAmount);
      this.setActivePipelineCount(activePipelineCount);
      this.setActivePipelineTotalAmount(activePipelineTotalAmount);
      this.setActivePipelineTotalCount(activePipelineTotalCount);
      this.setTotalPipelineAmount(totalPipelineAmount);
      this.setTotalPipelineCount(totalPipelineCount);
      this.setTotalPipelineTotalAmount(totalPipelineTotalAmount);
      this.setTotalPipelineTotalCount(totalPipelineTotalCount);
    } catch (err) {
      console.log(err);
    }

    this.setIsLoading(false);
  }

  @action modifyLeadInCache(leadId, payload) {
    const leads = [...this.leads];

    const leadIndex = leads.findIndex((lead) => lead.id === leadId);

    if (leadIndex !== -1) {
      leads[leadIndex].attributes = {
        ...leads[leadIndex].attributes,
        ...payload,
      };

      this.setLeads(leads);
    }
  }

  @action
  async updateLeadLatLng(leadId, latitude, longitude) {
    const { leadsStore } = this.rootStore;

    await leadsStore.updateLead(leadId, {
      latitude,
      longitude,
    });

    this.modifyLeadInCache(leadId, {
      lat: latitude,
      lng: longitude,
    });
  }

  @action
  async retrieveMapLeadsAsync(latitude, longitude, radius) {
    this.setIsLoading(true);

    try {
      const { filters } = this.rootStore.filtersStore;

      const params = {
        ...filters,
        latitude: latitude,
        longitude: longitude,
        radius: radius,
        map: true,
      };

      const response = await leadsService.getLeads(params);

      const { data, meta } = response;
      const {
        activePipelineAmount,
        activePipelineCount,
        activePipelineTotalAmount,
        activePipelineTotalCount,
        countByStatus,
        missingAddressLeads,
        totalCountByStatus,
        totalPipelineAmount,
        totalPipelineCount,
        totalPipelineTotalAmount,
        totalPipelineTotalCount,
      } = meta;

      this.setLeads(data);
      this.setExcludedLeadCount(missingAddressLeads);
      this.setCountByStatus(countByStatus);
      this.setTotalCountByStatus(totalCountByStatus);
      this.setLatitude(latitude);
      this.setLongitude(longitude);
      this.setRadius(radius);
      this.setActivePipelineAmount(activePipelineAmount);
      this.setActivePipelineCount(activePipelineCount);
      this.setActivePipelineTotalAmount(activePipelineTotalAmount);
      this.setActivePipelineTotalCount(activePipelineTotalCount);
      this.setTotalPipelineAmount(totalPipelineAmount);
      this.setTotalPipelineCount(totalPipelineCount);
      this.setTotalPipelineTotalAmount(totalPipelineTotalAmount);
      this.setTotalPipelineTotalCount(totalPipelineTotalCount);
      this.setIsLoading(false);
    } catch (err) {
      this.setIsLoading(false);
    }
  }
}
export default MapStore;
