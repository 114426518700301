import React from "react";
import { Outlet } from "react-router-dom";
import { AddLeadSlideout } from "../layouts/AddLeadSlideout/AddLeadSlideout";
import { withRouter } from "../utils/withRouter";
import { AppContext } from "../context";
import { TranslationProvider } from "../providers/TranslationProvider";
import i18next from "../pages/Dashboard/translations/i18n";

@withRouter
export class BaseRoute extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <TranslationProvider i18n={i18next}>
        <div className="BaseRoute" data-testid="BaseRoute">
          <AddLeadSlideout />
          <Outlet />
        </div>
      </TranslationProvider>
    );
  }
}

export default BaseRoute;
