import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const CAMPAIGN_TASKS_ENDPOINT = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/sc_tasks.json`);

const CAMPAIGN_TASK_ENDPOINT = (campaignId, taskId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/sc_tasks/${taskId}`);

const CAMPAIGN_TASK_ENDPOINT_POST = (campaignId) =>
  endpoint(`app/v1.0/campaigns/${campaignId}/sc_tasks`);

export async function getTasks(campaignId) {
  try {
    const response = await axiosService.axiosGet(
      CAMPAIGN_TASKS_ENDPOINT(campaignId)
    );

    return response.data.data;
  } catch (err) {
    return {};
  }
}

export async function updateTask(campaignId, taskId, payload) {
  const response = await axiosService.axiosPut(
    CAMPAIGN_TASK_ENDPOINT(campaignId, taskId),
    payload
  );

  return response.data;
}

export async function deleteTask(campaignId, taskId) {
  const response = await axiosService.axiosDelete(
    CAMPAIGN_TASK_ENDPOINT(campaignId, taskId)
  );

  return response.data;
}

export async function postTask(campaignId, payload) {
  const response = await axiosService.axiosPost(
    CAMPAIGN_TASK_ENDPOINT_POST(campaignId),
    payload
  );

  return response.data;
}

export default {
  getTasks,
};
