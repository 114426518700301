import React, { ReactNode } from "react";
import { Avatar, Tooltip } from "@wingmate/toolkit";
import { useTranslation } from "react-i18next";
import { ILeadCreator } from "../../types/ILead";
import { IUserAttributes } from "../../types/IUser";
import { ConditionalWrapper } from "../../utils/ConditionalWrapper";

import "./UserAvatar.scss";

export interface IUserAvatarProps {
  showTooltip?: boolean;
  size?: "small" | "medium" | "large";
  tooltipMessage?: string;
  user: ILeadCreator | Pick<IUserAttributes, "disabled" | "fullName" | "profileImageUrl">;
}

export const UserAvatar: React.FC<IUserAvatarProps> = ({
  showTooltip = true,
  size = "medium",
  tooltipMessage,
  user,
}) => {
  const { t } = useTranslation(["common"]);

  const { disabled, fullName, profileImageUrl } = user;

  const getTooltipMessage = () => {
    if (!tooltipMessage) {
      if (user) {
        return disabled ? t("user.disabled", { name: fullName }) : fullName;
      }
      return null;
    }
    return tooltipMessage;
  };

  const renderTooltip = (children: ReactNode) => (
    <Tooltip title={getTooltipMessage()}>
      {children}
    </Tooltip>
  );

  return (
    <ConditionalWrapper
      condition={showTooltip}
      wrapper={renderTooltip}
    >
      <Avatar
        className="UserAvatar"
        data-testid="UserAvatar"
        src={profileImageUrl}
        name={fullName}
        disabled={disabled}
        size={size}
      />
    </ConditionalWrapper>
  );
};
