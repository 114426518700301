import { endpoint } from "../../utils/endpoint";

const LEAD_CUSTOM_FIELDS_ENDPOINT = (leadId, customFieldId) =>
  endpoint(`app/v1.0/leads/${leadId}/custom_fields/${customFieldId}.json`);

import * as axiosService from "../axiosRequests";

export async function putLeadCustomFields(leadId, customFieldId, value) {
  const payload = {
    value,
  };

  const response = await axiosService.axiosPut(
    LEAD_CUSTOM_FIELDS_ENDPOINT(leadId, customFieldId),
    {
      custom_field: payload,
    }
  );

  return response;
}

export default {
  putLeadCustomFields,
};
