import React from "react";
import { useTranslation } from "react-i18next";

export function withTranslations(Child) {
  return function WithTranslationDecorator({ ...otherProps }) {
    const { t } = useTranslation(["default", "common"]);

    return <Child {...otherProps} t={t} />;
  };
}
