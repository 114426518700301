import { action, computed, makeObservable, observable, override } from "mobx";
import { BaseStore } from "./BaseStore";

const STATUSES = [
  "fresh",
  "received_by_sales",
  "work_in_progress",
  "pending_decision",
  "contract_signed",
  "completed",
  "failed",
  "hibernated",
];

const EMPTY_FUNNEL_COLUMN = {
  amount: 0,
  count: 0,
  page: {},
  leads: [],
};

export class FunnelStore extends BaseStore {
  rootStore;

  @observable funnelScroll;

  @observable funnel;

  @observable activePipelineAmount;

  @observable activePipelineCount;

  @observable activePipelineTotalAmount;

  @observable activePipelineTotalCount;

  @observable totalPipelineAmount;

  @observable totalPipelineCount;

  @observable totalPipelineTotalAmount;

  @observable totalPipelineTotalCount;

  @observable isInitialized = false;

  @observable noLeadsPresent = false;

  constructor(rootStore) {
    super();

    makeObservable(this);
    this.rootStore = rootStore;
    this.activePipelineAmount = 0;
    this.activePipelineCount = 0;
    this.activePipelineTotalAmount = 0;
    this.activePipelineTotalCount = 0;
    this.totalPipelineAmount = 0;
    this.totalPipelineCount = 0;
    this.totalPipelineTotalAmount = 0;
    this.totalPipelineTotalCount = 0;
    this.funnel = this.initializeFunnel();
    this.funnelScroll = {};
  }

  @override
  clear() {
    const emptyFunnel = {};
    STATUSES.map((status) => (emptyFunnel[status] = EMPTY_FUNNEL_COLUMN));

    this.setFunnel(emptyFunnel);
    this.setActivePipelineAmount(0);
    this.setActivePipelineCount(0);
    this.setActivePipelineTotalAmount(0);
    this.setActivePipelineTotalCount(0);
    this.setTotalPipelineAmount(0);
    this.setTotalPipelineCount(0);
    this.setTotalPipelineTotalAmount(0);
    this.setTotalPipelineTotalCount(0);
    this.setIsInitialized(false);
  }

  @computed get leadStats() {
    return {
      activePipelineAmount: this.activePipelineAmount,
      activePipelineCount: this.activePipelineCount,
      activePipelineTotalAmount: this.activePipelineTotalAmount,
      activePipelineTotalCount: this.activePipelineTotalCount,
      totalPipelineAmount: this.totalPipelineAmount,
      totalPipelineCount: this.totalPipelineCount,
      totalPipelineTotalAmount: this.totalPipelineTotalAmount,
      totalPipelineTotalCount: this.totalPipelineTotalCount,
    };
  }

  initializeFunnel = () =>
    STATUSES.reduce((map, key) => {
      map[key] = {
        leads: [],
        amount: null,
        count: 0,
        page: {},
      };
      return map;
    }, {});

  @action
  setIsInitialized(initialized) {
    this.isInitialized = initialized;
  }

  @action
  setFunnelColumn(status, newFunnelColumn) {
    this.funnel[status] = newFunnelColumn;
  }

  @action
  setFunnel(newFunnel) {
    this.funnel = newFunnel;
  }

  @action
  setActivePipelineAmount(activePipelineAmount){
    this.activePipelineAmount = activePipelineAmount;
  }

  @action
  setActivePipelineCount(activePipelineCount){
    this.activePipelineCount = activePipelineCount;
  }

  @action
  setActivePipelineTotalAmount(activePipelineTotalAmount){
    this.activePipelineTotalAmount = activePipelineTotalAmount;
  }

  @action
  setActivePipelineTotalCount(activePipelineTotalCount){
    this.activePipelineTotalCount = activePipelineTotalCount;
  }

  @action
  setTotalPipelineAmount(totalPipelineAmount){
    this.totalPipelineAmount = totalPipelineAmount;
  }

  @action
  setTotalPipelineCount(totalPipelineCount){
    this.totalPipelineCount = totalPipelineCount;
  }

  @action
  setTotalPipelineTotalAmount(totalPipelineTotalAmount){
    this.totalPipelineTotalAmount = totalPipelineTotalAmount;
  }

  @action
  setTotalPipelineTotalCount(totalPipelineTotalCount){
    this.totalPipelineTotalCount = totalPipelineTotalCount;
  }

  @action
  setFunnelScrollPosition(status, scrollPosition) {
    this.funnelScroll[status] = scrollPosition;
  }

  renderBanner(color, title, body, timer) {
    this.rootStore.bannerStore.addBanner(color, title, body, [], timer);
  }

  removeLeadFromFunnelColumn(leadToRemove, statusId) {
    const funnelColumn = this.funnel[statusId];

    let newAmount;
    try {
      newAmount = funnelColumn.amount - leadToRemove.attributes.amount;
    } catch {
      newAmount = funnelColumn.amount;
    }

    const newLeads = funnelColumn.leads.filter(
      (lead) => lead.id !== leadToRemove.id
    );

    let newCount = funnelColumn.count;

    if (newLeads.length !== funnelColumn.leads.length) {
      newCount = newCount - 1;
    }

    const newFunnelColumn = {
      leads: newLeads,
      amount: newAmount,
      count: newCount,
      page: funnelColumn.page,
    };

    this.funnel[statusId] = newFunnelColumn;
  }

  addLeadToFunnelColumn(lead, statusId) {
    const funnelColumn = this.funnel[statusId];

    let newAmount;
    try {
      newAmount = funnelColumn.amount + lead.attributes.amount;
    } catch (err) {
      newAmount = funnelColumn.amount;
    }

    const newLeads = [lead, ...funnelColumn.leads];
    const newCount = funnelColumn.count + 1;

    const newFunnelColumn = {
      leads: newLeads,
      amount: newAmount,
      count: newCount,
      page: funnelColumn.page,
    };

    this.funnel[statusId] = newFunnelColumn;
  }

  changeStatusOfLead(lead, oldStatusId, newStatusId) {
    if (oldStatusId === newStatusId) {
      return;
    }

    const { context } = this.rootStore;
    const { campaign } = context;

    const newStatusLabel = campaign.leadStatuses[newStatusId].label;

    try {
      this.removeLeadFromFunnelColumn(lead, oldStatusId);
      this.addLeadToFunnelColumn(lead, newStatusId);

      this.renderBanner(
        "green",
        "Status update successful",
        `${lead.attributes.identifier} has been updated! The status is now '${newStatusLabel}'`
      );
    } catch (err) {
      this.renderBanner(
        "red",
        "Status update unsuccessful",
        `The status for the ${campaign.leadLabel} could not be changed to '${newStatusLabel}'`,
        false
      );
    }
  }

  async retrieveFunnelColumn(status, filters) {
    const { leadsStore } = this.rootStore;

    const params = {
      ...filters,
      status,
    };

    const response = await leadsStore.getLeads(params);
    const { meta } = response;
    const { data } = response;

    const {
      page,
    } = meta;
    const leads = data;

    const newFunnel = {
      leads,
      page,
    };

    return newFunnel;
  }

  @action
  async getFunnelColumnNextPage(status) {
    const column = this.funnel[status];
    const { filters } = this.rootStore.filtersStore;

    const { more, next } = column.page;

    if (!more) {
      return;
    }

    const nextPageResponse = await this.retrieveFunnelColumn(status, {
      ...filters,
      page: next,
    });

    column.leads = this.funnel[status].leads.concat(nextPageResponse.leads);
    column.page = nextPageResponse.page;

    this.funnel[status] = column;
  }

  @action
  async updateFunnelColumn(status, searchParams, pagesToLoad) {
    let updatedLeads = [];
    const column = this.funnel[status];
    const leadsMap = new Map(column.leads.map((lead) => [lead.id, lead]));

    for (let i = 1; i <= pagesToLoad; i++) {
      const nextPageResponse = await this.retrieveFunnelColumn(status, {
        ...searchParams,
        page: i,
      });

      updatedLeads.push(...nextPageResponse.leads);
    }

    updatedLeads.forEach((updatedLead) => {
      leadsMap.set(updatedLead.id, updatedLead);
    });

    return {
      ...column,
      leads: Array.from(leadsMap.values()),
    };
  }

  @action
  async retrieveFunnelAsync(searchParams = {}) {
    const { leadsStore } = this.rootStore;

    this.cancelPreviousRequests();

    if (!this.isInitialized) {
      try {
        const response = await leadsStore.getFunnel(searchParams, { signal: this.abortController.signal });

        const { data, meta } = response;
        const { attributes } = data;
        const { 
          activePipelineAmount,
          activePipelineCount,
          activePipelineTotalAmount,
          activePipelineTotalCount,
          totalPipelineAmount,
          totalPipelineCount,
          totalPipelineTotalAmount,
          totalPipelineTotalCount,
         } = meta;

        Object.keys(attributes).forEach((status) =>
          this.setFunnelColumn(status, {
            leads: attributes[status].data,
            amount: attributes[status].meta.amount,
            page: attributes[status].meta.page,
            count: attributes[status].meta.count,
          })
        );

        this.setActivePipelineAmount(activePipelineAmount);
        this.setActivePipelineCount(activePipelineCount);
        this.setActivePipelineTotalAmount(activePipelineTotalAmount);
        this.setActivePipelineTotalCount(activePipelineTotalCount);
        this.setTotalPipelineAmount(totalPipelineAmount);
        this.setTotalPipelineCount(totalPipelineCount);
        this.setTotalPipelineTotalAmount(totalPipelineTotalAmount);
        this.setTotalPipelineTotalCount(totalPipelineTotalCount);
        this.setIsInitialized(true);
      } catch (err) {
        this.setIsInitialized(true);
      }
    } else {
      const newFunnel = { ...this.funnel };

      await Promise.all(
        STATUSES.map(async (status) => {
          const column = this.funnel[status];
          const { current } = column.page;
          const pagesToLoad = current > 1 ? 2 : 1;

          newFunnel[status] = await this.updateFunnelColumn(
            status,
            searchParams,
            pagesToLoad
          );
        })
      );

      this.setFunnel(newFunnel);
    }
  }
}

export default FunnelStore;
