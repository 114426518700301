import { axiosGet, axiosPost } from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const LEAD_ATTACHMENTS_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/attachments`);

const NEW_LEAD_ATTACHMENT_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/attachments/new`);

const CREATE_LEAD_ATTACHMENT_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/attachments`);

export async function getAttachmentUploadUrl(leadId, filename) {
  const params = {
    filename,
  };

  const response = await axiosGet(NEW_LEAD_ATTACHMENT_ENDPOINT(leadId), params);

  return response.data;
}

export async function getAttachments(leadId) {
  const response = await axiosGet(LEAD_ATTACHMENTS_ENDPOINT(leadId));

  return response.data;
}

export async function postAttachment(leadId, payload) {
  const response = await axiosPost(CREATE_LEAD_ATTACHMENT_ENDPOINT(leadId), {
    attachment: payload,
  });

  return response.data;
}
