import axios from "axios";
import { ICampaign } from "../../types/ICampaign";
import { awsServicesEndpoint } from "../../utils/endpoint";

export async function getFilesAtPath(authToken: string, campaignId: ICampaign["id"], path: string) {
  const endpoint = awsServicesEndpoint("team-resources-service", `campaigns/${campaignId}/files`);

  const params = new URLSearchParams({ path });

  const response = await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params,
  });

  return response.data.files;
}

export async function getUploadUrl(authToken: string, campaignId: ICampaign["id"], fileName: string, path: string) {
  const endpoint = awsServicesEndpoint("team-resources-service", `campaigns/${campaignId}/file`);

  const params = new URLSearchParams({ path });

  const response = await axios.post(endpoint, {
    fileName,
  }, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params,
  });

  return response.data.signedUrl;
}

export async function createFolder(authToken: string, campaignId: ICampaign["id"], folderName: string, path: string) {
  const endpoint = awsServicesEndpoint("team-resources-service", `campaigns/${campaignId}/folders`);

  let newPath;

  if (path === "") {
    newPath = folderName;
  } else {
    newPath = `${path}/${folderName}`;
  }

  const data = {
    path: newPath,
  };

  const response = await axios.post(endpoint, data, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  return response.data;
}

export async function uploadToS3(signedUploadUrl: string, file: File) {
  return axios.put(signedUploadUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
}

export async function deleteFolder(authToken: string, campaignId: ICampaign["id"], folderName: string, path: string): Promise<{ message: string, status: number }> {
  try {
    const endpoint = awsServicesEndpoint("team-resources-service", `campaigns/${campaignId}/folders`);

    let newPath;

    if (path === "") {
      newPath = `${folderName}/`;
    } else {
      newPath = `${path}/${folderName}/`;
    }

    const response = await axios.delete(endpoint, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        path: newPath,
      },
      validateStatus(status) {
        return status < 500;
      },
    });

    if (response.status === 400) {
      return {
        status: response.status,
        message: response.data.errors[0].message,
      };
    }

    return {
      message: "",
      status: response.status,
    };
  } catch (error) {
    return {
      message: "",
      status: 500,
    };
  }
}

export async function deleteFile(authToken: string, campaignId: ICampaign["id"], fileName: string, path: string) {
  const endpoint = awsServicesEndpoint("team-resources-service", `campaigns/${campaignId}/file`);

  let newPath;

  if (path === "") {
    newPath = fileName;
  } else {
    newPath = `${path}/${fileName}`;
  }

  await axios.delete(endpoint, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    data: {
      path: newPath,
    },
  });
}

export async function renameFile(authToken: string, campaignId: ICampaign["id"], oldFileName: string, newFileName: string, path: string) {
  const endpoint = awsServicesEndpoint("team-resources-service", `campaigns/${campaignId}/folders`);

  let oldPath;

  if (path === "") {
    oldPath = oldFileName;
  } else {
    oldPath = `${path}/${oldFileName}`;
  }

  let newPath;

  if (path === "") {
    newPath = newFileName;
  } else {
    newPath = `${path}/${newFileName}`;
  }

  await axios.put(endpoint, {
    oldPath,
    newPath,
  }, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
}

export async function renameFolder(authToken: string, campaignId: ICampaign["id"], oldFolderName: string, newFolderName: string, path: string) {
  const endpoint = awsServicesEndpoint("team-resources-service", `campaigns/${campaignId}/folders`);

  let oldPath;
  let newPath;

  if (path === "") {
    oldPath = `${oldFolderName}/`;
  } else {
    oldPath = `${path}/${oldFolderName}/`;
  }

  if (path === "") {
    newPath = `${newFolderName}/`;
  } else {
    newPath = `${path}/${newFolderName}/`;
  }

  await axios.put(endpoint, {
    oldPath,
    newPath,
  }, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
}
