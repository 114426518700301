import { endpoint } from "../utils/endpoint";
import * as axiosService from "./axiosRequests";

const APPROVAL_REQUEST_ENDPOINT = (approvalRequestId) =>
  endpoint(`app/v1.0/approval_requests/${approvalRequestId}`);

const APPROVAL_REQUESTS_ENDPOINT = endpoint("app/v1.0/approval_requests");

export async function updateApprovalRequest(approvalRequestId, payload) {
  const response = await axiosService.axiosPut(
    APPROVAL_REQUEST_ENDPOINT(approvalRequestId),
    payload
  );
  return response;
}

export async function getApprovalRequests() {
  const response = await axiosService.axiosGet(APPROVAL_REQUESTS_ENDPOINT);
  return response.data;
}

export default { updateApprovalRequest, getApprovalRequests };
