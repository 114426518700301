import React from "react";
import PropTypes from "prop-types";
import { PhotoUpload } from "../PhotoUpload/PhotoUpload";
import "./PhotoUrls.scss";
export class PhotoUrls extends React.Component {
  static propTypes = {
    accept: PropTypes.string,
    onChange: PropTypes.func,
  };

  onChange = (data) => {
    const { onChange } = this.props;

    onChange(data);
  };

  render() {
    return (
      <div className="PhotoUrls">
        <PhotoUpload {...this.props} id="photo_urls" onChange={this.onChange} />
      </div>
    );
  }
}

export default PhotoUrls;
