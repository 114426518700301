import { action, computed, makeObservable, observable } from "mobx";
import * as commonsService from "../utils/commons";

const DASHBOARD_TABLE_COLUMNS_STORAGE_KEY = "dashboardTableColumns";
const STATIC_COLUMN_IDS = [
  "owner",
  "status",
  "amount",
  "name",
  "description",
  "creator",
  "address",
  "updatedAt",
];

export class TableStore {
  rootStore;

  @observable columns;

  @observable isLoading;

  @observable tableScroll;

  constructor(rootStore) {
    makeObservable(this);

    this.isLoading = true;
    this.rootStore = rootStore;
    this.columns = [];
    this.tableScroll = 0;
  }

  @computed get storageKey() {
    const { context } = this.rootStore;

    if (context && context.currentUser && context.campaign) {
      return `TableStore/u${context.currentUser.id}/c${context.campaign.id}/${DASHBOARD_TABLE_COLUMNS_STORAGE_KEY}`;
    } else {
      return DASHBOARD_TABLE_COLUMNS_STORAGE_KEY;
    }
  }

  @computed get visibleColumns() {
    return this.columns.filter((column) => !column.hidden);
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setColumns(columns) {
    this.columns = columns;
  }

  @action
  setTableScrollPosition(scrollPosition) {
    this.tableScroll = scrollPosition;
  }

  defaultTableColumns() {
    const customFieldColumns = this.customFieldsFeaturedInTable();

    const { context } = this.rootStore;

    const nameLabel = context ? context.campaign.leadNameLabel : "Name";

    const columns = [
      {
        id: "owner",
        label: "Owners",
        hidden: false,
      },
      {
        id: "status",
        label: "Status",
        hidden: false,
      },
      {
        id: "amount",
        label: "Value ($)",
        hidden: false,
      },
      {
        id: "name",
        label: nameLabel,
        hidden: false,
      },
      {
        id: "description",
        label: "Description",
        hidden: false,
      },
      {
        id: "creator",
        label: "Creator",
        hidden: false,
      },
      {
        id: "address",
        label: "Address",
        hidden: false,
      },
    ]
      .concat(customFieldColumns)
      .concat([
        {
          id: "updatedAt",
          label: "Last Update",
          hidden: false,
        },
      ]);

    if (this.rootStore.context.currentUser.type === "Salesperson") {
      const salesPersonColumns = columns.filter(
        (column) => column.id !== "owner"
      );
      return salesPersonColumns;
    }

    return columns;
  }

  @action
  initializeTableColumns() {
    const tableColumnsFromStorageJson = this.getFromLocalStorage();

    let tableColumns;
    if (tableColumnsFromStorageJson) {
      try {
        tableColumns = JSON.parse(tableColumnsFromStorageJson);
        this.errorIfCustomFieldsDiffer(tableColumns);
      } catch (err) {
        tableColumns = this.defaultTableColumns();
      }
    } else {
      tableColumns = this.defaultTableColumns();
    }

    this.setColumns(tableColumns);
    this.setToLocalStorage(tableColumns);
    this.setIsLoading(false);

    return tableColumns;
  }

  @action
  refreshTableColumns() {
    const customFieldColumns = this.customFieldsFeaturedInTable();
    const currentColumns = this.columns.map((column) => {
      const customField = customFieldColumns.find(
        (field) => field.id === column.id
      );
      if (customField) {
        return {
          ...column,
          label: customField.label,
        };
      }
      return {
        ...column,
      };
    });
    this.setColumns(currentColumns);
    this.setToLocalStorage(currentColumns);
  }

  errorIfCustomFieldsDiffer(columns) {
    const currentCustomFields = columns.filter(
      (column) => !this.getStaticColumnIds().includes(column.id)
    );
    const currentCustomFieldIds = currentCustomFields.map(
      (customField) => customField.id
    );
    const customFieldIdsFeaturedInTable =
      this.customFieldsFeaturedInTable().map((customField) => customField.id);
    if (
      !commonsService.sameElements(
        currentCustomFieldIds,
        customFieldIdsFeaturedInTable
      )
    ) {
      throw new Error();
    }
  }

  customFieldsFeaturedInTable() {
    const { context } = this.rootStore;
    const { campaign } = context;
    const { customFields } = campaign;
    const customFieldColumns = customFields
      .map((field) => {
        if (field.type !== "table") {
          return {
            id: `${field.id}_cf`,
            label: field.name,
            type: field.type,
            required: field.required,
            hidden: false,
          };
        }
        return null;
      })
      .filter((field) => field !== null);
    return customFieldColumns;
  }

  getStaticColumnIds() {
    const { context } = this.rootStore;
    if (context.currentUser.type === "Salesperson") {
      const salesPersonStaticColumnIds = STATIC_COLUMN_IDS.filter(
        (id) => id !== "owner"
      );
      return salesPersonStaticColumnIds;
    }
    return STATIC_COLUMN_IDS;
  }

  @action
  onReorderColumns(columns) {
    this.setColumns(columns);
    this.setToLocalStorage(columns);
  }

  @action
  onHiddenColumnChange(updatedColumn) {
    const columnIndex = this.columns.findIndex(
      (column) => column.id === updatedColumn.id
    );

    this.columns[columnIndex] = updatedColumn;
    this.setToLocalStorage(this.columns);
  }

  @action
  resetColumns() {
    const defaultColumns = this.defaultTableColumns();
    this.setColumns(defaultColumns);
    this.setToLocalStorage(defaultColumns);
  }

  setToLocalStorage(columns) {
    localStorage.setItem(this.storageKey, JSON.stringify(columns));
  }

  getFromLocalStorage() {
    return localStorage.getItem(this.storageKey);
  }
}

export default TableStore;
