import React from "react";
import { Flex, Text } from "@wingmate/toolkit";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../hooks/useStore";

import "./CurrentSegment.scss";

export const CurrentSegment = observer(() => {
  const { t } = useTranslation("default");

  const rootStore = useStore();
  const { segmentsStore } = rootStore;
  const { currentSegment } = segmentsStore;

  if (!currentSegment) return null;

  return (
    <Flex className="CurrentSegment" align="flex-start" gap={4}>
      <Text type="P" weight="Medium">{t("segments.viewingName")}</Text>
      <Text type="P">{currentSegment.name}</Text>
    </Flex>
  );
});

export default CurrentSegment;
