import React from "react";
import { PropTypes } from "prop-types";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import { I18nextProvider } from "react-i18next";
import i18n from "../pages/ErrorPage/translations/i18n";

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    navigate: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false, errorType: null };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);

    if (process.env.NODE_ENV === "production") {
      this.setState({ hasError: true, errorType: error.errorCode });
    }
  }

  componentDidUpdate() {
    if (this.state.hasError) {
      if (process.env.NODE_ENV === "production") {
        window.location.replace("/error");
      }
    }
  }

  render() {
    const { children } = this.props;

    if (this.state.hasError) {
      return (
        <I18nextProvider i18n={i18n}>
          <ErrorPage />
        </I18nextProvider>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
