import { endpoint } from "../utils/endpoint";
import * as axiosService from "./axiosRequests";

const USER_ME_ENDPOINT = endpoint("app/v1.0/users/me");
const USER_EMAIL_ENDPOINT = endpoint("app/v1.0/users/email");
const USER_ENDPOINT = (userId) => endpoint(`app/v1.0/users/${userId}`);
const USER_SETTINGS_ENDPOINT = (userId) =>
  endpoint(`app/v1.0/users/${userId}/settings`);
const USER_PROFILE_UPLOAD_ENDPOINT = (userId) =>
  endpoint(`app/v1.0/users/${userId}/profile_upload`);

export const getMe = async () => {
  const response = await axiosService.axiosGet(USER_ME_ENDPOINT);

  return response.data;
};

export const getEmail = async () => {
  const response = await axiosService.axiosGet(USER_EMAIL_ENDPOINT);

  return response.data;
};

export const getUserSettings = async (userId) => {
  const response = await axiosService.axiosGet(USER_SETTINGS_ENDPOINT(userId));

  return response.data.data;
};

export const updateUser = async (userId, payload) => {
  const response = await axiosService.axiosPut(USER_ENDPOINT(userId), payload);

  return response.data.data;
};

export const getProfileUploadUrl = async (userId, filename) => {
  try {
    const params = {
      filename,
    };

    const response = await axiosService.axiosGet(
      USER_PROFILE_UPLOAD_ENDPOINT(userId),
      params
    );

    return response.data;
  } catch (err) {
    return "";
  }
};

export default {
  getMe,
  updateUser,
  getProfileUploadUrl,
};
