import React from "react";
import { inject, observer } from "mobx-react";
import { AdminRouteBreadcrumbs } from "./AdminRouteBreadcrumbs";
import { adminStoreProptypes } from "../proptypes/stores";

@inject("adminStore")
@observer
export class AdminRouteBreadcrumbsWrapper extends React.Component {
  static propTypes = {
    adminStore: adminStoreProptypes,
  };

  render() {
    const { adminStore } = this.props;
    const { showBreadcrumbs } = adminStore;

    return showBreadcrumbs ? <AdminRouteBreadcrumbs /> : <></>;
  }
}

export default AdminRouteBreadcrumbsWrapper;
