import React from "react";
import PropTypes from "prop-types";
import { FlexRow } from "wm-ui-toolkit";
import { CheckmarkCircleIcon, XCircleIcon } from "wm-graphics";
import "./ConfirmButtons.scss";

const ENTER_KEY = "Enter";

export class ConfirmButtons extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
  };

  onConfirm = (event) => {
    const { onConfirm } = this.props;

    if (event.key === ENTER_KEY) {
      onConfirm();
    }
  };

  onCancel = (event) => {
    const { onCancel } = this.props;

    if (event.key === ENTER_KEY) {
      onCancel();
    }
  };

  render() {
    const { onCancel, onConfirm } = this.props;
    return (
      <FlexRow className="ConfirmButtons">
        <div
          className="ConfirmButtons__confirm"
          data-testid="ConfirmButtons__confirm"
          onClick={onConfirm}
          onKeyDown={this.onConfirm}
          role="button"
          tabIndex={0}
        >
          <CheckmarkCircleIcon height={20} width={20} />
        </div>
        <div
          className="ConfirmButtons__cancel"
          data-testid="ConfirmButtons__cancel"
          onClick={onCancel}
          onKeyDown={this.onCancel}
          role="button"
          tabIndex={0}
        >
          <XCircleIcon className="XCircleIcon" height={20} width={20} />
        </div>
      </FlexRow>
    );
  }
}

export default ConfirmButtons;
