import { axiosGet } from "./axiosRequests";
import { endpoint } from "../utils/endpoint";

const NEW_IMAGE_ENDPOINT = endpoint("app/v1.0/leads/images/new");

export async function getImageUploadUrl(filename) {
  const params = {
    filename,
  };

  const response = await axiosGet(NEW_IMAGE_ENDPOINT, params);

  return response.data;
}

export default { getImageUploadUrl };
