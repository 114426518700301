import { awsServicesEndpoint } from "../../../utils/endpoint";
import {
  axiosGet, axiosPost, axiosPut, axiosDelete,
} from "../../axiosRequests";
import { ICampaign } from "../../../types/ICampaign";
import { ISegment, ISegmentAccess } from "../../../types/ISegment";
import { camelCase } from "../../../utils/commons";

export interface IGetSegmentResponse {
  _id: string;
  campaignId: string;
  terms: Record<string, string | string[]>;
  name: string;
  description: string;
  timestamps: {
    created_at: string;
    updated_at: string;
  };
  accessibleBy: ISegmentAccess[];
}

const segmentsEndpoint = (campaignId: ICampaign["id"]): string => awsServicesEndpoint("segments-service", `campaigns/${campaignId}/segments`);

export const segmentEndpoint = (
  campaignId: ICampaign["id"],
  segmentId: ISegment["id"],
): string => `${segmentsEndpoint(campaignId)}/${segmentId}`;

export const formatSegment = (segmentResponse: IGetSegmentResponse): ISegment => {
  const segment = camelCase(segmentResponse) as ISegment;
  segment.terms = segmentResponse.terms;

  return segment;
};

export const formatSegments = (segmentsResponse: IGetSegmentResponse[]): ISegment[] => {
  const camelCaseSegments = camelCase(segmentsResponse) as ISegment[];

  return camelCaseSegments.map((segment: ISegment) => {
    const newSeg: ISegment = segment;
    newSeg.terms = {};

    const snakeCaseSegment = segmentsResponse.find((seg: IGetSegmentResponse) => {
      const { _id } = seg;
      return _id === segment.id;
    });

    if (snakeCaseSegment) newSeg.terms = snakeCaseSegment.terms;
    return newSeg;
  });
};

export const getSegments = async (
  token: string,
  campaignId: ICampaign["id"],
): Promise<ISegment[]> => {
  const response = await axiosGet(
    segmentsEndpoint(campaignId),
    {},
    {
      Authorization: `Bearer ${token}`,
    },
  );

  const segmentsResponse: IGetSegmentResponse[] = response.data.segments;
  const camelCaseSegmentsWithSnakeCaseTerms: ISegment[] = formatSegments(segmentsResponse);

  return camelCaseSegmentsWithSnakeCaseTerms;
};

export const createSegment = async (
  token: string,
  campaignId: ICampaign["id"],
  payload: Pick<ISegment, "name" | "terms">,
): Promise<ISegment> => {
  const response = await axiosPost(
    segmentsEndpoint(campaignId),
    payload,
    {
      Authorization: `Bearer ${token}`,
    },
  );

  return formatSegment(response.data) as ISegment;
};

export const updateSegment = async (
  token: string,
  campaignId: ICampaign["id"],
  segmentId: ISegment["id"],
  payload: Pick<ISegment, "name" | "terms">,
): Promise<ISegment> => {
  const response = await axiosPut(
    segmentEndpoint(campaignId, segmentId),
    payload,
    {
      Authorization: `Bearer ${token}`,
    },
  );

  return formatSegment(response.data) as ISegment;
};

export const deleteSegment = async (
  token: string,
  campaignId: ICampaign["id"],
  segmentId: ISegment["id"],
): Promise<void> => {
  await axiosDelete(
    segmentEndpoint(campaignId, segmentId),
    {},
    {
      Authorization: `Bearer ${token}`,
    },
  );
};
