import * as axiosRequests from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const LEAD_CONTACT_ENDPOINT = (leadId, contactId) =>
  endpoint(`app/v1.0/leads/${leadId}/contacts/${contactId}`);

const LEAD_CONTACTS_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/contacts`);

export async function deleteContact(leadId, contactId) {
  const response = await axiosRequests.axiosDelete(
    LEAD_CONTACT_ENDPOINT(leadId, contactId)
  );

  return response.data;
}

export async function getContacts(leadId) {
  const response = await axiosRequests.axiosGet(LEAD_CONTACTS_ENDPOINT(leadId));

  return response.data;
}

export async function postContact(leadId, contact) {
  const response = await axiosRequests.axiosPost(
    LEAD_CONTACTS_ENDPOINT(leadId),
    {
      contact,
    }
  );

  return response.data;
}

export async function putContact(leadId, contactId, contact) {
  const response = await axiosRequests.axiosPut(
    LEAD_CONTACT_ENDPOINT(leadId, contactId),
    {
      contact,
    }
  );

  return response.data;
}

export default {
  getContacts,
  postContact,
  putContact,
};
