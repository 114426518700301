import { endpoint } from "../../utils/endpoint";

const CREATE_LIKE_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/likes.json`);

const DELETE_LIKE_ENDPOINT = (leadId, likeId) =>
  endpoint(`app/v1.0/leads/${leadId}/likes/${likeId}.json`);

const GET_LIKES_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/likes`);

import * as axiosService from "../axiosRequests";

export async function getLikes(leadId) {
  const response = await axiosService.axiosGet(GET_LIKES_ENDPOINT(leadId));
  return response.data.data;
}

export async function postLike(leadId) {
  const payload = {};
  const response = await axiosService.axiosPost(
    CREATE_LIKE_ENDPOINT(leadId),
    payload
  );
  return response.data;
}

export async function deleteLike(leadId, likeId) {
  const payload = {};
  const response = await axiosService.axiosDelete(
    DELETE_LIKE_ENDPOINT(leadId, likeId),
    payload
  );
  return response.data;
}

export default {
  postLike,
  deleteLike,
  getLikes,
};
