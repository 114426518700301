import { endpoint } from "../utils/endpoint";

import * as axiosService from "./axiosRequests";

const LEADS_ENDPOINT = endpoint("app/v1.0/leads.json");

const ADDRESS_ENDPOINT = endpoint("app/v1.0/leads/address");

const LEAD_ENDPOINT_A1 = (leadId) => endpoint(`app/v1.0/leads/${leadId}.json`);

const MERGE_LEAD_ENDPOINT = (leadId) =>
  endpoint(`app/v1.0/leads/${leadId}/merge`);

export async function getLeads(params = {}, additionalOptions = {}) {
  try {
    const response = await axiosService.axiosGet(LEADS_ENDPOINT, params, {}, additionalOptions);

    return {
      data: response.data.data,
      meta: response.data.meta,
    };
  } catch (err) {
    return {};
  }
}

export async function getLeadDetails(leadId) {
  const response = await axiosService.axiosGet(LEAD_ENDPOINT_A1(leadId), {
    headers: {
      "Content-Type": "application/json",
    },
  });

  const { data } = response;
  return data.data;
}

export async function getAddress(params = {}) {
  const response = await axiosService.axiosGet(ADDRESS_ENDPOINT, params);

  const { data } = response;
  return data;
}

export async function putLead(leadId, payload) {
  const response = await axiosService.axiosPut(
    LEAD_ENDPOINT_A1(leadId),
    payload
  );
  return response.data;
}

export async function deleteLead(leadId) {
  await axiosService.axiosDelete(LEAD_ENDPOINT_A1(leadId));
  return true;
}

export async function mergeLeads(leadId, payload) {
  const response = await axiosService.axiosPut(
    MERGE_LEAD_ENDPOINT(leadId),
    payload
  );
  return response.data;
}

export default {
  getLeads,
  putLead,
  getLeadDetails,
};
