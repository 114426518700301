import React, {
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import pluralize from "pluralize";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
  AdminConsoleActiveIcon,
  AdminConsoleInactiveIcon,
  AnalyticsActiveIcon,
  AnalyticsInactiveIcon,
  LeadsActiveIcon,
  LeadsInactiveIcon,
  NavigationSupportIcon,
  RemindersActiveIcon,
  RemindersInactiveIcon,
  ActivityActiveIcon,
  ActivityInactiveIcon,
  HopperActiveIcon,
  HopperInactiveIcon,
  NavigationMenuIcon,
} from "@wingmate/graphics";
import { Drawer, Flex, Text } from "@wingmate/toolkit";
import { AppContext } from "../../../context/AppProvider";
import { useStore } from "../../../hooks/useStore";
import { ConditionalWrapper } from "../../../utils/ConditionalWrapper";
import { ContractSelector } from "../ContractSelector/ContractSelector";
import { HomeButton } from "../HomeButton/HomeButton";
import { NavigationItem } from "./NavigationItem/NavigationItem";
import { NavigationPin } from "./NavigationPin/NavigationPin";

import "./SideNavigation.scss";

const MANAGER_TYPE = "Manager";
const WORKER_TYPE = "Worker";
const SCREEN_MEDIUM = 899;

export const SideNavigation = observer(() => {
  const [isSmallScreenSize, setIsSmallScreenSize] = useState(window.innerWidth <= SCREEN_MEDIUM);
  const { t } = useTranslation(["default", "common"]);
  const { campaign, currentUser } = useContext(AppContext);
  const { id: campaignId } = campaign;
  const { id: currentUserId } = currentUser;
  const { contractStore, navigationStore } = useStore();
  const { contracts, isInitialized } = contractStore;
  const { isExpanded, isPinned } = navigationStore;

  const showLabel = isPinned || isExpanded;
  const currentUserIsAManager = currentUser.type === MANAGER_TYPE;
  const currentUserIsNotAWorker = currentUser.type !== WORKER_TYPE;

  useEffect(() => {
    if (window.innerWidth <= SCREEN_MEDIUM) {
      navigationStore.setIsPinned(false);
    }

    navigationStore.loadNavigationStatus();
  }, [navigationStore]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= SCREEN_MEDIUM) {
        navigationStore.setIsPinned(false);
      }

      setIsSmallScreenSize(window.innerWidth <= SCREEN_MEDIUM);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigationStore]);

  const expandSideNavigation = () => {
    navigationStore.setIsExpanded(true);
  };

  const shrinkSideNavigation = () => {
    navigationStore.setIsExpanded(false);
  };

  const getAnalytics = () => (
    currentUser.type === MANAGER_TYPE ? `/campaigns/${campaignId}/analytics` : `/users/${currentUserId}/analytics`
  );

  const showAdminConsole = currentUserIsNotAWorker && currentUser.hasAdminPrivileges;

  const sideNavigationClassNames = classNames("SideNavigation", {
    closed: !isExpanded && !isPinned,
    expand: isExpanded && !isPinned,
    open: isPinned,
  });

  const contractsLoaded = contracts.length > 0 && isInitialized;

  const renderNavigationContent = () => (
    <nav
      className={sideNavigationClassNames}
      data-testid="SideNavigation"
      onMouseEnter={expandSideNavigation}
      onMouseLeave={shrinkSideNavigation}
    >
      <Flex className="SideNavigation__HomeSection" align="center" justify="flex-start" gap={8}>
        <NavigationMenuIcon className="HomeSection__NavigationMenu" onClick={shrinkSideNavigation} />
        <HomeButton />
      </Flex>
      <Flex className="SideNavigation__NavItems" flex="1" justify="space-between" vertical>
        <Flex className="NavItems__Container" vertical>
          {showLabel && <NavigationPin />}
          {contractsLoaded && <ContractSelector contracts={contracts} />}
          <NavigationItem
            key="leads"
            activeIcon={<LeadsActiveIcon data-testid="LeadsActiveIcon" />}
            inactiveIcon={<LeadsInactiveIcon data-testid="LeadsInActiveIcon" />}
            label={pluralize(campaign.leadLabel)}
            to="/dashboard"
          />
          {currentUser.pmAccount && (
            <NavigationItem
              key="pm-dashboard"
              activeIcon={<HopperActiveIcon data-testid="PmDashboardActiveIcon" />}
              inactiveIcon={<HopperInactiveIcon data-testid="PmDashboardInactiveIcon" />}
              label={t("common:navigation.sideNavigation.pmDashboard")}
              to="/pipeline"
            />
          )}
          {currentUserIsNotAWorker && (
            <NavigationItem
              key="reminders"
              activeIcon={<RemindersActiveIcon data-testid="RemindersActiveIcon" />}
              inactiveIcon={<RemindersInactiveIcon data-testid="RemindersInactiveIcon" />}
              label={t("common:navigation.sideNavigation.reminders")}
              to={`/users/${currentUserId}/reminders`}
            />
          )}
          {currentUserIsNotAWorker && (
            <NavigationItem
              key="team-feed"
              activeIcon={<ActivityActiveIcon data-testid="ActivityActiveIcon" />}
              inactiveIcon={<ActivityInactiveIcon data-testid="ActivityInactiveIcon" />}
              label={t("common:navigation.sideNavigation.pipelineActivity")}
              to="/activities"
            />
          )}
          {currentUserIsNotAWorker && (
            <NavigationItem
              key="analytics"
              activeIcon={<AnalyticsActiveIcon data-testid="AnalyticsActiveIcon" />}
              inactiveIcon={<AnalyticsInactiveIcon data-testid="AnalyticsInactiveIcon" />}
              label={t("common:navigation.sideNavigation.analytics")}
              to={getAnalytics()}
              matchKey="analytics"
            >
              {currentUserIsAManager && (
              <NavigationItem
                key="campaign-analytics"
                label={t("common:navigation.sideNavigation.pipelineAnalytics")}
                to={`/campaigns/${campaignId}/analytics`}
              />
              )}
              <NavigationItem
                key="user-analytics"
                label={t("common:navigation.sideNavigation.userAnalytics")}
                to={`/users/${currentUserId}/analytics`}
              />
            </NavigationItem>
          )}
          {showAdminConsole && (
            <NavigationItem
              key="admin-console"
              activeIcon={<AdminConsoleActiveIcon data-testid="AdminConsoleActiveIcon" />}
              inactiveIcon={<AdminConsoleInactiveIcon data-testid="AdminConsoleInactiveIcon" />}
              label={t("common:navigation.sideNavigation.adminConsole")}
              to={`/admin/campaigns/${campaignId}`}
            >
              <NavigationItem
                key="pipeline-settings"
                label={t("common:navigation.sideNavigation.pipelineSettings")}
                to={`/admin/campaigns/${campaignId}/edit`}
              />
              <NavigationItem
                key="team-management"
                label={t("common:navigation.sideNavigation.teamManagement")}
                to={`/admin/campaigns/${campaignId}/users`}
              />
              <NavigationItem
                key="enterprise-payout"
                label={t("common:navigation.sideNavigation.enterprisePayouts")}
                to={`/admin/campaigns/${campaignId}/enterprise_payouts`}
              />
            </NavigationItem>
          )}
        </Flex>
        <Flex className="NavItems__Support" gap="large">
          <a href="https://support.wingmateapp.com/" target="__blank">
            <Flex align="center" justify="space-between" flex="1">
              <Flex align="center" justify="center" gap="large">
                <div><NavigationSupportIcon /></div>
                {showLabel && <Text type="H6">{t("common:navigation.sideNavigation.support")}</Text>}
              </Flex>
            </Flex>
          </a>
        </Flex>
      </Flex>
    </nav>
  );

  return (
    <div className="SideNavigationContainer">
      <ConditionalWrapper
        condition={isSmallScreenSize}
        wrapper={(children: ReactNode) => (
          <Drawer
            rootClassName="SideNavigationDrawer"
            placement="left"
            open={isExpanded && !isPinned}
            width="80%"
            title={null}
            onClose={shrinkSideNavigation}
            showCloseButton={false}
          >
            {children}
          </Drawer>
        )}
      >
        {renderNavigationContent()}
      </ConditionalWrapper>
    </div>
  );
});

export default SideNavigation;
