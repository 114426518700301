import { action, makeAutoObservable, observable } from "mobx";

export class MassSelectStore {
  @observable selected = [];
  @observable selectMode = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  addToSelected(item) {
    this.selected.push(item);
  }

  @action
  isItemSelected = (itemId) => {
    return !!this.selected.find((item) => item.id === itemId);
  };

  @action
  removeFromSelected(itemId) {
    const removeItemAtIndex = this.selected.findIndex(
      (item) => item.id === itemId
    );
    this.selected.splice(removeItemAtIndex, 1);
  }

  @action
  clearSelected() {
    this.selected = [];
  }

  @action
  turnSelectModeOff() {
    this.selectMode = false;
  }

  @action
  turnSelectModeOn() {
    this.selectMode = true;
  }

  isSelectedEmpty() {
    return this.selected.length === 0;
  }
}

export default MassSelectStore;
