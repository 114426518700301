import { axiosPut } from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";

const UPDATE_BULK_LEAD_ENDPOINT = endpoint("app/v1.0/leads/bulk.json");

export async function putBulkLeads(searchParams, payload) {
  const response = await axiosPut(UPDATE_BULK_LEAD_ENDPOINT, {
    leads: {
      ...payload,
    },
  }, {}, { params: { ...searchParams } });

  return response.data;
}

export default {
  putBulkLeads,
};
