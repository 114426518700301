import React, { useContext, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { TranslationProvider } from "../providers/TranslationProvider";
import i18next from "../pages/CampaignActivity/i18n";
import { AppContext } from "../context/AppProvider";

const USER_TYPES_WITH_ACCESS: string[] = ["Manager", "Salesperson"];

interface ICampaignActivityRoute {
  children: ReactNode;
}

export const CampaignActivityRoute: React.FC<ICampaignActivityRoute> = ({ children }) => {
  const { currentUser } = useContext(AppContext);

  if (!USER_TYPES_WITH_ACCESS.includes(currentUser.type)) {
    return <Navigate to="/" />;
  }

  return <TranslationProvider i18n={i18next}>{children}</TranslationProvider>;
};

export default CampaignActivityRoute;
