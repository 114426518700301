import axios from "axios";
import { endpoint } from "../../utils/endpoint";

export const USER_REMINDERS_CALENDAR_ENDPOINT = (userId) =>
  endpoint(`app/v1.0/users/${userId}/reminders/calendar.json`);

export const USER_REMINDERS_ENDPOINT = (userId, format = "json") =>
  endpoint(`app/v1.0/users/${userId}/reminders.${format}`);

export async function getReminders(userId, params = {}) {
  try {
    const response = await axios.get(USER_REMINDERS_ENDPOINT(userId), {
      params,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response;
  } catch (err) {
    return {};
  }
}

export default {
  getReminders,
};
