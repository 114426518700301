import { action, makeAutoObservable, observable } from "mobx";
import * as priceSheetsPurchasablesService from "../requests/priceSheets/purchasables";
import * as purchasablesService from "../requests/purchasables";

export class PurchasablesStore {
  @observable purchasables = [];

  @observable isSaving = false;

  @observable amount;

  @observable count;

  @observable page;

  @observable hasMorePurchasables;

  @observable isLoading;

  constructor(rootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
    this.amount = 0;
    this.count = 0;
    this.page = {};
    this.hasMorePurchasables = false;
  }

  @action setIsSaving(isSaving) {
    this.isSaving = isSaving;
  }

  @action
  setPurchasables(purchasables) {
    this.purchasables = purchasables;
  }

  @action
  setAmount(amount) {
    this.amount = amount;
  }

  @action
  setCount(count) {
    this.count = count;
  }

  @action
  setPage(page) {
    this.page = page;
  }

  @action
  setIsLoading(loading) {
    this.isLoading = loading;
  }

  @action
  setHasMorePurchasables(hasMore) {
    this.hasMorePurchasables = hasMore;
  }

  getPurchasablesForPriceSheet(priceSheetId) {
    return this.purchasables.filter(
      (purchasable) =>
        purchasable.attributes.priceSheetId.toString() ===
        priceSheetId.toString()
    );
  }

  @action
  modifyPurchasableInCache(purchasableId, newPurchasable) {
    const purchasables = [...this.purchasables];

    const purchasableIndex = purchasables.findIndex(
      (purchasable) => purchasable.id == purchasableId
    );

    if (purchasableIndex !== -1) {
      purchasables[purchasableIndex] = newPurchasable;

      this.setPurchasables(purchasables);
    }
  }

  @action
  async getPurchasablesForPriceSheetAsync(priceSheetId, payload) {
    this.setIsLoading(true);

    const { data, meta } = await priceSheetsPurchasablesService.getPurchasables(
      priceSheetId,
      payload
    );

    const { amount, count, page } = meta;
    this.setCount(count);
    this.setAmount(amount);
    this.setPage(page);
    this.setHasMorePurchasables(page.more);

    this.setPurchasables(data);
    this.setIsLoading(false);
    return data;
  }

  @action
  async getNextPageAsync(priceSheetId, payload) {
    if (!this.page.more) {
      return;
    }

    const purchasePayload = {
      page: this.page.next,
      ...payload,
    };

    this.setIsLoading(true);

    const { data, meta } = await priceSheetsPurchasablesService.getPurchasables(
      priceSheetId,
      purchasePayload
    );

    const { page } = meta;

    this.setPage(page);
    this.setHasMorePurchasables(page.more);

    const allPurchasables = this.purchasables.concat(data);
    this.setPurchasables(allPurchasables);
    this.setIsLoading(false);
  }

  @action
  async deletePurchasableAsync(purchasableId) {
    this.setIsSaving(true);

    await purchasablesService.deletePurchasable(purchasableId);

    this.deletePurchasable(purchasableId);

    this.setIsSaving(false);
  }

  @action
  deletePurchasable(purchasableId) {
    const purchasableToDeleteIndex = this.purchasables.findIndex(
      (purchasable) => purchasable.attributes.id === purchasableId
    );

    if (purchasableToDeleteIndex !== -1) {
      this.purchasables.splice(purchasableToDeleteIndex, 1);
    }
  }

  @action
  async createPurchasableAsync(priceSheetId, payload) {
    this.setIsSaving(true);

    const newPurchasable = await priceSheetsPurchasablesService.postPurchasable(
      priceSheetId,
      payload
    );

    this.purchasables.push(newPurchasable);

    this.setIsSaving(false);
  }

  @action
  async updatePurchasableAsync(priceSheetId, purchasableId, purchasable) {
    this.setIsSaving(true);

    const updatedPurchasable =
      await priceSheetsPurchasablesService.putPurchasable(
        priceSheetId,
        purchasableId,
        purchasable
      );

    this.modifyPurchasableInCache(purchasableId, updatedPurchasable);

    this.setIsSaving(false);

    return updatedPurchasable;
  }
}

export default PurchasablesStore;
