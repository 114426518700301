import PropTypes from "prop-types";

const leadLinkLead = PropTypes.shape({
  id: PropTypes.number,
  identifier: PropTypes.string,
  owner: PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.number,
    initials: PropTypes.string,
    profileImageUrl: PropTypes.string,
    type: PropTypes.string,
  }),
});

export const leadLink = PropTypes.shape({
  attributes: PropTypes.shape({
    childLead: leadLinkLead,
    parentLead: leadLinkLead,
    createdAt: PropTypes.string,
  }),
  id: PropTypes.string,
  type: PropTypes.string,
});

export default leadLink;
