import * as axiosService from "../axiosRequests";
import { endpoint } from "../../utils/endpoint";
import { IUser } from "../../types/IUser";

interface IGetCampaignUsersParams {
  page?: number;
  include_disabled?: boolean;
}

export interface ICampaignUsersParams {
  email: string;
  full_name: string;
  disabled?: boolean;
  has_admin_privileges?: boolean;
  type: string;
  amount_visible?: boolean;
  phone_number: string;
  time_zone?: string;
  locale?: string;
  open_leads_in_tab?: boolean;
  scrollbar_enabled?: boolean;
  enable_tile_details?: boolean;
  auto_assign_leads?: boolean;
  unsubscribed_email_cmapaigns?: string[];
}

const CAMPAIGN_USERS_ENDPOINT = (id: string): string => endpoint(
  `app/v1.0/campaigns/${id}/users.json`,
);
const CAMPAIGN_USERS_CREATE_ENDPOINT = (id: string): string => endpoint(`app/v1.0/campaigns/${id}/users.json`);

const CAMPAIGN_USER_ENDPOINT = (id: string, userId: string): string => endpoint(`app/v1.0/campaigns/${id}/users/${userId}.json`);

export const createCampaignUser = async (
  campaignId: string,
  user: ICampaignUsersParams,
): Promise<IUser> => {
  const response = await axiosService.axiosPost(
    CAMPAIGN_USERS_CREATE_ENDPOINT(campaignId),
    { user },
  );

  return response.data;
};

export const getCampaignUsers = async (
  campaignId: string,
  params: IGetCampaignUsersParams = {},
): Promise<IUser[]> => {
  try {
    const response = await axiosService.axiosGet(
      CAMPAIGN_USERS_ENDPOINT(campaignId),
      params,
    );

    if (response.data.data) {
      return response.data.data;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const updateCampaignUser = async (
  campaignId: string,
  userId: string,
  user: Partial<ICampaignUsersParams>,
): Promise<IUser> => {
  const response = await axiosService.axiosPut(
    CAMPAIGN_USER_ENDPOINT(campaignId, userId),
    { user },
  );

  return response.data;
};

export default {
  createCampaignUser,
  getCampaignUsers,
};
