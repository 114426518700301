import { endpoint } from "../utils/endpoint";

import * as axiosService from "./axiosRequests";

const UPDATE_CONTRACT_ENDPOINT = (contractId) =>
  endpoint(`app/v1.0/contracts/${contractId}`);

const CONTRACTS_ENDPOINT = endpoint(`app/v1.0/contracts`);

export async function updateContract(contractId, payload) {
  try {
    const response = await axiosService.axiosPut(
      UPDATE_CONTRACT_ENDPOINT(contractId),
      payload
    );
    return response;
  } catch (err) {
    return {};
  }
}

export async function getContracts() {
  const response = await axiosService.axiosGet(CONTRACTS_ENDPOINT);
  return response.data.data;
}

export default { updateContract };
