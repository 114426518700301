import { action, makeAutoObservable, observable } from "mobx";
import * as tasksService from "../requests/campaigns/tasks";

export class TasksStore {
  @observable tasks = [];
  @observable isLoading = true;
  @observable isSaving = false;

  constructor(rootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  setTasks(tasks) {
    this.tasks = tasks;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setIsSaving(isSaving) {
    this.isSaving = isSaving;
  }

  @action
  addTask(task) {
    this.tasks.push(task);
  }

  @action
  findTask(taskId) {
    const task = this.tasks.find((task) => task.id === taskId);
    return task;
  }

  @action
  modifyTaskInCache(taskId, newTask) {
    const tasks = [...this.tasks];

    const taskIndex = tasks.findIndex((task) => task.id === taskId);

    if (taskIndex !== -1) {
      tasks[taskIndex] = newTask;

      this.setTasks(tasks);
    }
  }

  @action
  async getTasksAsync() {
    this.setIsLoading(true);
    const tasks = await tasksService.getTasks(this.rootStore.campaignId);

    this.setTasks(tasks);
    this.setIsLoading(false);

    return tasks;
  }

  @action
  async updateTaskAsync(taskId, payload) {
    this.setIsLoading(true);
    const task = await tasksService.updateTask(
      this.rootStore.campaignId,
      taskId,
      payload
    );

    this.modifyTaskInCache(taskId, task);

    this.setIsLoading(false);

    return task;
  }

  @action
  async postTaskAsync(payload) {
    this.setIsLoading(true);
    const task = await tasksService.postTask(
      this.rootStore.campaignId,
      payload
    );

    this.addTask(task);

    this.setIsLoading(false);

    return task;
  }

  @action
  async deleteTaskAsync(taskId) {
    this.setIsLoading(true);
    await tasksService.deleteTask(this.rootStore.campaignId, taskId);

    await this.getTasksAsync(this.rootStore.campaignId);

    this.setIsLoading(false);
  }
}

export default TasksStore;
