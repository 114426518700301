import { action, makeAutoObservable, observable } from "mobx";
import * as webhooksService from "../requests/campaigns/webhooks";

export class WebhooksStore {
  @observable webhooks = [];
  @observable isLoading = false;

  constructor(adminStore) {
    makeAutoObservable(this);
    this.adminStore = adminStore;
    this.campaignId = adminStore.campaignId;
  }

  @action
  setWebhooks(webhooks) {
    this.webhooks = webhooks;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  deleteWebhook(webhookId) {
    const webhookToDeleteIndex = this.webhooks.findIndex(
      (webhook) => webhook.id === webhookId
    );

    if (webhookToDeleteIndex !== -1) {
      this.webhooks.splice(webhookToDeleteIndex, 1);
    }
  }

  @action
  async getWebhooksAsync() {
    this.setIsLoading(true);
    const webhooks = await webhooksService.getWebhookEndpoints(this.campaignId);

    this.setWebhooks(webhooks);
    this.setIsLoading(false);

    return webhooks;
  }

  @action
  async createWebhookAsync(newWebhookPayload) {
    const payload = {
      endpoint: {
        target_url: "",
        events: [],
        ...newWebhookPayload,
      },
    };

    const newWebhook = await webhooksService.postWebhookEndpoint(
      this.campaignId,
      payload
    );

    this.webhooks.push(newWebhook);

    return newWebhook;
  }

  @action
  async deleteWebhookAsync(webhook) {
    await webhooksService.deleteWebhookEndpoint(this.campaignId, webhook.id);

    this.deleteWebhook(webhook.id);
  }
}

export default WebhooksStore;
