import React from "react";
export { ReactComponent as ArchivedPin } from "./Archived.svg";
export { ReactComponent as CompletePin } from "./Complete.svg";
export { ReactComponent as DefaultPin } from "./Default.svg";
export { ReactComponent as FailedPin } from "./Failed.svg";
export { ReactComponent as NewPin } from "./New.svg";
export { ReactComponent as PendingPin } from "./PendingProposal.svg";
export { ReactComponent as ProposalPin } from "./Proposal.svg";
export { ReactComponent as ReceivedPin } from "./Received.svg";
export { ReactComponent as WorkingPin } from "./Working.svg";
export { ReactComponent as ClusterGroupSmall } from "./ClusterGroupSmall.svg";
export { ReactComponent as ClusterGroupMedium } from "./ClusterGroupMedium.svg";
export { ReactComponent as ClusterGroupBig } from "./ClusterGroupBig.svg";
import { ReactComponent as ArchivedPin } from "./Archived.svg";
import { ReactComponent as CompletePin } from "./Complete.svg";
import { ReactComponent as FailedPin } from "./Failed.svg";
import { ReactComponent as NewPin } from "./New.svg";
import { ReactComponent as PendingPin } from "./PendingProposal.svg";
import { ReactComponent as ProposalPin } from "./Proposal.svg";
import { ReactComponent as ReceivedPin } from "./Received.svg";
import { ReactComponent as WorkingPin } from "./Working.svg";

export { default as ArchivedPinData } from "./Archived.svg";
export { default as CompletePinData } from "./Complete.svg";
export { default as DefaultPinData } from "./Default.svg";
export { default as FailedPinData } from "./Failed.svg";
export { default as NewPinData } from "./New.svg";
export { default as PendingPinData } from "./PendingProposal.svg";
export { default as ProposalPinData } from "./Proposal.svg";
export { default as ReceivedPinData } from "./Received.svg";
export { default as WorkingPinData } from "./Working.svg";
export { default as ClusterGroupSmallData } from "./ClusterGroupSmall.png";
export { default as ClusterGroupMediumData } from "./ClusterGroupMedium.png";
export { default as ClusterGroupBigData } from "./ClusterGroupBig.png";
import Archived from "./Archived.png";
import Complete from "./Complete.png";
import Failed from "./Failed.png";
import New from "./New.png";
import Pending from "./PendingProposal.png";
import Proposal from "./Proposal.png";
import Received from "./Received.png";
import Working from "./Working.png";

export const PIN_MAP = {
  fresh: <NewPin />,
  received_by_sales: <ReceivedPin />,
  work_in_progress: <WorkingPin />,
  pending_decision: <ProposalPin />,
  contract_signed: <PendingPin />,
  completed: <CompletePin />,
  failed: <FailedPin />,
  hibernated: <ArchivedPin />,
};

export const PIN_DATA_MAP = {
  fresh: New,
  received_by_sales: Received,
  work_in_progress: Working,
  pending_decision: Proposal,
  contract_signed: Pending,
  completed: Complete,
  failed: Failed,
  hibernated: Archived,
};
